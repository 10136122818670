<div class="container-fluid mt-4 mb-3 ">
  <div class="headerLabel">
    <div class="headerLabelFont" *ngIf="manipulationType === manipulationTypes.ADD">Add Template</div>
    <div class="headerLabelFont" *ngIf="manipulationType === manipulationTypes.EDIT">Edit Template
      #{{modalConfig?.data?.template?.templateId}}</div>
    <div class="right-section" (click)="closeEvent()">
      <img src="assets/icon-order-status-close.svg" alt="icon-close">
    </div>
  </div><br>
  <form [formGroup]="form" [ngClass]="{'submitted':formSubmitted}" (submit)="save()">
    <div class="formContent">
      <div class="row m-0 p-0 pt-2 mb-4">
        <div class=" col-md-6 col-sm-12 col-xs-12 mb-2">
          <div class="formInput  mt-3 mb-1">
            <label class="labelFont required mb-2">Select Parent Zone</label>
            <select [disabled]="manipulationType === manipulationTypes.EDIT" tabindex="0"
              (change)="onParentzoneInputSelect($event)" type="text" id="type" class="form-control inputBox"
              formControlName="parentZoneId">
              <option value="">Select Parent Zone</option>
              <option value={{zoneType.ALL_PARENT_ZONE}}>Common All Zone</option>
              <option *ngFor="let zone of parentZonalDetails" value={{zone.parentZoneId}}>{{zone.parentZoneName}}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="subZoneDetails.length || form.value.parentZoneId === zoneType.ALL_PARENT_ZONE"
          class=" col-md-6 col-sm-12 col-xs-12 mb-2">
          <div class="formInput  mt-3 mb-1">
            <label class="labelFont required mb-2">Select Subzone</label>
            <select tabindex="0" type="text" id="type" class="form-control inputBox" formControlName="subZoneId">
              <option value={{zoneType.ALL_SUB_ZONE}}>Common All Sub Zone</option>
              <option *ngFor="let zone of subZoneDetails" value={{zone.subZoneId}}>{{zone.subZoneName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row m-0 p-1 mt-2 mb-4">
        <div class="col-md-6 col-sm-12 col-xs-12 p-2 mb-2">
          <div class="formInput">
            <label class="labelFont required" for="templateTitle">Section Title</label>
            <input tabindex="0" type="text" id="templateTitle" class="form-control inputBox" formControlName="title" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 p-2 mb-2">
          <div class="formInput">
            <label class="labelFont required" for="templateName">Section Name</label>
            <input tabindex="0" type="text" id="templateName" class="form-control inputBox" formControlName="name" />
          </div>
        </div>
      </div>
      <div class="row m-0 p-1 mt-2 mb-4">
        <div class="col-md-12 col-sm-12 p-2 col-xs-12">
          <div class="formInput">
            <label class="labelFont required" for="detailedDescription">Section Description</label>
            <textarea tabindex="0" type="text" rows="2" id="detailedDescription" class="form-control inputBox"
              formControlName="description">
            </textarea>
          </div>
        </div>
      </div>
      <div class="row m-0 p-1 pt-2 mb-4">
        <div class="col-md-6 col-sm-12 col-xs-12 p-0">
          <div class="formInput">
            <label class="labelFont required" for="type">Section Type</label>
            <select tabindex="0" type="text" id="type" class="form-control inputBox" formControlName="type"
              [disabled]="templateType === 'sellerProducts'">
              <option *ngIf="templateType === 'food'" value="">Select TemplateType</option>
              <option *ngIf="templateType === 'food'" value="kitchen">Kitchen</option>
              <option *ngIf="templateType === 'food'" value="item">Item</option>
              <option *ngIf="templateType === 'sellerProducts'" value="sellerProducts">Product</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="formInput">
            <label class="labelFont required" for="isActive">Active Status</label>
            <select tabindex="0" type="text" id="isActive" class="form-control inputBox" formControlName="isActive">
              <option value="">none</option>
              <option [ngValue]="true">Active</option>
              <option [ngValue]="false">In Active</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row m-0 p-1 mt-2 mb-4">
        <div class="section-card-upload col-md-6 col-sm-12 col-xs-12" *ngIf="form.value.type === 'item'">
          <div class="formInput">
            <label for="uploadFile" class="labelfont">Section Image</label>
            <div class="section-card-upload-wrapper" (click)="$event.stopPropagation && uploadFile.click()" appDropZone
              [ngClass]="{'submitted': !cardBgImage}">
              <div class="hoverImage" *ngIf="cardBgImage?.url"></div>
              <div class="close" (click)="removeUploadedFile($event)" *ngIf="cardBgImage">
                X
              </div>
              <div class="upload-wrapper" [hidden]="cardBgImage">
                <img class="upload-asset" src="/assets/icon-logo-upload-placeholder.svg"
                  alt="icon-logo-upload-placeholder">
                <p class="upload-title">Drag & Drop a Image file here</p>
                <input #uploadFile type="file" id="uploadFile" class="upload-cta" (change)="onImageUpload($event)"
                  accept="image/jpg,image/jpeg,image/png" />
                <label for="label-uploadFile">Select From Computer</label>
              </div>
              <img class="uploaded-image" [src]="cardBgImage?.url" alt="" *ngIf="cardBgImage?.url">
            </div>
            <div class="preview" *ngIf="cardBgImage?.url" (click)="previewUploadedFile($event, cardBgImage)">
              <div class="preview-icon"></div>
            </div>
            <label class="labelfont">Upload image of width 1440 and height 880 only </label>
          </div>
        </div>
        <div class="section-card-upload col-md-6 col-sm-12 col-xs-12">
          <div class="formInput">
            <label for="uploadFile" class="labelfont">Larger Section Image</label>
            <div class="section-card-upload-wrapper" (click)="$event.stopPropagation && uploadLgFile.click()"
              appDropZone [ngClass]="{'submitted': !cardBgImageLg}">
              <div class="hoverImage" *ngIf="cardBgImageLg?.url"></div>
              <div class="close" (click)="removeLgUploadedFile($event)" *ngIf="cardBgImageLg">
                X
              </div>
              <div class="upload-wrapper" [hidden]="cardBgImageLg">
                <img class="upload-asset" src="/assets/icon-logo-upload-placeholder.svg"
                  alt="icon-logo-upload-placeholder">
                <p class="upload-title">Drag & Drop a Image file here</p>
                <input #uploadLgFile type="file" id="uploadLgFile" class="upload-cta" (change)="onLgImageUpload($event)"
                  accept="image/jpg,image/jpeg,image/png" />
                <label for="label-uploadFile">Select From Computer</label>
              </div>
              <img class="uploaded-image" [src]="cardBgImageLg?.url" alt="" *ngIf="cardBgImageLg?.url">
            </div>
            <div class="preview" *ngIf="cardBgImageLg?.url" (click)="previewLgUploadedFile($event, cardBgImageLg)">
              <div class="preview-icon"></div>
            </div>
            <label class="labelfont">Upload image of width 360 and height 460 only </label>
          </div>
        </div>
      </div>
      <div *ngIf="form.value.type" class="row m-0 p-1 pt-2 mb-4">
        <div class="col-md-6 col-sm-12 col-xs-12 p-1">
          <div class="title formInput" *ngIf="templateType === 'food'">
            <label class="labelFont required mb-2" for="discountType">Select Option</label>
            <div class="form-check form-check-inline formInputTitle" *ngFor="let item of queryGroup.items">
              <input class="form-check-input mt-0" name="selectQuery" type="radio" [value]="item.value"
                formControlName="selectQuery"
                *ngIf="(form.value.type === 'kitchen' && item.value !== 'isGetItImmediatelySection') || (form.value.type === 'item')">
              <label class="form-check-label"
                *ngIf="form.value.type === 'kitchen' && item.value !== 'isGetItImmediatelySection'">{{item.label}}</label>
              <label class="form-check-label"
                *ngIf="form.value.type === 'item'">{{$any(itemChoiceLabelMap)[item.value]}}</label>
            </div>
          </div>
          <div class="title formInput d-flex flex-column justify-content-center"
            *ngIf="templateType === 'sellerProducts'">
            <label class="labelFont required mb-2">Select Option</label>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="d-flex align-items-center">
                <input class="form-check-input mt-0" id="sellerProductCategory" name="selectQuery" type="radio"
                  value="seller-product-category" (change)="handleChangeCategory($event)" formControlName="selectQuery">
                <label class="ms-2 form-check-label" for="sellerProductCategory">Product Category</label>
              </div>
              <div class="d-flex align-items-center">
                <input class="form-check-input mt-0" id="sellerProductSubCategory" name="selectQuery" type="radio"
                  value="seller-product-sub-category" (change)="handleChangeSubCategory($event)"
                  formControlName="selectQuery">
                <label class="ms-2 form-check-label" for="sellerProductSubCategory">Product subcategory</label>
              </div>
              <div class="d-flex align-items-center">
                <input class="form-check-input mt-0" id="sellerProductBrand" name="selectQuery" type="radio"
                  value="seller-brand" (change)="handleChangeBrand($event)" formControlName="selectQuery">
                <label class="ms-2 form-check-label" for="sellerProductBrand">Brand</label>
              </div>
              <div class="d-flex align-items-center">
                <input class="form-check-input mt-0" id="productTags" name="selectQuery" type="radio"
                  value="product-tags" (change)="handleChangeProductTags($event)" formControlName="selectQuery">
                <label class="ms-2 form-check-label" for="productTags">Product Tags</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 p-0">
          <div *ngIf="form.value.selectQuery === 'nearby'" class="formInput p-1">
            <label class="labelFont required" *ngIf="form.value.type === 'kitchen' || !form.value.type"
              for="templateNearBy">Nearby Kitchen Kms</label>
            <label class="labelFont required" *ngIf="form.value.type === 'item'" for="templateNearBy">Nearby Items
              Kms</label>
            <input tabindex="0" name="nearBy" type="number" id="templateNearBy" class="form-control inputBox"
              formControlName="nearBy" [defaultValue]="5" />
          </div>
          <div *ngIf="form.value.selectQuery === 'cuisine'" class="formInput p-1">
            <div class="cuisineDropdown d-block">
              <label class="labelFont required" for="templateNearBy">Select Cuisine Type</label><br>
              <angular2-multiselect [data]="cuisineList" [settings]="dropDownSettings" formControlName="cuisine">
              </angular2-multiselect>&nbsp;
            </div>
          </div>
          <div *ngIf="form.value.selectQuery === 'seller-product-category'" class="formInput p-1">
            <label class="labelFont required">Select Product Categories</label><br>
            <ng-select placeholder="Select product category" [multiple]="true" class="custom"
              (close)="onCloseCategories()" (clear)="onCloseCategories()" formControlName="sellerProductCategory"
              (search)="fetchCategoriesSearch($event)" (scrollToEnd)="fetchCategories()">
              <ng-option [value]="category.categoryId" *ngFor="let category of allCategories">
                {{category.categoryName}}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="form.value.selectQuery === 'seller-product-sub-category'" class="formInput p-1">
            <label class="labelFont required">Select Product SubCategories</label><br>
            <ng-select placeholder="Select product subcategory" [multiple]="true" class="custom"
              (close)="onCloseSubCategories()" (clear)="onCloseSubCategories()"
              (search)="fetchSubCategoriesSearch($event)" formControlName="sellerProductSubCategory"
              (scrollToEnd)="fetchSubCategories()">
              <ng-option [value]="subCategory.subCategoryId" *ngFor="let subCategory of allSubCategories">
                {{subCategory.subCategoryName}}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="form.value.selectQuery === 'seller-brand'" class="formInput p-1">
            <label class="labelFont required">Select Brands</label><br>
            <ng-select placeholder="Select brands" formControlName="sellerProductBrand" [multiple]="true" class="custom"
              [ngClass]="{'has-error': formSubmitted && form.get('sellerProductBrand')?.hasError('required')}">
              <ng-option [value]="brand.kitchenId" *ngFor="let brand of allBrands">
                {{brand.kitchenName || ''}}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="form.value.selectQuery === 'product-tags'" class="formInput p-1">
            <label class="labelFont required">Select Product tags</label><br>
            <ng-select placeholder="Select product tags" class="custom" (close)="onCloseTags()"
              (clear)="onCloseTags()" (search)="fetchTagsSearch($event)" formControlName="productTags"
              (scrollToEnd)="fetchTags()">
              <ng-option [value]="tags.tagId" *ngFor="let tags of allTags">
                {{tags.title}}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="formInput row mt-3 mb-1 ps-3">
        <div class="col-md-6 col-sm-12 col-xs-12 p-2 mb-2">
          <div class="formInput">
            <label class="labelFont " for="requiredLimit">Minimum Entity Required</label>
            <input tabindex="0" type="number" id="requiredLimit" class="form-control inputBox"
              formControlName="requiredLimit" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 align-item-center pt-3 portion-table-pureveg d-flex"
          *ngIf="templateType === 'food'">
          <div class="toggle-pureveg">
            <input type="checkbox" class="me-1 ml-2" id="Pureveg" formControlName="restrictForPureVeg" />
            <label for="Pureveg">
              Restrict For Pure Veg
            </label>
            <img class="pureveg-icon" src="/assets/icon-pure-veg.svg" alt="icon-pure-veg">
          </div>
        </div>
      </div>
      <div class="formInput row mt-3 mb-1 ps-2" *ngIf="templateType === 'food'">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <div class="formInput p-1">
            <label class="labelFont">Select Preferred MealTime</label><br>
            <angular2-multiselect [data]="mealTimeList" [settings]="mealTimeDropdownSetting"
              formControlName="preferredMealTime">
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="footer"><br>
      <div class="d-flex justify-content-end float-right align-item-center">
        <div class="submitBtn d-flex align-items-end">
          <button type="button" type="submit" class="footerBtn border-0" id="saveBtn">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>