import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManipulateDeliveryPreferenceModalComponent } from './manipulate-delivery-preference-modal.component';
import { ConfigApiService } from '../../../../services/api/config.service';

@NgModule({
  declarations: [
    ManipulateDeliveryPreferenceModalComponent,
  ],
  providers: [
    ConfigApiService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ManipulateDeliveryPreferenceModule { }
