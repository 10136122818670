import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface ICuisine {
  cuisineId: string;
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

export interface createCuisine {
  cuisineName: string;
  cuisineAliasName: string;
  cuisineDescription: string;
}

@Injectable()
export class CuisineApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/cuisine-details/`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async get(id: string): Promise<ICuisine> {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll(): Promise<{ data: ICuisine[] }> {
    const apiPath = `${this.servicePath}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: createCuisine) {
    const apiPath = `${this.servicePath}`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: Partial<createCuisine>) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpPut(apiPath, payload);
  }
}
