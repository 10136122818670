export enum FoodType {
  PURE_VEG = 'pureVeg',
  VEG = 'veg',
  NON_VEG = 'nonVeg',
  VEG_NON_VEG = 'vegNonVeg',
  VEGAN = 'vegan',
}

export enum CookStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  L1COMPLETED = 'l1completed',
  ONBOARD_COMPLETED = 'onboardCompleted',
  REJECTED = 'rejected',
  FUTURE_COOK = 'future-cook',
  ACTIVE = 'active',
  ACTIVE_COOK = 'active-cook',
}

export const COOKS = [{
  id: 'Cook 010',
  name: 'Haritha Murali',
  phone: '+91-956695959',
  createdAt: '05 - 06 - 2022',
  foodType: FoodType.VEG,
  status: CookStatus.PENDING,
}, {
  id: 'Cook 011',
  name: 'Suganya',
  phone: '+91-956695959',
  createdAt: '04 - 06 - 2022',
  foodType: FoodType.VEG,
  status: CookStatus.L1COMPLETED,
}, {
  id: 'Cook 012',
  name: 'Karthi Kumar',
  phone: '+91-956695959',
  createdAt: '03 - 06 - 2022',
  foodType: FoodType.VEG_NON_VEG,
  status: CookStatus.REJECTED,
}, {
  id: 'Cook 013',
  name: 'Karthi Kumar',
  phone: '+91-956695959',
  createdAt: '03 - 06 - 2022',
  foodType: FoodType.VEG_NON_VEG,
  status: CookStatus.FUTURE_COOK,
}];

// NOTE:
// if delivery time is within this time from now, order will be highlighted in its respective color
export const DANGER_HIGHLIGHT_TIME = 45;

export enum AccountVerificationStatus {
  NOT_VERIFIED = 'not-verified',
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
}
