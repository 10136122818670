import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AzureAdConfigService } from '../services/azure-ad-config.service';

/* If the user is not authenticated, they are redirected to the login page */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private azureAdConfigService: AzureAdConfigService,
  ) { }

  /**
   * This function is called when the user navigates to a route that requires authentication.
   * If the user is not authenticated, they are redirected to the login page
   * @returns A boolean value.
   */
  async canActivate(): Promise<boolean> {
    // TODO: use this once jwt is in place
    const validLogin = await this.azureAdConfigService.isAuthenticatedCheck();
    if (!validLogin) {
      this.azureAdConfigService.logout(); // TODO: redirect to login
    }
    return validLogin;
  }

  async canActivateChild(): Promise<boolean> {
    // TODO: use this once jwt is in place
    const validLogin = await this.azureAdConfigService.isAuthenticatedCheck();
    if (!validLogin) {
      this.azureAdConfigService.logout(); // TODO: redirect to login
    }
    return validLogin;
  }
}
