import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../../enums/role.enum';
import { TabTypes } from '../../enums/tab.types';
import { AzureAdConfigService } from '../../services/azure-ad-config.service';
import { AppAbility } from '../../services/appAbility';
import { InitialService } from '../../services/initial.service';
import { AppAbilityKey } from '../../enums/ability-keys.enum';
import { environment } from '../../environments/environment';

const { config } = environment;
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public currentTab = TabTypes.COOK_ADMIN;

  userName: string | undefined;

  roleTypes = Role;

  public envConfig = config;

  role!: Role;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly azureAdConfigService: AzureAdConfigService,
    private initialService: InitialService,
    public appAbility: AppAbility,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
  }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  ngOnInit() {
    this.loadInitialDependency();
  }

  get tabTypes() {
    return TabTypes;
  }

  loadInitialDependency() {
    this.userName = this.azureAdConfigService.azureUserProfile.displayName;
    const childRoute = this.activatedRoute.children[0];
    const currentTab = (childRoute?.data as any)?.value?.dashboardType;
    if (currentTab) {
      if (this.appAbility.can('view', AppAbilityKey.COOK_ADMIN)) {
        this.currentTab = currentTab;
      } else if (this.appAbility.can('view', AppAbilityKey.DE_ADMIN)) {
        this.currentTab = currentTab;
      } else if (this.appAbility.can('view', AppAbilityKey.CUSTOMER_ADMIN)) {
        this.currentTab = currentTab;
      }
    } else if (this.appAbility.can('view', AppAbilityKey.COOK_ADMIN)) {
      this.currentTab = TabTypes.COOK_ADMIN;
      this.router.navigate(['cook-admin'], { relativeTo: this.activatedRoute });
    } else if (this.appAbility.can('view', AppAbilityKey.DE_ADMIN)) {
      this.currentTab = TabTypes.DRIVER_ADMIN;
      this.router.navigate(['driver-admin'], { relativeTo: this.activatedRoute });
    } else if (this.appAbility.can('view', AppAbilityKey.CUSTOMER_ADMIN)) {
      this.currentTab = TabTypes.CUSTOMER_ADMIN;
      this.router.navigate(['customer-admin'], { relativeTo: this.activatedRoute });
    }
  }

  changeTab(tab: TabTypes) {
    this.currentTab = tab;
  }

  logout() {
    this.azureAdConfigService.logout();
  }
}
