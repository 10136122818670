<aside class="app-sidebar" *ngIf="sidebarItems.length">
  <nav class="sidebar-options">
    <ul class="nav flex-column">
      <li class="nav-item" *ngFor="let item of sidebarItems">
        <a class="nav-link {{item.elementClass}}" [activeLink]="basePath + (item.route || '') + '/*'"
          (click)="navigate(item.route)">
          {{item.title}}
          <div class="position-relative">
            <svg-icon class="nav-item-icon" src="/assets/{{item.icon}}"></svg-icon>
            <span *ngIf="menuApprovalCount && item.title === 'Menu Approval'"
              class="position-absolute top-0 start-100 translate-middle badge badge-color rounded-pill">
              {{menuApprovalCount}}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</aside>