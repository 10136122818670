import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IImageUpload } from './seller-pipeline.service';
import { IS3FileResponse } from '../upload.service';

export interface IEntityType {
  entityName: string;
  entityType: string;
  entityId: string;
}

export interface ICategories {
  categoryId: string;
  categoryName: string;
  categoryDescription: string;
  categoryS3FileName: string;
  categoryOriginalFileName: string;
  createdBy: IEntityType;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: IEntityType;
  categoryImageUrl: string;
}

export interface IGetAllCategoryResponse {
  categories: Array<ICategories>,
  catagoriesCount: number
}

export interface IGetAllSubCategory {
  subCategoryId: string;
  subCategoryName: string;
  subCategoryDescription: string;
  categoryId: string;
}

export interface IFlavours {
  flavourId: string;
  levels: Array<string>;
  flavourName: string;
}

export interface IGetSubCategoryDetails {
  subCategoryId: string;
  subCategoryName: string;
  subCategoryDescription: string;
  subCategoryS3FileName: string;
  subCategoryOriginalFileName: string;
  categoryId: string;
  flavours: Array<IFlavours>;
  createdBy: IEntityType;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: IEntityType;
  customizeOptions: any,
  subCategoryImageUrl: string;
}

export interface IProductApproval {
  _id: string;
  productId: string;
  productApprovalId: string;
  kitchenId: string;
  name: string;
  approvalStatus: string;
  createdAt: string;
  updatedAt: string;
  brandName: string;
}

export interface IProductApprovalList {
  productApprovals: Array<IProductApproval>;
  productApprovalsCount: number;
}

export interface IAssets {
  s3FileName: string;
  originalFileName: string;
  contentType: string;
  fileType: string;
  fileUrl: IS3FileResponse;
}

export interface IProductSellers {
  product: {
    assets: Array<IAssets>;
    kitchenName: string;
    logoImageOriginalFileName: string;
    logoImageS3FileName: string;
  }
}

export interface IProductSellerDetails {
  kitchenProfiles: Array<IProductSellers>;
  kitchenCount: number;
  totalPages: number;
}

export interface IApprovalPayload {
  approvalStatus: string;
  remarks?: string;
}

export interface IGetAllProductList {
  name: string;
  productId: string;
}

// export interface IProductApprovalDetails {
//   "_id": string;
//   "productId": string;
//   "__v": 0,
//   "allergicInfo": string;
//   "approvalStatus": string;
//   "assets": Array<IAssets>;
//   "categoryId": string;
//   "certificates": [
//     {
//       "certificateName": "praveen",
//       "certificateDocuments": [
//         {
//           "s3FileName": "certificate-image-8703d259-938b-49b9-a320-5f2899f9f90b.png",
//           "originalFileName": "MicrosoftTeams-image (3).png",
//           "photoUrl": {
//             "url": "https://cookr-images.s3.ap-south-1.amazonaws.com/certificate-image-8703d259-938b-49b9-a320-5f2899f9f90b.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAURV62SOHEZK6VDFQ%2F20240320%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240320T151645Z&X-Amz-Expires=300&X-Amz-Signature=e8cf50c24cc85f87c341ac7caa751dcf9b76c92d39b4120973b59d5175d5c07d&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3DMicrosoftTeams-image%20%283%29.png",
//             "expiresIn": 300
//           }
//         }
//       ]
//     },
//     {
//       "certificateName": "test",
//       "certificateDocuments": []
//     }
//   ],
//   "createdAt": "2024-03-20T13:27:49.933Z",
//   "createdBy": {
//     "entityId": null,
//     "entityType": "cook"
//   },
//   "crossSellProductId": [
//     "rWGyIcdZHBFfXua-6Nylt"
//   ],
//   "description": "64 variety spice mix",
//   "expirationDetails": {
//     "expiryPeriod": 4,
//     "unitOfTime": "day"
//   },
//   "flavours": [
//     {
//       "flavourId": "oIqWI0xrdD5zXr_YiRFlo",
//       "level": "low",
//       "isCustomizable": true
//     }
//   ],
//   "foodType": "veg",
//   "ingredients": [
//     "cloves",
//     "cinamon"
//   ],
//   "isExistingProduct": true,
//   "isONDCPreferable": true,
//   "kitchenId": "MeisexQtOqYU9hgAzs1ba",
//   "name": "DRS Masala",
//   "nutritionalInfo": [
//     {
//       "nutritionName": "Caloery",
//       "nutritionValue": 10,
//       "nutritionUOM": "gram"
//     }
//   ],
//   "productApprovalId": "xGkIS2HrR5tL3WmIwF44l",
//   "productInfo": [
//     {
//       "sku": 1,
//       "skuUOM": "gram",
//       "price": 1,
//       "packingCharge": 1,
//       "productType": "madeTo",
//       "leadTime": 4,
//       "isCustomizable": false
//     },
//     {
//       "sku": 2,
//       "skuUOM": "gram",
//       "price": 2,
//       "packingCharge": 1,
//       "productType": "madeTo",
//       "leadTime": 40,
//       "isCustomizable": true,
//       "customizeOptions": {
//         "addOn": [],
//         "flavour": [],
//         "layer": [],
//         "remove": [],
//         "shape": [],
//         "substitute": [],
//         "theme": [],
//         "topping": [],
//         "canHaveNameOnCake": true
//       }
//     }
//   ],
//   "subCategoryId": "tqDBucTqZewxLFkQPyDg9",
//   "updatedAt": "2024-03-20T14:05:33.626Z",
//   "updatedBy": {
//     "entityId": null,
//     "entityType": "cook"
//   },
//   "submittedAt": "2024-03-20T14:05:33.625Z",
//   revenueSharingPercentage?: number
// }

export interface ICertificate {
  certificateName: string,
  certificateDocuments: IImageUpload[];
}

export interface IProductApprovalDetails {
  parentZoneId: string;
  productId: string;
  allergicInfo: string;
  approvalStatus: string;
  assets: Array<IAssets>;
  categoryId: string;
  certificates: ICertificate[];
  createdA: string,
  createdBy: IEntityType;
  crossSellProductId: Array<string>;
  description: string,
  expirationDetails: {
    expiryPeriod: number,
    unitOfTime: string,
  },
  flavours: [
    {
      flavourId: string,
      level: string,
      isCustomizable: boolean,
    }
  ],
  foodType: Array<string>;
  ingredients: Array<string>;
  // isONDCPreferable: boolean;
  isExistingProduct: boolean;
  kitchenId: string,
  name: string,
  nutritionalInfo: [
    {
      nutritionName: string,
      nutritionValue: number,
      nutritionUOM: string
    }
  ],
  productApprovalId: string,
  productInfo: [
    {
      sku: number,
      skuUOM: string,
      price: number,
      packingCharge: number,
      productType: string,
      leadTime: number,
      isCustomizable: boolean
    }
  ],
  subCategoryId: string;
  updatedAt: string,
  updatedBy: IEntityType;
  submittedAt: string;
  tags: string[];
  satelliteStoreId: string;
  revenueSharingPercentage?: number;
  tagsWithNames: Array<{
    tagId: string,
    tagName: string,
    title: string
  }>
}

export interface IExistingProductApprovalDetails {
  productId: string;
  assets: IAssets;
  categoryId: string;
  certificates: ICertificate[];
  createdAt: string;
  createdBy: IEntityType;
  crossSellProductId: Array<string>;
  description: string,
  allergicInfo: string;
  expirationDetails: {
    expiryPeriod: number,
    unitOfTime: string,
  },
  flavours: [
    {
      flavourId: string,
      level: string,
      isCustomizable: boolean,
    }
  ],
  foodType: Array<string>;
  ingredients: Array<string>;
  // isONDCPreferable: boolean,
  kitchenId: string,
  name: string,
  nutritionalInfo: [
    {
      nutritionName: string,
      nutritionValue: number,
      nutritionUOM: string
    }
  ],
  productApprovalId: string,
  productInfo: [
    {
      sku: number,
      skuUOM: string,
      price: number,
      packingCharge: number,
      productType: string,
      leadTime: number,
      isCustomizable: boolean
    }
  ],
  subCategoryId: string;
  updatedAt: string,
  updatedBy: IEntityType;
  revenueSharingPercentage?: number;
  tags: string[];
  satelliteStoreId: string;
  tagsWithNames: Array<{
    tagId: string,
    tagName: string,
    title: string
  }>
}

export interface IProductApprovalComparisonDetails {
  'productApproval': IProductApprovalDetails;
  'existingProduct': IExistingProductApprovalDetails;
  'differences': Array<string>;
}

@Injectable()
export class ProductApprovalApiService extends RestApiService {
  protected adminServiceBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  protected serviceBaseApiUrl = environment.config['SELLER_PIPELINE_SERVICE_URL'];

  private servicePath = `${this.serviceBaseApiUrl}/seller-portal-v2`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllProductSellerDetails(queryParams: Partial<any>): Promise<{ data: IProductSellerDetails }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
    }
    const apiPath = `${this.adminServiceBaseApiUrl}/kitchens/sellers-list/seller-type/productSeller?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getAllProductApprovalList(queryParams: Partial<any>): Promise<{ data: IProductApprovalList }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'ALL') {
        searchParams.set('approvalStatus', String(queryParams['approvalStatus']));
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('isExistingProduct' in queryParams) {
        searchParams.set('isExistingProduct', String(queryParams['isExistingProduct']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/product-approval/list?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getProductById(productId: any): Promise<any> {
    const apiPath = `${this.servicePath}/products/details/${productId}`;
    return this.httpGet(apiPath);
  }

  async getProductList(currentProductId: string, kitchenId: string): Promise<{ data: Array<IGetAllProductList> }> {
    const searchParams = new URLSearchParams();
    if (currentProductId) {
      searchParams.set('excludeProductId', String(currentProductId));
    }
    const apiPath = `${this.servicePath}/products/kitchens/${kitchenId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getCompareProductById(productId: string): Promise<{ data: IProductApprovalComparisonDetails }> {
    const apiPath = `${this.servicePath}/product-approval/comparison/products/${productId}`;
    return this.httpGet(apiPath);
  }

  async updateProduct(payload: any, productId: string): Promise<any> {
    const apiPath = `${this.servicePath}/product-approval/${productId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateApprovalStatus(id: string, payload: IApprovalPayload) {
    const apiPath = `${this.servicePath}/product-approval/status/${id}`;
    return this.httpPatch(apiPath, payload);
  }

  async getAllCategories(
    skip: number,
    limit: number,
  ): Promise<{ data: IGetAllCategoryResponse }> {
    const searchParams = new URLSearchParams();
    if (skip || skip === 0) {
      searchParams.set('skip', String(skip));
    }
    if (limit) {
      searchParams.set('limit', String(limit));
    }
    const apiPath = `${this.servicePath}/products/category?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getSubCategoryListById(
    subCategoryId: string,
  ): Promise<{ data: Array<IGetAllSubCategory> }> {
    const apiPath = `${this.servicePath}/products/sub-category/list/${subCategoryId}`;
    return this.httpGet(apiPath);
  }

  async getSubCategoryDetailsById(
    subCategoryId: string,
  ): Promise<{ data: IGetSubCategoryDetails }> {
    const apiPath = `${this.servicePath}/products/sub-category/details/${subCategoryId}`;
    return this.httpGet(apiPath);
  }

  async getProductApprovalLogs(productApprovalId: string) {
    const apiPath = `${this.servicePath}/product-approval/logs/${productApprovalId}`;
    return this.httpGet(apiPath);
  }
}
