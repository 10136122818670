import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  firstValueFrom, Observable,
} from 'rxjs';
import { ConfirmationModalComponent } from './confirmation-modal.component';

@Injectable()
export class ConfirmationModalService {
  constructor(private ngbModal: NgbModal) { }

  showConfirmationPopup(confirmationMessage = 'Are you sure you want to continue?', isClosedReq?: boolean, isYesOrNo?: boolean, remarks?:boolean, remarksRequired?: boolean): Promise<any> {
    const modal = this.ngbModal.open(ConfirmationModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'confirmation-modal',
    });
    const componentInstance = (<ConfirmationModalComponent>(modal.componentInstance));
    componentInstance.confirmationMessage = confirmationMessage;
    componentInstance.isClosedReq = isClosedReq ?? true;
    componentInstance.isYesOrNo = isYesOrNo ?? false;
    componentInstance.remarks = remarks ?? false;
    componentInstance.remarksRequired = remarksRequired ?? false;
    return firstValueFrom(componentInstance.result);
  }
}
