<div class="modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <div class="modal-title" *ngIf="videoUrl; else imageTitle">Preview Video</div>
      <ng-template #imageTitle>
        <div class="modal-title">Preview Image</div>
      </ng-template>
      <img src="assets/icon-order-status-close.svg" class="cursor-pointer" alt="icon-close" (click)="modalClose()" />
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center" >
      <video controls class="video" *ngIf="videoUrl; else imageView">
        <source [src]="videoUrl.url" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <ng-template #imageView>
        <img [src]="imageUrl" class="img-fluid" />
      </ng-template>
    </div>
  </div>
</div>