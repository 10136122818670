/* eslint-disable no-nested-ternary */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom, from } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { IDeliveryTimeSlotOption, IDeliveryPreferencesOption } from '../../../customer-admin/customer-app-configuration/delivery-preference/delivery-preference.component';
import { AppAbility } from '../../../../services/appAbility';
import { ModalRef } from '../../../../lib/modal/modal-ref';
import { ParentZonalDetail, ZoneApiService } from '../../../../services/api/zone-api.service';
import { InitialService } from '../../../../services/initial.service';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ConfigApiService } from '../../../../services/api/config.service';

enum ManipulationType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

@Component({
  selector: 'app-manipulate-delivery-preference-modal',
  templateUrl: './manipulate-delivery-preference-modal.component.html',
  styleUrls: ['./manipulate-delivery-preference-modal.component.scss'],
})
export class ManipulateDeliveryPreferenceModalComponent implements OnInit {
  public form!: FormGroup;

  public formSubmitted: boolean = false;

  manipulationType = ManipulationType.ADD;

  manipulationTypes = ManipulationType;

  public parentZonalDetails: ParentZonalDetail[] = [];

  public selectedParentZone: ParentZonalDetail | null = null;

  public parentZoneId = '';

  public selectedDeliveryPreferenceUpdate: any;

  constructor(
    private readonly configApiService: ConfigApiService,
    private readonly zoneApiService: ZoneApiService,
    private readonly initialService: InitialService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public appAbility: AppAbility,
    public modalConfig: ModalConfig,
    public modalRef: ModalRef,
    private readonly toast: HotToastService,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
    this.createForm();
  }

  async ngOnInit() {
    if (this.modalConfig.data.manipulationType) {
      this.manipulationType = this.modalConfig.data.manipulationType;
    }
    if (this.manipulationType === ManipulationType.EDIT
      && this.modalConfig.data.deliveryPreference) {
      const { deliveryPreference } = this.modalConfig.data;
      this.selectedDeliveryPreferenceUpdate = deliveryPreference;
      this.form.patchValue({
        parentZoneId: deliveryPreference.parentZoneId,
        selectedOption: (!deliveryPreference.displayDeliveryTimeSlot)
          ? IDeliveryPreferencesOption.ORDER_NOW
          : (deliveryPreference.displayDeliveryTimeSlot
            && deliveryPreference.deliveryTimeSlotOption === IDeliveryTimeSlotOption.REQUIRED)
            ? IDeliveryPreferencesOption.ORDER_LATER
            : (deliveryPreference.displayDeliveryTimeSlot
              && deliveryPreference.deliveryTimeSlotOption === IDeliveryTimeSlotOption.OPTIONAL)
              ? IDeliveryPreferencesOption.BOTH : undefined,
        isActive: deliveryPreference.isActive ? deliveryPreference.isActive : false,
        deliveryMessage:deliveryPreference.deliveryTimeSlotMessage ? deliveryPreference.deliveryTimeSlotMessage : ''
      });
      this.form.get('parentZoneId')?.disable();
      if (this.form.getRawValue()?.parentZoneId === 'default') {
        this.form.get('isActive')?.disable();
      }
    }
    const eligibleParentZoneIds = await this.initialService.getElgibleParentZones();
    if (Array.isArray(eligibleParentZoneIds) && !eligibleParentZoneIds.includes('all')) {
      const responses = await this.zoneApiService.getAllEligibleParentZone();
      this.parentZonalDetails = responses.data.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    } else {
      const responses: Array<ParentZonalDetail> = [];
      responses.push(...(await this.zoneApiService.getAllParentZone()).data);
      this.parentZonalDetails = responses.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    }
    this.getParentParams();
  }

  async getParentParams() {
    const queryParamMap = await firstValueFrom(this.activatedRoute.queryParamMap);
    this.parentZoneId = queryParamMap.get('parentZoneId') || '';
    const filterParentZone = this.parentZonalDetails.find(
      (item: ParentZonalDetail) => (
        item.parentZoneId === this.parentZoneId
      ),
    );
    if (filterParentZone) {
      this.selectedParentZone = filterParentZone;
    } else if (this.parentZoneId === null) {
      this.selectedParentZone = null;
    } else {
      await this.router.navigate([], {
        queryParams: {
          parentZoneId: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      this.selectedParentZone = null;
    }
  }

  private createForm() {
    this.form = new FormGroup({
      parentZoneId: new FormControl('', [Validators.required]),
      selectedOption: new FormControl('', [Validators.required]),
      isActive : new FormControl(false),
      deliveryMessage : new FormControl('')
    });
  }

  async saveChanges() {
    this.formSubmitted = true;
    if (this.form.valid) {
      if (this.selectedParentZone?.parentZoneId === undefined
        && this.selectedParentZone !== null) {
        this.toast.warning('Please select the Zone first!');
        this.formSubmitted = false;
        return;
      }
      const formValues = this.form.getRawValue();
      const DELIVERY_TIME_SLOT_MESSAGE = 'Your order will be delivered within 60 - 70 minutes';
      const commonConfig = {
        displayDeliveryTimeSlot: true,
        deliveryTimeSlotOption: IDeliveryTimeSlotOption.REQUIRED,
        deliveryTimeSlotMessage: formValues.deliveryMessage || DELIVERY_TIME_SLOT_MESSAGE,
        isActive: formValues.parentZoneId === 'default' ? true : formValues?.isActive || false,
      };
      let deliveryPreferenceUpdatePayload: any = {};
      if (formValues.parentZoneId === 'default') {
        deliveryPreferenceUpdatePayload = {
          configuration: commonConfig,
        };
      } else {
        deliveryPreferenceUpdatePayload = {
          parentZoneId: formValues.parentZoneId,
          configuration: commonConfig,
        };
      }

      switch (formValues.selectedOption) {
        case IDeliveryPreferencesOption.ORDER_NOW:
          deliveryPreferenceUpdatePayload.configuration.displayDeliveryTimeSlot = false;
          break;
        case IDeliveryPreferencesOption.ORDER_LATER:
          break;
        case IDeliveryPreferencesOption.BOTH:
          deliveryPreferenceUpdatePayload.configuration
            .deliveryTimeSlotOption = IDeliveryTimeSlotOption.OPTIONAL;
          break;
        default:
          break;
      }

      const deliveryPreferenceUpdatePromise = this.configApiService.updateOrderNowConfig(
        'customer',
        deliveryPreferenceUpdatePayload,
      );
      from(deliveryPreferenceUpdatePromise).pipe(
        this.toast.observe({
          success: 'Delivery preference updated successfully',
          loading: 'Updating delivery preference',
          error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
        }),
      ).subscribe(
        (res) => {
          if (res) {
            this.modalRef.dataEmit(res.data);
          }
        },
      );
      await deliveryPreferenceUpdatePromise;
      this.resetForm();
      this.modalRef.close();
    } else {
      this.toast.warning('Please enter valid input!');
    }
    setTimeout(() => {
      this.formSubmitted = false;
    }, 2000);
  }

  resetForm() {
    this.formSubmitted = false;
    this.form.reset();
    this.selectedDeliveryPreferenceUpdate = null;
  }

  parentZoneChange(event: Event) {
    const { value } = event.target as HTMLInputElement;
    if (value === 'default') {
      this.form.controls['isActive'].setValue(true);
      this.form.get('isActive')?.disable();
    } else {
      this.form.get('isActive')?.enable();
    }
  }
}
