import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export enum OrderTypes {
  NOW = 'now',
  PREORDER = 'preOrder',
  PREBOOKING = 'preBooking',
  GET_IT_IMMEDIATELY = 'getItImmediately',
  SCHEDULED = 'scheduled',
  INSTANT = 'instant',
  SUBSCRIPTION = 'subscription',
}

export enum ProductTypesEnum {
  MADE_TO_ORDER = 'madeTo',
  READYMADE = 'readyMade',
}

export enum MealTimes {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  SNACKS = 'snacks',
  DINNER = 'dinner',
}

export enum LogisticOrderStatus {
  ORDER_PLACED = 'orderPlaced',
  DE_ASSIGNING = 'deAssigning',
  DE_ASSIGNED = 'deAssigned',
  FIRST_MILE_REACHED = 'firstMileReached',
  ORDER_PICKED_UP = 'orderPickedUp',
  LAST_MILE_REACHED = 'lastMileReached',
  DELIVERED = 'orderDelivered',
  PARTIALLY_COMPLETE = 'partiallyFulfilled',
  DELIVERY_IN_COMPLETE = 'orderInComplete',
}

export enum LogisticOrderRiderType {
  IN_HOUSE = 'inHouse',
  OTHERS = 'others',
}

export interface ILocationCoordinates {
  coordinates: number[];
  type: string
}

export interface IPickUpOrDropDetails {
  contactPersonName: string,
  contactPersonNumber: string,
  address: string,
  location: ILocationCoordinates
}

export interface IDeliveryTimeSlot {
  date: string,
  time: string
}

export interface ICreatedOrUpdatedBy {
  entityId: string,
  entityName: string;
  entityType: string
}

export interface IRatings {
  createdAt: string;
  orderId: string;
  rating: number;
  ratingId: string;
  review: string;
  revieweeId: string;
  revieweeType: 'kitchen' | 'rider' | 'item' | '3pl-rider';
  reviewerId: string;
  reviewerType: 'customer' | 'rider';
  updatedAt: string;
}

export interface ILogisticOrderDetails {
  logisticOrderId: string,
  referenceId: string,
  orderType: OrderTypes,
  orderStatus: LogisticOrderStatus,
  mealTime: MealTimes,
  pickUpDetails: IPickUpOrDropDetails,
  dropDetails: IPickUpOrDropDetails,
  deliveryTip: number,
  deliveryTimeSlot: IDeliveryTimeSlot,
  deliveryInstructions?: string;
  amountToBeCollected: number,
  paymentMode: string,
  tplOrderId?: string,
  isOrderNoteExists: boolean,
  riderRating: IRatings;
  productRating: IRatings;
  riderType: LogisticOrderRiderType,
  riderId: string,
  riderName: string,
  createdBy: ICreatedOrUpdatedBy,
  isProductExists: boolean,
  parentZoneId: string,
  subZoneId: string,
  createdAt: string,
  updatedAt: string,
  __v: number,
  firstMileDistance: number,
  lastMileDistance: number,
  orderStatusLabel?: string;
  ratingStarAssetLink?: string;
  preOrder?: boolean;
  productType?: string;
  satelliteStoreId?: string;
  isFoodReady?: boolean;
  deliveryTimeSlotMessage?: string;
  simplifiedOrderId?: string;
}

export interface ILogisticOrderResponse {
  data: {
    logisticOrders: ILogisticOrderDetails[],
    logisticOrdersCount: number
  }
}

export interface ITplOrderCancellationResponse {
  code: number;
  message: string;
}

@Injectable()
export class LogisticOrdersService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config.LOGISTIC_SERVICE_URL;

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/common/logistic-orders`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllLogisticOrders(queryParams: Record<any, (string | number)>)
    : Promise<ILogisticOrderResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['search'] !== undefined) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (Array.isArray(queryParams['riderType'])) {
        queryParams['riderType'].forEach((item) => {
          searchParams.append('riderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['orderCategory'] !== undefined) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (queryParams['orderTabType']) {
        searchParams.set('orderTabType', String(queryParams['orderTabType']));
      }
    }
    const apiPath = `${this.servicePath}?${searchParams}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async assignRider(riderId: string, queryParams: Record<any, (string | number)>) {
    const searchParams = new URLSearchParams();
    if (queryParams?.['referenceId']) {
      searchParams.set('referenceId', String(queryParams['referenceId']));
    }
    const apiPath = `${this.baseApiUrl}/logistic-orders/custom-rider-order/${riderId}?${searchParams}`;
    return this.httpPut(apiPath);
  }

  async updateRiderDeliveredStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.servicePath}/delivered/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateRiderUndeliveredStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.servicePath}/undelivered/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateRiderInCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.servicePath}/incomplete/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async cancelTplOrder(logisticsOrderId: string): Promise<ITplOrderCancellationResponse> {
    const apiPath = `${this.baseApiUrl}/tpl-providers/cancel-order/logistic/${logisticsOrderId}`;
    return this.httpPut(apiPath);
  }
}
