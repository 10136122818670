import {
  Injectable,
} from '@angular/core';
import {
  MatDialog,
} from '@angular/material/dialog';
import {
  firstValueFrom,
} from 'rxjs';
import { sequenceChoice } from '../add-section-card-modal/add-section-card-modal.component';
import { SequenceConformationModalComponent } from './sequence-conformation-modal.component';

@Injectable()
export class SequenceConfirmationModalService {
  constructor(
    private dialog: MatDialog,
  ) { }

  showConfirmationPopup(checkBoxOptions = sequenceChoice): Promise<string> {
    const modal = this.dialog.open(SequenceConformationModalComponent, {
      data: checkBoxOptions,
      height: '150px',
      width: '300px',
    });
    const componentInstance = (<SequenceConformationModalComponent>(modal.componentInstance));
    componentInstance.checkBoxOptions = checkBoxOptions;
    return firstValueFrom(componentInstance.result);
  }
}
