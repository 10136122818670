import {
  Directive, EventEmitter, HostBinding, HostListener, Input, Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { generateFileHandles } from './drop-zone-helpers';

export interface FileHandle {
  file: File;
  url: SafeUrl;
  fileType?: string;
  contentType?: string;
}
@Directive({
  selector: '[appDropZone]',
})
export class DropZoneDirective {
  @Output('onFileDrop') files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding('style.background') public background = 'transparent';

  constructor(private sanitizer: DomSanitizer) {}

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';

    if (evt.dataTransfer) {
      const files = generateFileHandles(evt.dataTransfer.files, this.sanitizer);
      if (files.length > 0) {
        this.files.emit(files);
      }
    }
  }
}
