import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveLinkDirective } from './active-link.directive';

@NgModule({
  declarations: [
    ActiveLinkDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActiveLinkDirective,
  ],
})
export class ActiveLinkModule { }
