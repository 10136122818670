import { Component, OnInit } from '@angular/core';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ModalRef } from '../../../../lib/modal/modal-ref';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit {
  previewImage;

  constructor(
    private modalRef : ModalRef,
    private modalConfig: ModalConfig,
  ) {
    this.previewImage = this.modalConfig.data;
  }

  ngOnInit(): void {
  }

  modalClose() {
    this.modalRef.close();
  }
}
