<div class="modal-header">
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
  </button>
</div>
<div class="modal-body">
  <p class="confirmation-text" style="white-space: pre-wrap;">{{confirmationMessage}}</p>
  <div class="form-group" *ngIf="remarks">
    <label for="exampleFormControlTextarea1" class="{{remarksRequired ? 'required' : ''}}">Remarks</label>
    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="remarksValue" (change)="handleChangeRemarks($event)"></textarea>
  </div>
</div>
<div class="modal-footer" *ngIf="!remarks">
  <button type="button" class="btn btn-outline-secondary" *ngIf="isClosedReq" (click)="close()">{{ isYesOrNo ? 'No' :
    'Cancel' }}</button>
  <button type="button" class="btn btn-danger" (click)="onOk()">{{ isYesOrNo ? 'Yes' : 'Ok'}}</button>
</div>
<div class="modal-footer" *ngIf="remarks">
  <button type="button" class="btn btn-outline-secondary" *ngIf="isClosedReq" (click)="close()">{{ isYesOrNo ? 'No' :
    'Cancel' }}</button>
  <button type="button" class="btn btn-danger" [disabled]="remarksRequired ? !remarksValue.length : false" (click)="onRemarksOk()">{{ isYesOrNo ? 'Yes' : 'Ok'}}</button>
</div>