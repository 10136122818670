/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-empty */

export interface Path {
  pathname: string;
  search: string;
  hash: string;
}

export interface PathPattern<Path extends string = string> {
  path: Path;
  caseSensitive?: boolean;
  end?: boolean;
}

export const matchPath = <Path extends string> (
  pattern: PathPattern<Path> | Path,
  pathname: string,
) => {
  if (typeof pattern === 'string') {
    pattern = { path: pattern, caseSensitive: false, end: true };
  }

  const matcher = compilePath(
    pattern.path,
    pattern.caseSensitive,
    pattern.end,
  );

  const match = pathname.match(matcher);
  if (!match) return false;
  return true;
};

const compilePath = (
  path: string,
  caseSensitive = false,
  end = true,
): RegExp => {
  let regexpSource = `^${
    path
      .replace(/\/*\*?$/, '') // Ignore trailing / and /*, we'll handle it below
      .replace(/^\/*/, '/') // Make sure it has a leading /
      .replace(/[\\.*+^$?{}|()[\]]/g, '\\$&') // Escape special regex chars
      .replace(/:(\w+)/g, (_: string) => '([^\\/]+)')}`;

  if (path.endsWith('*')) {
    regexpSource
      += path === '*' || path === '/*'
        ? '(.*)$'
        : '(?:\\/(.+)|\\/*)$';
  } else {
    regexpSource += end ? '\\/*$' : '(?:\\b|\\/|$)';
  }

  const matcher = new RegExp(regexpSource, caseSensitive ? undefined : 'i');

  return matcher;
};

export const getCoordinatesFromURL = (mapURL: string) => {
  const url = mapURL.split('@');
  const at = url[1].split('z');
  const zero = at[0].split(',');
  const lat = Number(zero[0]);
  const long = Number(zero[1]);
  return { lat, long };
};
