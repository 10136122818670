import { Component, OnInit } from '@angular/core';
import { ModalRef } from '../../../../lib/modal/modal-ref';
import { DELAY_NOTIFICATION_EVENT_TYPE } from '../../../../enums/order.enum';
import { INotificationLog, IPurchaseOrder } from '../../../../services/api/purchase-order-api.service';
import { ModalConfig } from '../../../../lib/modal/modal-config';

@Component({
  selector: 'app-order-acivity-logs',
  templateUrl: './order-acivity-logs.component.html',
  styleUrls: ['./order-acivity-logs.component.scss'],
})
export class OrderAcivityLogsComponent implements OnInit {
  public notificationEventType = DELAY_NOTIFICATION_EVENT_TYPE;

  public logType: string = this.notificationEventType.ALL;

  public notificationLogs: INotificationLog[] = [];

  public orderDetails: IPurchaseOrder | null = null;

  constructor(
    public modalRef: ModalRef,
    public modalConfig: ModalConfig,
  ) { }

  ngOnInit(): void {
    this.orderDetails = this.modalConfig.data?.orderDetails;
    if (this.orderDetails) {
      this.onLogTypeChange(this.orderDetails);
    }
  }

  onLogTypeChange(order: IPurchaseOrder, logType: string = this.logType) {
    if (logType === this.notificationEventType.CALL) {
      this.notificationLogs = order?.delayNotificationLogs.filter(
        (log) => log.eventType === DELAY_NOTIFICATION_EVENT_TYPE.CALL,
      );
    } else if (logType === this.notificationEventType.MISSED_CALL) {
      this.notificationLogs = order?.delayNotificationLogs.filter(
        (log) => log.eventType === DELAY_NOTIFICATION_EVENT_TYPE.MISSED_CALL,
      );
    } else if (logType === this.notificationEventType.NOTIFICATION) {
      this.notificationLogs = order?.delayNotificationLogs.filter(
        (log) => log.eventType === DELAY_NOTIFICATION_EVENT_TYPE.NOTIFICATION,
      );
    } else if (logType === this.notificationEventType.COUPON) {
      this.notificationLogs = order?.delayNotificationLogs.filter(
        (log) => log.eventType === DELAY_NOTIFICATION_EVENT_TYPE.COUPON,
      );
    } else {
      this.notificationLogs = order?.delayNotificationLogs;
    }
  }

  modalClose() {
    this.modalRef.dataEmit(true);
    this.modalRef.close();
  }
}
