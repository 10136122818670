import { Component, OnInit } from '@angular/core';
import { InitialService } from '../services/initial.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cookr-admin-dashboard';

  constructor() { }
}
