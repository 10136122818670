import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface IOrderDisputes {
  disputeId: string;
  orderId: string;
  disputeAddedTo: string;
  disputeType: string;
  disputeAmount: number;
  disputeRefId?: string;
  remarks: string;
  createdAt: string;
  createdBy: {
    entityId: string,
    entityName: string,
    entityType: string
  };
  disputeCategory?: string;
  disputeCategoryLabel?: string;
  updatedBy?: {
    entityId: string,
    entityName: string,
    entityType: string
  };
  disputeCreatedBy?: string;
  disputeUpdatedBy?: string;
}

export interface IDisputePayload {
  disputeId?: string;
  orderId: string;
  referenceId: string,
  disputeAddedTo: string;
  disputeType: string;
  disputeAmount: number;
  remarks: string;
  disputeCategory: string;
  disputeRefId?: string;
}

export interface IPayoutDisputeResponse {
  orderDisputes: Array<IOrderDisputes>;
  totalDisputesCount: number;
}

@Injectable()
export class OrderDisputeApiService extends RestApiService {
  protected adminBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private adminServicePath = `${this.adminBaseApiUrl}/order-disputes/`;

  protected orderServiceBaseApiUrl = environment.config['ORDER_SERVICE_URL'];

  private orderServicePath = `${this.orderServiceBaseApiUrl}/order-disputes/`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getOrderDisputeById(
    referenceId: string,
    orderId: string,
    disputeAddedTo?: string,
  ): Promise<{ data: IOrderDisputes[] }> {
    const searchParams = new URLSearchParams();
    if (referenceId) {
      searchParams.set('referenceId', referenceId);
    }
    if (orderId) {
      searchParams.set('orderId', orderId);
    }
    if (disputeAddedTo) {
      searchParams.set('disputeAddedTo', disputeAddedTo);
    }
    const apiPath = `${this.adminServicePath}fetch-order-disputes/${orderId}?${searchParams.toString()}`;
    // const apiPath = `${this.orderServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getOrderDisputes(): Promise<{ data: IOrderDisputes[] }> {
    const apiPath = `${this.adminServicePath}fetch-disputes`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async fetchOrderDisputes(
    orderIds: string[],
    disputeAddedTo?: string,
  ): Promise<{ data: IOrderDisputes[] }> {
    const searchParams = new URLSearchParams();
    if (disputeAddedTo) {
      searchParams.set('disputeAddedTo', disputeAddedTo);
    }
    const apiPath = `${this.adminServicePath}fetch-disputes/by-orders?${searchParams.toString()}`;
    // const apiPath = `${this.orderServicePath}?${searchParams.toString()}`;
    return this.httpPost(apiPath, orderIds);
  }

  async createOrderDispute(payload: IDisputePayload) {
    const apiPath = `${this.adminServicePath}create-order-dispute`;
    // const apiPath = `${this.orderServicePath}`;
    return this.httpPost(apiPath, payload);
  }

  async updateOrderDispute(payload: IDisputePayload) {
    const apiPath = `${this.adminServicePath}update-order-dispute/${payload.disputeId}`;
    // const apiPath = `${this.orderServicePath}${payload.disputeId}`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchDisputesByPayout(paginationConfig: {
    skip: number,
    limit: number,
  }, payoutId: string): Promise<{ data: IPayoutDisputeResponse }> {
    const searchParams = new URLSearchParams();
    if (paginationConfig.skip !== undefined) {
      searchParams.set('skip', String(paginationConfig.skip));
    }
    if (paginationConfig.limit !== undefined) {
      searchParams.set('limit', String(paginationConfig.limit));
    }
    const apiPath = `${this.adminServicePath}fetch-disputes/by-payout/${payoutId}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }
}
