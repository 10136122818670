<div class="delivery-preference-container">
  <div class="d-flex align-item-center justify-content-between">
    <h5 class="m-0">Delivery Preference Configuration</h5>
    <div class="submit-btn" *ngIf="appAbility.can('create', appAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION)">
      <button type="submit" class="add-btn border-0" (click)="openAddDeliveryPreferenceConfigModal()">Add</button>
    </div>
  </div>
  <div class="delivery-preference-table">
    <table>
      <thead>
        <td>S.No</td>
        <td>Parent Zone</td>
        <td>Delivery Preference</td>
        <td *ngIf="appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION)">Status</td>
        <td *ngIf="appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION)">Edit</td>
      </thead>
      <tbody>
        <tr *ngFor="let deliveryPreference of filteredParentZones$ | async; let i = index">
          <td>
            <div>
              {{i + 1 + paginationConfig.skip}}
            </div>
          </td>
          <td>
            <div>{{ deliveryPreference?.parentZoneName }}</div>
          </td>
          <td>
            <div *ngIf="!deliveryPreference?.displayDeliveryTimeSlot">Order Now</div>
            <div
              *ngIf="deliveryPreference?.displayDeliveryTimeSlot && deliveryPreference.deliveryTimeSlotOption === deliveryTimeSlotOption.REQUIRED">
              Order Later</div>
            <div
              *ngIf="deliveryPreference?.displayDeliveryTimeSlot && deliveryPreference.deliveryTimeSlotOption === deliveryTimeSlotOption.OPTIONAL">
              Order Now & Order Later
            </div>
          </td>
          <td>
            <div *ngIf="appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION)" class="toggle">
              <input type="checkbox" [id]="'switch-' + deliveryPreference.parentZoneId"
                [disabled]="deliveryPreference.parentZoneId === 'default'"
                [checked]="deliveryPreference.isActive"
                (change)="updateDeliveryPreferencesStatus($event,deliveryPreference)" />
              <label [attr.for]="'switch-' + deliveryPreference.parentZoneId"></label>
            </div>
          </td>
          <td *ngIf="appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_DELIVERY_PREFERENCE_CONFIGURATION)">
            <div class="cursor-pointer">
              <img src="/assets/icon-paper-edit.svg" alt="icon-paper-edit"
                (click)="openEditDeliveryPreferenceConfigModal(deliveryPreference)">
            </div>
          </td>
        </tr>
      </tbody>
      <caption *ngIf="!filteredParentZones$">No Delivery Preference Added</caption>
    </table>
  </div>
  <!-- <app-pagination-controls [paginationInfo]="{
        itemsPerPage: paginationConfig.limit,
        totalItems: sortedZones.length,
        currentPage: paginationConfig.currentPage
      }" (pageChange)="onPageChange($event)">
  </app-pagination-controls> -->
</div>
