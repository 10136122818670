import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() confirmationMessage!: string;

  @Input() isClosedReq = true;

  @Input() isYesOrNo = true;

  @Input() remarks!: boolean;

  @Input() remarksRequired!: boolean;

  public remarksValue = '';

  @Output() result = new EventEmitter<boolean | string>();

  constructor(public modal: NgbActiveModal) { }

  onOk() {
    this.result.next(true);
    this.modal.close();
  }
  onRemarksOk() {
    this.result.next(this.remarksValue.trim());
    this.modal.close();
  }

  close() {
    this.result.next(false);
    this.modal.close();
  }

  handleChangeRemarks(event: Event) {
    const { value } = event.target as HTMLInputElement;
    this.remarksValue = value;
  }
}
