import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  catchError, map, Observable, throwError,
} from 'rxjs';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  constructor(private readonly loadingbarService: LoadingBarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.startLoading();
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.stopLoading();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.stopLoading();
        return throwError(error);
      }),
    );
  }

  private startLoading() {
    this.loadingbarService.useRef().start();
  }

  private stopLoading() {
    this.loadingbarService.useRef().stop();
  }
}
