import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SidebarComponent } from './sidebar.component';
import { ActiveLinkModule } from '../../../directives/active-link/active-link.module';
import { MenuApprovalApiService } from '../../../services/api/menu-approval-api.service';

@NgModule({
  declarations: [
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    ActiveLinkModule,
  ],
  exports: [SidebarComponent],
  providers: [MenuApprovalApiService]
})
export class SidebarModule { }
