<div class="container-fluid p-0 m-0">
  <div class="banner-content">
    <div class="banner-header p-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex selection-dropdown pe-4 justify-content-end">
          <button class="dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ selectedParentZone?.parentZoneName ? selectedParentZone?.parentZoneName : 'All ParentZone' }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" *ngIf="isAllParentZonesElligible" (click)="onParentZoneSelect(null)">All
                ParentZone</a>
              <a class="dropdown-item" *ngFor="let parentZone of parentZonalDetails"
                (click)="onParentZoneSelect(parentZone)">
                {{parentZone.parentZoneName}}
              </a>
            </li>
          </ul>
        </div>
        <div class="d-flex selection-dropdown pe-4">
          <button class="dropdown dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ selectedSubzone?.subZoneName ? selectedSubzone?.subZoneName : 'All SubZone' }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li>
              <a class="dropdown-item" (click)="onSubzoneSelect(null)">
                All SubZone
              </a>
              <a class="dropdown-item" *ngFor="let subZone of subZoneDetails" (click)="onSubzoneSelect(subZone)">
                {{subZone.subZoneName}}
              </a>
            </li>
          </ul>
        </div>
        <button *ngIf="appAbility.can('create', appAbilityKey.CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT)"
            class="add-banner-cta" (click)="addBanner()">
            <span>Add Banner</span>
        </button>
      </div>
    </div>
    <div class="banner-body mt-2">
      <div class="banner-body-content-wrapper" [ngClass]="((selectedSubzone)
         || selectedSubzone === null) ? '': 'disabled'">
        <div class="col-md-7 col-xs-12 col-sm-12 banner-edit">
          <form [formGroup]="form" [class.form-submmited]="formSubmitted">
            <div class="choose-active-banner">
              <div class="formInput">
                <label class="labelFont required">Name</label>
                <input tabindex="0" type="text" class="form-control inputBox mt-1" formControlName="title" />
              </div>
              <div class="formInput">
                <label class="formInput labelFont required mt-3 mb-1">Upload Files</label>
                <div class="upload-wrapper mt-1">
                  <div class="banner-upload cursor-pointer p-2" appDropZone (onFileDrop)="onImageDrop($event)"
                    [ngClass]="{'submitted': !bannerUploadImage}" (click)="bannerFile.click()">
                    <div class="hoverImage" *ngIf="bannerUploadImage"></div>
                    <input type="file" name="bannerPic" #bannerFile class="banner-file" formControlName="banner"
                      accept="image/jpeg,image/png,image/jpg" (change)="onImageUpload($event)" id="banner-upload" />
                    <div class="d-flex flex-column justify-content-center" *ngIf="!bannerUploadImage">
                      <img class="upload-icon" src="assets/icon-logo-upload-placeholder.svg">
                      <p class="uploadTitle d-flex justify-content-center mt-1">Drag & Drop a PDF or JPEG File here</p>
                      <label class="button">
                        <p class="mb-0 py-2">Select From Computer</p>
                      </label>
                    </div>
                    <img [src]="bannerUploadImage?.url" alt="coverPic" class="uploaded-image" [hidden]="!bannerUploadImage?.url">
                  </div>
                  <div class="preview mb-3 me-5" *ngIf="bannerUploadImage?.url" (click)="previewImage($event, bannerUploadImage)">
                    <div class="preview-icon"></div>
                  </div>
                  <button class="delete-item-cta mb-3 me-3" *ngIf="bannerUploadImage?.url" (click)="removeUploadedFile($event)">
                    <img src="/assets/icon-delete-item.svg" alt="icon-delete-banner">
                  </button>
                </div>
                <div class="banner-size my-1 py-1">Supported aspect ratio: 164:57 - Eg: ( Width 984 , Height 342 )</div>
                <!-- <div class="banner-size my-1 py-1">Supported aspect ratio: 145:68 - Eg: ( Width 290 , Height 136 )</div> -->
              </div>
              <div class="formInput mt-3 mb-1">
                <label class="labelFont required mb-1">Select Date and Zone</label>
                <div class="banner-edit-selection-wrapper">
                  <div class="select-date">
                    <span class="me-2">From</span>
                    <mat-form-field class="example-full-width" appearance="fill">
                      <input matInput [matDatepicker]="effectiveFrom" id="effectiveFrom" formControlName="effectiveFrom"
                        max="{{form.getRawValue().expireAfter}}" placeholder="MM/DD/YYYY">
                      <mat-datepicker #effectiveFrom disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <img class="ms-2" src="/assets/calendar-icon.png" alt="from-date" (click)="effectiveFrom.open()">
                  </div>
                  <div class="select-date mx-1">
                    <span class="me-2">To</span>
                    <mat-form-field class="example-full-width" appearance="fill">
                      <input matInput [matDatepicker]="expireAfter" id="expireAfter" formControlName="expireAfter"
                        min="{{form.getRawValue().effectiveFrom}}" placeholder="MM/DD/YYYY">
                      <mat-datepicker #expireAfter disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <img class="ms-2" src="/assets/calendar-icon.png" alt="to-date" (click)="expireAfter.open()">
                  </div>
                  <div class="status">
                    <button class="dropdown-toggle w-100 h-100" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      {{ (bannerSelectedStatus !== undefined && (bannerSelectedStatus ? 'Active' : 'InActive')) ||
                      'Select Banner Status' }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" (click)="onSelectStatus(true)">Active</a></li>
                      <li><a class="dropdown-item" (click)="onSelectStatus(false)">InActive</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="formInput mt-3 mb-1">
                <div class="portion-table-pureveg d-flex">
                  <div class="toggle-pureveg">
                    <input type="checkbox" class="me-1 ml-2" id="Pureveg" formControlName="restrictForPureVeg" />
                    <label for="Pureveg">
                      Restrict For Pure Veg
                    </label>
                    <img class="pureveg-icon" src="/assets/icon-pure-veg.svg" alt="icon-pure-veg">
                  </div>
                </div>
              </div>
              <textarea placeholder="Write Description" tabindex="0" type="text" class="form-control inputBox mt-3 mb-3"
                formControlName="description" rows="4"></textarea>

              <div class="row">
                <div class="col-md-6">
                  <div class="formInput">
                    <label class="labelFont mb-1">Select a Redirection Type</label>
                    <ng-select placeholder="Select a redirection type" class="custom mb-4"
                      formControlName="redirectionType">
                      <ng-option [value]="redirectionType" *ngFor="let redirectionType of deepLinkRedirections">
                        {{getDeepLinkRedirectionType(redirectionType)}}
                      </ng-option>
                      <ng-option *ngIf="deepLinkRedirections.length" value="others">Others</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="formInput" *ngIf="form.value.redirectionType && form.value.redirectionType !== 'others'">
                    <label class="labelFont required mb-1">Select a
                      {{getDeepLinkRedirectionType(form.value.redirectionType)}}</label>
                    <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
                      class="custom mb-4" (search)="onSearchCategory($event.term)" (close)="onClose()"
                      (clear)="onClose()" formControlName="redirectionId" (scrollToEnd)="fetchCategory()"
                      *ngIf="form.value.redirectionType === this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY">
                      <ng-option [value]="category.specialCategoryId" *ngFor="let category of allCategory">
                        <div class="d-flex justify-content-between">
                          <span><img [src]="category?.specialCategoryImageUrl?.url" alt=""
                              *ngIf="category?.specialCategoryImageUrl"
                              class="special-tag-image" />{{category.specialCategoryName}}</span>
                          <span class="tag-alignment">#{{category.specialCategoryId | slice:0:6}}</span>
                        </div>
                      </ng-option>
                    </ng-select>
                    <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
                      class="custom mb-4" (search)="onSearchCoupon($event.term)" (close)="onClose()" (clear)="onClose()"
                      formControlName="redirectionId" (scrollToEnd)="fetchCoupons()"
                      *ngIf="form.value.redirectionType ===this.bannerRedirectionTypesEnum.COUPON">
                      <ng-option [value]="coupon.couponId" *ngFor="let coupon of coupons">
                        <div class="d-flex justify-content-between">
                          <!-- <span class="me-2" style="max-width: 50%;word-break: break-all;">{{coupon.couponName}}</span> -->
                          <span>{{coupon.couponCode}}</span>
                        </div>
                      </ng-option>
                    </ng-select>
                    <ng-select [placeholder]="'Select a '+ getDeepLinkRedirectionType(form.value.redirectionType)"
                      class="custom mb-4" (search)="onSearchKitchen($event.term)" (close)="onClose()"
                      (clear)="onClose()" formControlName="redirectionId" (scrollToEnd)="fetchKitchens()"
                      *ngIf="form.value.redirectionType === 'kitchen'">
                      <ng-option [value]="kitchen.kitchenId" *ngFor="let kitchen of kitchens">
                        {{ kitchen.kitchenName }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="formInput"
                *ngIf="form.get('redirectionType')?.value === 'others' || generatedDeepLink.length">
                <label class="labelFont mb-1">{{ form.get('redirectionType')?.value === 'others' ?
                  'Paste a custom deep link' : 'Generated deep link' }}</label>
                <input tabindex="0" type="text" class="form-control inputBox mt-1" formControlName="deepLinkUrl" />
              </div>

              <div
                *ngIf="appAbility.can('create', appAbilityKey.CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT) || appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT)"
                class="save-cta-section mt-4">
                <button class="save-cta" type="submit"
                  [disabled]="formSubmitted || (selectedBannerUpdate && selectedBannerUpdate.isAllParentZone && !isAllParentZonesElligible)"
                  (click)="saveChange()">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-5 col-xs-12 col-sm-12 d-flex flex-column justify-content-between banner-view">
          <div class="active-banner-header">
            <span>Active Banners</span>
          </div>
          <div class="active-banners my-3 position-relative">
            <div cdkDropList class="my-3 position-relative" (cdkDropListDropped)="drop($event)">
              <div *ngFor="let banner of banners" cdkDrag class="active-banner">
                <div class="active-banner-image d-flex justify-content-center align-items-center flex-column">
                  <div class="my-3">
                    <div class="active-banner-container" (click)="onSelectBanner(banner)"
                      [ngClass]="{'inactive-banner-image': !banner.isActive}">
                      <div *ngIf="!banner.isActive" class="inactive-banner-text">
                        <p>InActive</p>
                      </div>
                      <img class="bannerimagetag" *ngIf="banner.isAllParentZone" src="/assets/All_P-Zone.svg">
                      <img class="bannerimagetag" *ngIf="banner.parentZoneId !== null && banner.isAllSubZone === true"
                        src="/assets/All_S-Zone.svg">
                      <img *ngIf="banner?.fileHandle?.url " class="align-self-center banner-image"
                        [src]="banner?.fileHandle?.url" [alt]="banner?.fileHandle?.file?.name">
                      <div class="active-banner-title ms-2 mb-2">{{
                        banner.bannerTitle }}</div>
                      <button
                        *ngIf="appAbility.can('delete', appAbilityKey.CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT) && !(banner.isAllParentZone && !isAllParentZonesElligible)"
                        class="delete-item-cta mb-2 me-2" (click)="openDeletePrompt(banner)">
                        <img src="/assets/icon-delete-item.svg" alt="icon-delete-banner">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf=" !activeBanners.length">
              No active banners
            </div>
          </div>
          <button *ngIf="appAbility.can('update', appAbilityKey.CUSTOMER_ADMIN_SCROLLABLE_BANNER_MANAGEMENT)"
            (click)="changeSquence()" class="sequence-update-cta border-0 p-2 w-100"
            [disabled]="!enableChangeSequenceCTA">
            Update Banner Sequence
          </button>
        </div>
      </div>
      <div class="select-sub-zone-info" *ngIf="!((selectedSubzone)
        || selectedSubzone === null)">
        Please Select the Zone and Subzone to proceed
      </div>
    </div>
  </div>
</div>

<!-- delete modal -->
<div #deletePromptModal class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Banner Deletion</h5>
        <div class="right-section">
          <img src="assets/icon-order-status-close.svg" class="cursor-pointer" alt="icon-close"
            (click)="modalClose()" />
        </div>
      </div>
      <div class="modal-body">
        <p>{{ deletePromptMessage }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalClose()" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteBanner(deleteBannerItem.bannerId!)">Delete</button>
      </div>
    </div>
  </div>
</div>
