import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { FileHandle } from '../../directives/drop-zone/drop-zone.directive';

export interface IPreBooking {
  kitchenId: string,
  cutOffHours: number;
  scheduleDate: Date;
  mealTime: string;
  itemId: string;
  maxQuantity: number;
}

export interface IGetPreBookingRequestQueryParams {
  fromDate: string;
  toDate: string;
  parentZoneId: string;
}

export interface ICreatedOrUpdatedBy {
  entityType: string,
  entityId: string,
  entityName?: string
}

export interface IPreBookingPayload {
  preBookingConfigId?: string;
  name: string;
  bannerOriginalFileName: string;
  bannerS3FileName: string
  parentZoneId: string;
  isPureVegCompatible: boolean;
  preBookingDate: string;
  isActive: boolean;
  minimumRequiredLimit: number;
  // createdBy: IModifier;
  // updatedBy: IModifier;
}

export interface IPreBookingResponse {
  preBookingConfigId: string,
  name: string,
  bannerOriginalFileName: string,
  bannerS3FileName: string
  parentZoneId: string,
  isPureVegCompatible: boolean,
  preBookingDate: string,
  minimumRequiredLimit: number,
  isActive: boolean,
  createdBy: ICreatedOrUpdatedBy,
  _id: string,
  createdAt: string
  updatedAt: string,
  __v: number,
}

export interface IGetPreBookingResponse {
  preBookingConfigId: string,
  name: string,
  bannerOriginalFileName: string,
  bannerS3FileName: string,
  parentZoneId: string,
  isPureVegCompatible: boolean,
  preBookingDate: string,
  minimumRequiredLimit: number,
  isActive: boolean,
  createdBy: ICreatedOrUpdatedBy,
  createdAt: string,
  updatedAt: string,
  __v: number,
  backgroundImageUrl: string,
  updatedBy: ICreatedOrUpdatedBy,
  fileHandle?: FileHandle,
}

@Injectable()
export class PreBookingApiService extends RestApiService {
  private basePath = environment.config['KITCHEN_SERVICE_URL'];

  private adminBasePath = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.basePath}/pre-booking/`;

  private adminServicePath = `${this.adminBasePath}/pre-booking-configurations`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async create(payload: IPreBooking) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async update(id: string, date: string, payload: IPreBooking) {
    const apiPath = `${this.servicePath}kitchen/${id}/date/${date}`;
    return this.httpPatch(apiPath, payload);
  }

  async delete(
    kitchenId: string,
    scheduleDate: string,
    mealTime: string,
    itemId: string,
  ) {
    const searchParams = new URLSearchParams();
    if (kitchenId) {
      searchParams.set('kitchenId', kitchenId);
    }
    if (scheduleDate) {
      searchParams.set('scheduleDate', scheduleDate);
    }
    if (mealTime) {
      searchParams.set('mealTime', mealTime);
    }
    if (itemId) {
      searchParams.set('itemId', itemId);
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpDelete(apiPath);
  }

  async createPreBooking(payload: IPreBookingPayload)
    : Promise<{ data: IPreBookingResponse }> {
    const apiPath = this.adminServicePath;
    return this.httpPost(apiPath, payload);
  }

  async updatePreBooking(preBookingConfigId: string, payload: IPreBookingPayload)
    : Promise<{ data: IGetPreBookingResponse }> {
    const apiPath = `${this.adminServicePath}/${preBookingConfigId}`;
    return this.httpPatch(apiPath, payload);
  }

  async getAllPreBookings(
    queryParams: IGetPreBookingRequestQueryParams,
  ): Promise<{ data: IGetPreBookingResponse[] }> {
    const searchParams = new URLSearchParams();
    if (queryParams.fromDate) {
      searchParams.set('fromDate', queryParams.fromDate);
    }
    if (queryParams.toDate) {
      searchParams.set('toDate', queryParams.toDate);
    }
    if (queryParams.parentZoneId) {
      searchParams.set('parentZoneId', queryParams.parentZoneId);
    }
    const apiPath = `${this.adminServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async deletePreBooking(preBookingConfigId: string) {
    const apiPath = `${this.adminServicePath}/${preBookingConfigId}`;
    return this.httpDelete(apiPath);
  }
}
