import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
} from '@angular/router';
import { InitialService } from '../services/initial.service';
import { AppAbility } from '../services/appAbility';
import { AppAbilityKey } from '../enums/ability-keys.enum';

@Injectable()
export class AbilityGuard implements CanActivate {
  isFirstLoad = false;

  constructor(
    private initialService: InitialService,
    private appAbility: AppAbility,
  ) { }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const baseRoute = (route.routeConfig as any).path as string;
    const baseRoutes = ['dashboard', 'user-management'];
    if (baseRoutes.includes(baseRoute)) {
      await this.initialService.initialize();
      return true;
    }
    const abilityKey = (route.data as any).abilityKey as string;
    let definedAbility$;
    definedAbility$ = this.initialService.isUserAuthorized();
    if (definedAbility$) {
      this.appAbility = definedAbility$;
      if (this.appAbility.can('view', abilityKey)) return true;
      return false;
    }
    await this.initialService.initialize();
    definedAbility$ = this.initialService.isUserAuthorized();
    if (definedAbility$) {
      this.appAbility = definedAbility$;
      if (this.appAbility.can('view', abilityKey)) return true;
      return false;
    }
    return false;
  }
}
