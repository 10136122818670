import {
  Subject, debounceTime, firstValueFrom, from,
} from 'rxjs';
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import {
  Component, ElementRef, OnInit, Renderer2, ViewChild,
} from '@angular/core';
import {
  FormControl, FormGroup, Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { KitchenApiService } from '../../../../services/api/kitchen-api.service';
import { CookStatus } from '../../../../constants/view-cook.constants';
import { CookApiService, ICookResponse, ISeller } from '../../../../services/api/cook-api.service';
import { CouponApiService, ICoupon, SellerCategory } from '../../../../services/api/coupon-api.service';
import { BannerSellerTypes, DeepLinkRedirectEnums, getDeepLinkRedirectionTypeFromEnum } from '../../../../enums/deep-link.enum';
import { SequenceConfirmationModalService } from '../../../shared/pop-ups/sequence-conformation-modal/sequence-conformation-modal.service';
import { IChoice } from '../../../../helpers/form.helpers';
import { BannerApiService, IBannerConfig } from '../../../../services/api/banner.service';
import { BannerTypes, BannerUserView } from '../../../../enums/banner-user-view.enum';
import { timezone } from '../../../../constants/time-zone.constants';
import {
  subZonalDetail,
  ParentZonalDetail, ZoneApiService,
} from '../../../../services/api/zone-api.service';
import { S3Service } from '../../../../services/upload.service';
import { BannerStatus } from '../../../../services/api/config.service';
import { generateFileHandles } from '../../../../directives/drop-zone/drop-zone-helpers';
import { getMimeTypeFromFileName } from '../../../../helpers/manipulation.helpers';
import { FileHandle } from '../../../../directives/drop-zone/drop-zone.directive';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ModalService } from '../../../../lib/modal/modal.service';
import { PreviewModalComponent } from '../../../shared/pop-ups/preview-modal/preview-modal.component';
import { AppAbilityKey } from '../../../../enums/ability-keys.enum';
import { AppAbility } from '../../../../services/appAbility';
import { InitialService } from '../../../../services/initial.service';
import { ISpecialCategoryConfig, SpecialCategoryApiService, bannerRedirectionTypes } from '../../../../services/api/special-category-api.service';

export const sequenceChoice: IChoice[] = [
  {
    name: 'First',
    value: 'first',
  },
  {
    name: 'Last',
    value: 'last',
  },
];

const defaultPaginationConfig = {
  skip: 0,
  limit: 10,
};

export enum ZoneTypeEnum {
  ALL_ZONE = 'all',
  ALL_PARENT_ZONE = 'allParentZone',
  ALL_SUB_ZONE = 'allSubZone',
}

@Component({
  selector: 'app-scrollable-banner.',
  templateUrl: './scrollable-banner.component.html',
  styleUrls: ['./scrollable-banner.component.scss'],
})
export class ScrollableBannerComponent implements OnInit {
  public bannerUploadImage: FileHandle | null = null;

  public subZoneDetails: subZonalDetail[] = [];

  public parentZonalDetails: ParentZonalDetail[] = [];

  public selectedParentZone: ParentZonalDetail | null = null;

  public selectedSubzone: subZonalDetail | null = null;

  public zoneType = ZoneTypeEnum;

  public banners: IBannerConfig[] = [];

  public cachedBanners: IBannerConfig[] = [];

  bannerStatus = BannerStatus;

  deletePromptMessage: string = '';

  deleteBannerItem!: IBannerConfig;

  form!: FormGroup;

  formSubmitted: boolean = false;

  enableChangeSequenceCTA = false;

  bannerSelectedStatus: any;

  selectedBannerUpdate!: IBannerConfig | null;

  queryParamMap!: ParamMap;

  public parentZoneId = '';

  public subZoneId = '';

  get activeBanners() {
    return this.banners.filter((banner) => banner.isActive);
  }

  bannerRedirectionTypes = [
    { id: 0, label: 'Special Category', value: 'specialCategory' },
    { id: 1, label: 'Deep Link', value: 'deepLink' },
    // { id: 2, label: 'Bottom Sheet', value: 'bottomSheet' },
  ];

  public bannerRedirectionTypesEnum = bannerRedirectionTypes;

  preferredBannerType!: string;

  public allCategory: ISpecialCategoryConfig[] = [];

  public kitchens: ISeller[] = [];

  public coupons: ICoupon[] = [];

  skip: number = 0;

  public isAllParentZonesElligible = false;

  public deepLinkRedirections = Object.values(DeepLinkRedirectEnums);

  public getDeepLinkRedirectionType = getDeepLinkRedirectionTypeFromEnum;

  public generatedDeepLink = '';

  searchUpdate = new Subject<string>();

  kitchenSearchUpdate = new Subject<string>();

  couponSearchUpdate = new Subject<string>();

  @ViewChild('deletePromptModal', { static: false }) deletePromptModal!: ElementRef;

  public searchText!: string;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private modalService: ModalService,
    private modalConfig: ModalConfig,
    private readonly toast: HotToastService,
    private readonly bannerService: BannerApiService,
    private readonly s3Service: S3Service,
    private readonly renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly zoneApiService: ZoneApiService,
    private readonly initialService: InitialService,
    public appAbility: AppAbility,
    public sequenceConformationModalService: SequenceConfirmationModalService,
    private readonly specialCategoryApiService: SpecialCategoryApiService,
    private readonly cookApiService: CookApiService,
    private couponService: CouponApiService,
    private kitchenApiService: KitchenApiService,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
    this.createForm();
  }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  async ngOnInit() {
    this.searchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchCategory(searchText);
    });

    this.kitchenSearchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchKitchens(searchText);
    });

    this.couponSearchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchCoupons(searchText);
    });
    const eligibleParentZoneIds = await this.initialService.getElgibleParentZones();
    if (Array.isArray(eligibleParentZoneIds) && !eligibleParentZoneIds.includes('all')) {
      this.isAllParentZonesElligible = false;
      const responses = await this.zoneApiService.getAllEligibleParentZone();
      this.parentZonalDetails = responses.data.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    } else {
      this.isAllParentZonesElligible = true;
      const responses: Array<ParentZonalDetail> = [];
      responses.push(...(await this.zoneApiService.getAllParentZone()).data);
      this.parentZonalDetails = responses.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    }
    this.getParentParams();
  }

  async getParentParams() {
    const queryParamMap = await firstValueFrom(this.activatedRoute.queryParamMap);
    this.parentZoneId = queryParamMap.get('parentZoneId') || '';
    if (this.parentZoneId) {
      const filterParentZone = this.parentZonalDetails.find(
        (item: ParentZonalDetail) => (
          item.parentZoneId === this.parentZoneId
        ),
      );
      if (filterParentZone) {
        this.selectedParentZone = filterParentZone;
        this.getAllSubzonesForParent(this.selectedParentZone.parentZoneId!);
      } else {
        this.setDefaultSelectedParentZone();
      }
    } else {
      await this.router.navigate([], {
        queryParams: {
          parentZoneId: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      this.setDefaultSelectedParentZone();
    }
  }

  setDefaultSelectedParentZone() {
    if (this.isAllParentZonesElligible) {
      this.selectedParentZone = null;
      this.getSubZoneParams();
    } else {
      [this.selectedParentZone] = this.parentZonalDetails;
      this.getAllSubzonesForParent(this.selectedParentZone.parentZoneId!);
    }
  }

  async getSubZoneParams() {
    const queryParamMap = await firstValueFrom(this.activatedRoute.queryParamMap);
    this.subZoneId = queryParamMap.get('subZoneId') || '';
    const filterSubZone = this.subZoneDetails.find(
      (item: subZonalDetail) => (
        item.subZoneId === this.subZoneId
      ),
    );
    if (filterSubZone) {
      this.selectedSubzone = filterSubZone;
    } else if (this.subZoneId === null) {
      this.selectedSubzone = null;
    } else {
      this.router.navigate([], {
        queryParams: {
          subZoneId: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      this.selectedSubzone = null;
    }
    this.loadDependencies();
  }

  private createForm() {
    this.form = new FormGroup({
      banner: new FormControl(''),
      bannerId: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      effectiveFrom: new FormControl({ value: null, disabled: true }, [Validators.required]),
      expireAfter: new FormControl({ value: null, disabled: true }, [Validators.required]),
      description: new FormControl('', [Validators.maxLength(255)]),
      restrictForPureVeg: new FormControl(false),
      redirectionType: new FormControl(null),
      redirectionId: new FormControl(null),
      deepLinkUrl: new FormControl(''),
    });

    this.form.get('redirectionType')?.valueChanges.subscribe((value) => {
      const redirectionIdControl = this.form.get('redirectionId');
      const deepLinkUrlControl = this.form.get('deepLinkUrl');
      if (value) {
        redirectionIdControl?.setValidators(Validators.required);
        deepLinkUrlControl?.setValidators(Validators.required);

        if (value === 'others') {
          redirectionIdControl?.reset();
          deepLinkUrlControl?.enable();

          this.generatedDeepLink = '';
          deepLinkUrlControl?.setValue(this.generatedDeepLink);

          redirectionIdControl?.clearValidators();
        }

        redirectionIdControl?.setValue(null);
        deepLinkUrlControl?.setValue(null);
      } else {
        redirectionIdControl?.reset();
        redirectionIdControl?.clearValidators();
        deepLinkUrlControl?.clearValidators();

        this.generatedDeepLink = '';
        deepLinkUrlControl?.setValue(this.generatedDeepLink);
      }
      redirectionIdControl?.updateValueAndValidity();
      deepLinkUrlControl?.updateValueAndValidity();
      switch (value) {
        case 'special-category':
          this.skip = 0;
          this.fetchCategory();
          break;
        case 'kitchen':
          this.skip = 0;
          this.fetchKitchens();
          break;
        case 'coupon-description':
          this.skip = 0;
          this.fetchCoupons();
          break;
        default:
          console.log('Reidrection Type - Others');
      }
    });

    this.form.controls['redirectionId']?.valueChanges.subscribe((value) => {
      if (value) {
        const { redirectionType } = this.form.value;
        const redirectionId = value;
        this.searchText = '';

        this.generatedDeepLink = this.deepLinkGenerator(redirectionType, redirectionId);
        if (this.generatedDeepLink) {
          this.form.controls['deepLinkUrl'].setValue(this.generatedDeepLink);
          this.form.controls['deepLinkUrl'].disable();
        } else {
          this.form.controls['deepLinkUrl'].enable();
        }
      } else {
        this.generatedDeepLink = '';
        this.form.controls['deepLinkUrl'].enable();
      }
      this.form.get('specialCategoryTag')?.updateValueAndValidity();
      this.form.get('deepLinkUrl')?.updateValueAndValidity();
    });
  }

  private async loadDependencies() {
    this.selectedBannerUpdate = null;
    const query: any = {
      bannerFor: BannerUserView.CUSTOMER,
      bannerType: BannerTypes.SCROLLABLE_BANNER,
      sellerType: BannerSellerTypes.FOOD_ITEMS,
    };
    if (this.selectedParentZone?.parentZoneId) {
      query.parentZoneId = this.selectedParentZone?.parentZoneId;
    } else {
      query.isAllParentZone = true;
    }
    if (this.selectedSubzone?.subZoneId) {
      query.subZoneId = this.selectedSubzone?.subZoneId;
    } else {
      query.isAllSubZone = true;
    }
    const response = await this.bannerService.getBannersInSequence(query);
    this.banners = [];
    this.cachedBanners = [];
    const banners: IBannerConfig[] = response.data;
    banners.forEach((banner) => {
      if (banner.bannerOriginalFileName) {
        fetch(banner.bannerImageUrl!)
          .then(async (response) => {
            const data = await response.blob();
            const fileName = banner.bannerOriginalFileName || data.type;
            const type = getMimeTypeFromFileName(fileName);
            const file = new File([data], fileName, {
              type: type!,
            });
            const [fileHandle] = generateFileHandles([file] as any, this.sanitizer);
            banner.fileHandle = fileHandle;
          });
      }
    });
    this.banners = banners;
    this.cachedBanners = [...banners];
  }

  async fetchCoupons(searchText?: string) {
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }
      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
      };

      if (searchText) {
        query.couponCode = searchText?.trim();
      }

      const { coupons } = (await this.couponService.getAllCoupons(
        this.skip,
        defaultPaginationConfig.limit,
        searchText?.trim(),
        undefined,
        SellerCategory.FOOD_SELLER,
      )).data;

      if (coupons.length) {
        this.skip += defaultPaginationConfig.limit;
        if (searchText) {
          this.coupons = coupons;
        } else {
          this.coupons.push(...coupons);
          this.coupons = this.coupons.reduce((unique: ICoupon[], coupon) => {
            const existingCoupon = unique.find((c) => c.couponId === coupon.couponId);
            if (!existingCoupon) {
              unique.push(coupon);
            }
            return unique;
          }, []);
        }
      }
    } catch (error) {
      this.coupons = [];
    }
  }

  async fetchKitchens(searchText?: string) {
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }
      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
        status: ['active'],
        parentZoneId: this.selectedParentZone?.parentZoneId,
        isProductSeller: false,
      };
      if (searchText) {
        query.searchFor = 'kitchen';
        query.search = searchText?.trim();
      }

      const kitchens = (await this.cookApiService.getAllSellers(query)).data.kitchenProfiles;
      if (kitchens.length) {
        this.skip += defaultPaginationConfig.limit;

        if (searchText) {
          this.kitchens = kitchens;
        } else {
          this.kitchens.push(...kitchens);
          this.kitchens = this.kitchens.reduce((unique: ISeller[], kitchen) => {
            const existingKitchen = unique.find((c) => c.kitchenId === kitchen.kitchenId);
            if (!existingKitchen) {
              unique.push(kitchen);
            }
            return unique;
          }, []);
        }
      }
    } catch (error) {
      this.kitchens = [];
    }
  }

  async fetchCategory(searchText?: string) { // need to revisit
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }

      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
        parentZoneId: this.selectedParentZone?.parentZoneId,
      };

      if (searchText) {
        query.searchText = searchText;
      }

      const newCategories = (await this.specialCategoryApiService.getSpecialCategories(query))
        .data.specialCategories;

      if (newCategories.length) {
        this.skip += defaultPaginationConfig.limit;

        if (searchText) {
          this.allCategory = newCategories;
        } else {
          this.allCategory.push(...newCategories);
          this.allCategory = this.allCategory
            .reduce<ISpecialCategoryConfig[]>((unique, category) => {
              const existingCategory = unique.find(
                (c) => c.specialCategoryId === category.specialCategoryId,
              );
              if (!existingCategory) {
                unique.push(category);
              }
              return unique;
            }, []);
        }
      }
    } catch (error) {
      this.allCategory = [];
    }
  }

  onImageUpload(event: Event) {
    event.stopPropagation();
    const { files } = event.target as any;
    const fileList = generateFileHandles(files, this.sanitizer);
    this.onImageDrop(fileList);
    if (event.target) {
      (event.target as any).value = '';
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async onImageDrop(fileList: FileHandle[]) {
    if (fileList.length) {
      const file = fileList[0];
      if (await this.validateFile(file.file)) {
        this.bannerUploadImage = null;
        this.bannerUploadImage = file;
      }
    }
  }

  async getAllSubzonesForParent(id: string) {
    const subZoneResponse = (await this.zoneApiService.getAllSubzonesForParent(id)).data;
    this.subZoneDetails = subZoneResponse.sort(
      (a, b) => a.subZoneName.localeCompare(b.subZoneName),
    );
    this.getSubZoneParams();
  }

  onParentZoneSelect(zoneDetails: ParentZonalDetail | null) {
    this.subZoneDetails = [];
    this.selectedSubzone = null;
    this.selectedParentZone = zoneDetails;
    this.banners = [];
    this.cachedBanners = [];
    this.router.navigate([], {
      queryParams: {
        parentZoneId: this.selectedParentZone?.parentZoneId || null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
    if (zoneDetails) {
      this.getAllSubzonesForParent(zoneDetails.parentZoneId!);
    } else {
      this.getSubZoneParams();
    }
    this.form.controls['redirectionId'].setValue(null);
    this.skip = defaultPaginationConfig.skip;
    this.allCategory = [];
    this.fetchCategory();
    this.kitchens = [];
    this.fetchKitchens();
  }

  validateFile(file: File) {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if ((width / height).toFixed(2) !== (164 / 57).toFixed(2)) {
          this.toast.error('Please upload an image with the specified dimensions. Supported Aspect ratio - 164:57');
          // if ((width / height).toFixed(2) !== (290 / 136).toFixed(2)) {
          //   this.toast.error('Please upload an image with the specified dimensions. Supported Aspect ratio - 145:68');
          resolve(false);
          return;
        }
        resolve(true);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        return reject;
      };
    });
    img.src = objectUrl;
    return promise;
  }

  previewImage(event: Event, data: any) {
    event.stopPropagation();
    const uploadUrl: SafeUrl = data.url;
    this.modalConfig.data = uploadUrl;
    this.modalService.open(PreviewModalComponent);
  }

  async deleteBanner(bannerId: string) {
    try {
      await this.bannerService.deleteBanner(bannerId);
      this.modalClose();
    } catch (e) {
      console.log(e);
    }
    this.resetForm();
    this.banners = this.banners.filter((item) => item.bannerId !== bannerId);
    this.cachedBanners = [...this.banners];
  }

  onSubzoneSelect(zone: subZonalDetail | null) {
    this.banners = [];
    this.cachedBanners = [];
    this.selectedSubzone = zone;
    this.router.navigate([], {
      queryParams: {
        subZoneId: this.selectedSubzone?.subZoneId || null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
    this.resetForm();
    this.loadDependencies();
  }

  onSelectStatus(status: boolean) {
    this.bannerSelectedStatus = status;
  }

  async saveChange() {
    this.formSubmitted = true;
    if (this.form.valid && this.bannerUploadImage && this.bannerSelectedStatus !== undefined) {
      if (this.form.value?.description?.length > 255) {
        this.toast.warning('Description cannot be more than 255 characters!');
        this.formSubmitted = false;
        return;
      }
      const check = this.selectedSubzone && this.selectedParentZone;
      if (this.selectedSubzone?.subZoneId === undefined
        && this.selectedSubzone !== null) {
        this.toast.warning('Please select the Zone first!');
        this.formSubmitted = false;
        return;
      }
      const formValues = this.form.getRawValue();
      let fileName = '';
      let fileExtension = '';
      if (this.bannerUploadImage?.file) {
        fileExtension = this.bannerUploadImage?.file.name.split('.').pop() || '';
        fileName = `cook-image-${uuid()}.${fileExtension}`;
        // fileName = this.selectedBannerUpdate?.bannerS3FileName || `cook-image-${uuid()}.${fileExtension}`; might be a backend issue, temporarily fixing from front end
        await this.s3Service.upload(this.bannerUploadImage.file, {
          fileName,
          contentType: this.bannerUploadImage.file.type,
        });
        if (this.selectedBannerUpdate) {
          // update-banner
          const updateBannerPayload: IBannerConfig = {
            bannerId: formValues.bannerId,
            bannerOriginalFileName: this.bannerUploadImage.file.name,
            bannerS3FileName: fileName,
            isActive: this.bannerSelectedStatus || false,
            bannerTitle: formValues.title,
            effectiveFrom: moment(formValues.effectiveFrom).tz(timezone).startOf('day').toISOString(),
            expireAfter: moment(formValues.expireAfter).tz(timezone).endOf('day').toISOString(),
            subZoneId: this.selectedBannerUpdate?.subZoneId,
            parentZoneId: this.selectedBannerUpdate?.parentZoneId,
            isAllParentZone: this.selectedBannerUpdate?.isAllParentZone,
            isAllSubZone: this.selectedBannerUpdate?.isAllSubZone,
            isPureVegCompatible: !formValues.restrictForPureVeg || false,
            bannerFor: BannerUserView.CUSTOMER,
            bannerType: BannerTypes.SCROLLABLE_BANNER,
            sellerType: BannerSellerTypes.FOOD_ITEMS,
          };
          if (formValues.description !== '') {
            updateBannerPayload.description = formValues.description;
          }
          if (formValues.deepLinkUrl) {
            updateBannerPayload.clickAction = formValues.deepLinkUrl;
          }
          const configUpdatePromise = this.bannerService.updateBanner(
            formValues.bannerId,
            updateBannerPayload,
          );
          from(configUpdatePromise).pipe(
            this.toast.observe({
              success: 'Banner updated successfully',
              loading: 'Updating Banner',
              error: (e) => {
                this.formSubmitted = false;
                return (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong';
              },
            }),
          ).subscribe();
          await configUpdatePromise;
          this.loadDependencies();
          this.bannerUploadImage = null;
          this.selectedBannerUpdate = null;
          this.resetForm();
        } else {
          // create-banner
          const confirmation = await this.sequenceConformationModalService
            .showConfirmationPopup(sequenceChoice);
          if (!confirmation) {
            this.formSubmitted = false;
            this.toast.error('Please Select Section Sequence');
            return;
          }
          const createBannerPayload: IBannerConfig = {
            bannerOriginalFileName: this.bannerUploadImage?.file.name!,
            bannerS3FileName: fileName,
            isActive: this.bannerSelectedStatus || false,
            bannerTitle: formValues.title,
            effectiveFrom: moment(formValues.effectiveFrom).tz(timezone).startOf('day').toISOString(),
            expireAfter: moment(formValues.expireAfter).tz(timezone).endOf('day').toISOString(),
            subZoneId: this.selectedSubzone?.subZoneId! || null,
            parentZoneId: this.selectedParentZone?.parentZoneId! || null,
            isAllParentZone: !this.selectedParentZone?.parentZoneId!,
            isAllSubZone: !this.selectedSubzone?.subZoneId!,
            isPureVegCompatible: !formValues.restrictForPureVeg || false,
            bannerFor: BannerUserView.CUSTOMER,
            bannerType: BannerTypes.SCROLLABLE_BANNER,
            sellerType: BannerSellerTypes.FOOD_ITEMS,
          };
          if (formValues.description !== '') {
            createBannerPayload.description = formValues.description;
          }
          if (formValues.deepLinkUrl) {
            createBannerPayload.clickAction = formValues.deepLinkUrl;
          }
          const configUpdatePromise = this.bannerService
            .createBanner(confirmation, createBannerPayload);

          from(configUpdatePromise).pipe(
            this.toast.observe({
              success: 'Banner created successfully',
              loading: 'Creating Banner',
              error: (e) => {
                this.formSubmitted = false;
                return (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong';
              },
            }),
          ).subscribe();
          await configUpdatePromise;
          this.loadDependencies();
          this.bannerUploadImage = null;
          this.selectedBannerUpdate = null;
          this.resetForm();
        }
      }
    } else {
      this.toast.warning('Please enter valid input!');
    }
    setTimeout(() => {
      this.formSubmitted = false;
    }, 2000);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.banners, event.previousIndex, event.currentIndex);
    const isStringEqual = isEqual(this.banners, this.cachedBanners);
    if (!this.enableChangeSequenceCTA && !isStringEqual) {
      this.enableChangeSequenceCTA = true;
    }
  }

  openDeletePrompt(bannerItem: IBannerConfig) {
    this.deleteBannerItem = bannerItem;
    this.deletePromptMessage = `Would you like to delete ${bannerItem.bannerTitle}?`;
    this.renderer.addClass(this.deletePromptModal?.nativeElement, 'show');
    this.deletePromptModal?.nativeElement.focus();
  }

  async onSelectBanner(banner: IBannerConfig) {
    this.resetForm();
    this.selectedBannerUpdate = banner;
    this.form.patchValue({
      bannerId: banner.bannerId,
      title: banner.bannerTitle,
      effectiveFrom: moment(banner.effectiveFrom).tz(timezone).endOf('day').toISOString(),
      expireAfter: banner.expireAfter,
      description: banner.description,
      restrictForPureVeg: banner.isPureVegCompatible !== undefined
        ? !banner.isPureVegCompatible : false,
    });
    if (banner.clickAction) {
      this.form.patchValue({
        deepLinkUrl: banner.clickAction,
      });
      if (banner.clickAction.includes('special-category')) {
        const specialCategoryId = this.extractRedirectId(banner.clickAction);
        if (specialCategoryId && specialCategoryId !== 'null') {
          this.form.patchValue({
            redirectionType: this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY,
            redirectionId: specialCategoryId,
          });
          const selectedSpecialCategory: ISpecialCategoryConfig = (await this.specialCategoryApiService.getSpecialCategoryById(specialCategoryId)).data;
          this.allCategory.unshift(selectedSpecialCategory);
        }
      } else if (banner.clickAction.includes('kitchen')) {
        const kitchenId = this.extractRedirectId(banner.clickAction);
        if (kitchenId && kitchenId !== 'null') {
          this.form.patchValue({
            redirectionType: this.bannerRedirectionTypesEnum.KITCHEN,
            redirectionId: kitchenId,
          });
          const selectedKitchen = (await this.kitchenApiService.get(kitchenId)).data;
          this.kitchens.unshift({
            kitchenName: selectedKitchen?.kitchenName,
            cookId: selectedKitchen?.cookId,
            kitchenId: selectedKitchen?.kitchenId,
            address: selectedKitchen?.address,
            assets: selectedKitchen?.assets,
            coverImageOriginalFileName: selectedKitchen?.coverImageOriginalFileName,
            coverImageS3FileName: selectedKitchen?.coverImageS3FileName,
            createdAt: selectedKitchen?.createdAt,
            foodCuisineStyle: selectedKitchen?.foodCuisineStyle,
            foodType: selectedKitchen?.foodType,
            isActive: selectedKitchen?.isActive,
            sellerCreatedAt: '',
            sellerName: '',
            sellerPhoneNumber: '',
            sellerStatus: '',
            product: {
              kitchenName: '',
              isActive: false,
              isForceDisabled: false,
              revenueSharingPercentage: 0,
            },
          });
        }
      } else if (banner.clickAction.includes('coupon-description')) {
        const couponId = this.extractRedirectId(banner.clickAction);
        if (couponId && couponId !== 'null') {
          this.form.patchValue({
            redirectionType: this.bannerRedirectionTypesEnum.COUPON,
            redirectionId: couponId,
          });
          const selectedCoupon = (await this.couponService.get(couponId)).data;
          this.coupons.unshift(selectedCoupon);
        }
      } else {
        this.form.patchValue({
          redirectionType: this.bannerRedirectionTypesEnum.OTHERS,
          deepLinkUrl: banner.clickAction,
        });
      }
    }
    this.bannerUploadImage = banner.fileHandle!;
    this.bannerSelectedStatus = banner?.isActive;
  }

  extractRedirectId(url: string): string | null {
    const match = url.match(/redirectId=([^&]*)/);
    return match ? match[1] : null;
  }

  removeUploadedFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.bannerUploadImage = null;
  }

  modalClose() {
    this.renderer.removeClass(this.deletePromptModal?.nativeElement, 'show');
  }

  async changeSquence() {
    this.enableChangeSequenceCTA = false;
    if (this.banners.length) {
      const bannerIds = this.banners.map((banner) => banner.bannerId!);
      const payload = {
        bannerIds,
      };
      let zone: string = ZoneTypeEnum.ALL_PARENT_ZONE;
      let parentZoneId = '';
      if (this.selectedParentZone?.parentZoneId && this.selectedSubzone === null) {
        zone = ZoneTypeEnum.ALL_SUB_ZONE;
        parentZoneId = this.selectedParentZone.parentZoneId;
      } else if (this.selectedSubzone?.subZoneId) {
        zone = this.selectedSubzone.subZoneId;
      }
      const bannerSequenceUpdatePromise = this.bannerService.updateBannerSequence(
        zone!,
        BannerUserView.CUSTOMER,
        payload,
        parentZoneId,
        BannerSellerTypes.FOOD_ITEMS,
      );
      from(bannerSequenceUpdatePromise).pipe(
        this.toast.observe({
          success: 'Banner sequence updated successfully',
          loading: 'Updating Banner Sequence',
          error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
        }),
      ).subscribe();
      await bannerSequenceUpdatePromise;
    }
  }

  addBanner() {
    this.resetForm();
  }

  resetForm() {
    this.formSubmitted = false;
    this.form.reset();
    this.bannerUploadImage = null;
    this.selectedBannerUpdate = null;
    this.bannerSelectedStatus = null;
  }

  onSubzoneInputSelect(subZone: subZonalDetail | null) {
    this.selectedSubzone = subZone;
    this.banners = [];
    this.cachedBanners = [];
  }

  // resetBannerRedirectionTypeValue() {
  //   if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY) {
  //     // this.form.controls['deepLinkUrl'].setValue('');
  //   } else if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.DEEP_LINK) {
  //     this.form.controls['specialCategoryTag'].setValue('');
  //   } else {
  //     this.form.controls['specialCategoryTag'].setValue('');
  //     this.form.controls['deepLinkUrl'].setValue('');
  //   }
  // }

  onSearchCategory(searchText: string) {
    this.searchUpdate.next(searchText?.trim());
  }

  onSearchKitchen(searchText: string) {
    this.kitchenSearchUpdate.next(searchText?.trim());
  }

  onSearchCoupon(searchText: string) {
    this.couponSearchUpdate.next(searchText?.trim());
  }

  onClose() {
    this.searchText = '';
    this.skip = 0;
    if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY) {
      this.onSearchCategory(this.searchText);
    } else if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.KITCHEN) {
      this.onSearchKitchen(this.searchText);
    } else if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.COUPON) {
      this.onSearchCoupon(this.searchText);
    }
  }

  deepLinkGenerator(redirectionType: string = '', redirectionId: string = '') {
    const baseUrl = 'https://cookr.in';
    let generatedDeepLink = '';
    if (redirectionType && redirectionId) {
      generatedDeepLink = `${baseUrl}?redirect=${redirectionType}&redirectId=${redirectionId}`;
    }
    return generatedDeepLink;
  }
}
