import { MatFormFieldModule } from '@angular/material/form-field';
import {
  Component, EventEmitter, Inject, Input, NgModule, OnInit, Output,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import {
  MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {
  FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  CheckboxGroupControl, CheckboxRequiredValidator, generateCheckboxGroupControl, IChoice,
} from '../../../../helpers/form.helpers';

@Component({
  selector: 'app-sequence-conformation-modal',
  templateUrl: './sequence-conformation-modal.component.html',
  styleUrls: ['./sequence-conformation-modal.component.scss'],
})
export class SequenceConformationModalComponent implements OnInit {
  @Input() checkBoxOptions!: IChoice[];

  @Output() result = new EventEmitter<string>();

  form!: FormGroup;

  public formSubmitted = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SequenceConformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IChoice[],
  ) { }

  public queryGroup!: CheckboxGroupControl;

  ngOnInit(): void {
    const queryGroup = generateCheckboxGroupControl(
      'queryGroup',
      this.data,
      [new CheckboxRequiredValidator().validate],
    );
    this.queryGroup = cloneDeep(queryGroup);
    this.form = new FormGroup({
      selectQuery: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      this.formSubmitted = true;
    }
    const formValues = this.form.value;
    this.result.next(formValues.selectQuery);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
@NgModule({
  declarations: [
    SequenceConformationModalComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [SequenceConformationModalComponent],
})
export class SequenceConfirmationModalModule { }
