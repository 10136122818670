<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content p-3">
    <div class="modal-header d-flex justify-content-between align-items-start p-0 px-1">
      <div class="modal-title">
        <h5>Order Activity Logs</h5>
        <span>Reference ID: #{{orderDetails?.referenceId}}</span>
        <div class="order-type-select-container w-100 my-2">
          <label class="me-3">Select Log Type: </label>
          <select [(ngModel)]="logType" (change)="onLogTypeChange(orderDetails!)">
            <option value="all">All</option>
            <option value="call">Call</option>
            <option value="coupon">Coupon</option>
            <option value="missedCall">Missed Call</option>
            <option value="notification">Delay Notification</option>
          </select>
        </div>
      </div>
      <div class="view-filter-wrapper">
        <div class="right-section" (click)="modalClose()">
          <img src="assets/icon-order-status-close.svg" alt="icon-close">
        </div>
      </div>
    </div>
    <div class="modal-body p-0 pt-3 pb-2">
      <section class="call-logs-section">
        <div *ngFor="let notificationLog of notificationLogs" class="logs-container mb-2" [ngClass]="
          notificationLog.eventType===notificationEventType.NOTIFICATION ? 'notification-logs' :
          notificationLog.eventType===notificationEventType.CALL ? notificationLog.customerResponse :
          notificationLog.eventType===notificationEventType.COUPON ? 'notification-coupon': ''">
          <div *ngIf="notificationLog.eventType === notificationEventType.CALL">
            <span><b>Customer Response:</b> {{notificationLog.customerResponse}}</span> <br>
            <span *ngIf="notificationLog.remarks" class="word-wrap"><b>Remarks:</b> {{notificationLog.remarks}}</span>
            <br>
          </div>
          <div *ngIf="notificationLog.eventType === notificationEventType.MISSED_CALL">
            <span><b>Missed Call</b></span>
          </div>
          <div *ngIf="notificationLog.eventType === notificationEventType.NOTIFICATION">
            <span>
              <b>Message:</b>
              {{
              notificationLog.notificationMessageByTemplate ? notificationLog.notificationMessageByTemplate :
              (notificationLog.notificationMessage ? notificationLog.notificationMessage : '')
              }}
            </span> <br>
          </div>
          <div *ngIf="notificationLog.eventType === notificationEventType.COUPON">
            <span><b>Coupon Name:</b> {{notificationLog.couponName || ''}}</span> <br>
            <span *ngIf="notificationLog.remarks" class="word-wrap"><b>Remarks:</b> {{notificationLog.remarks}}</span>
            <br>
          </div>
          <div class="w-100 d-flex align-items-center justify-content-end" *ngIf="notificationLog.createdBy">-
            {{notificationLog.createdBy.entityName || ''}} {{notificationLog.createdBy.entityType || ''}}
            <span *ngIf="notificationLog.updatedAt">
              ( {{notificationLog.updatedAt| date:"dd MMM yyyy | hh:mm a"}} )
            </span>
          </div>
        </div>
        <div class="logs-empty-state d-flex justify-content-center align-items-center py-3"
          *ngIf="!notificationLogs?.length">
          <span class="text-muted">No Logs Found!</span>
        </div>
      </section>
    </div>
  </div>
</div>