import {
  Subject, debounceTime, firstValueFrom, from,
} from 'rxjs';
/* eslint-disable func-names */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import {
  Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild,
} from '@angular/core';
import {
  FormControl, FormGroup, Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HotToastService } from '@ngneat/hot-toast';
import moment from 'moment-timezone';
import { ThemePalette } from '@angular/material/core';
import {
  ISpecialCategoryConfig,
  SpecialCategoryApiService,
  addressBarTextColorConfig,
  bannerRedirectionTypes,
  statusBarBgColorConfig,
  statusBarTextColorConfig,
} from '../../../../services/api/special-category-api.service';
import { IChoice } from '../../../../helpers/form.helpers';
import { BannerApiService, IThemeBanner } from '../../../../services/api/banner.service';
import { BannerTypes, BannerUserView } from '../../../../enums/banner-user-view.enum';
import { timezone } from '../../../../constants/time-zone.constants';
import {
  ParentZonalDetail, ZoneApiService,
} from '../../../../services/api/zone-api.service';
import { S3Service } from '../../../../services/upload.service';
import { BannerStatus } from '../../../../services/api/config.service';
import { generateFileHandles } from '../../../../directives/drop-zone/drop-zone-helpers';
import { FileHandle } from '../../../../directives/drop-zone/drop-zone.directive';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ModalService } from '../../../../lib/modal/modal.service';
import { AppAbilityKey } from '../../../../enums/ability-keys.enum';
import { AppAbility } from '../../../../services/appAbility';
import { InitialService } from '../../../../services/initial.service';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { ModalRef } from '../../../../lib/modal/modal-ref';

import { KitchenApiService } from '../../../../services/api/kitchen-api.service';
import { CookStatus } from '../../../../constants/view-cook.constants';
import { CookApiService, ICookResponse, ISeller } from '../../../../services/api/cook-api.service';
import { CouponApiService, ICoupon } from '../../../../services/api/coupon-api.service';
import { DeepLinkRedirectEnums, getDeepLinkRedirectionTypeFromEnum } from '../../../../enums/deep-link.enum';

enum ManipulationType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const defaultPaginationConfig = {
  limit: 10,
  skip: 0,
  currentPage: 1,
  totalItems: 0,
};

@Component({
  selector: 'app-manipulate-theme-banner',
  templateUrl: './manipulate-theme-banner.component.html',
  styleUrls: ['./manipulate-theme-banner.component.scss'],
})
export class ManipulateThemeBannerComponent implements OnInit {
  public bannerUploadImage: FileHandle | null = null;

  public parentZonalDetails: ParentZonalDetail[] = [];

  public selectedParentZone: ParentZonalDetail | null = null;

  public banners: IThemeBanner[] = [];

  bannerStatus = BannerStatus;

  deletePromptMessage: string = '';

  deleteBannerItem!: IThemeBanner;

  form!: FormGroup;

  formSubmitted: boolean = false;

  enableChangeSequenceCTA = false;

  bannerSelectedStatus: any;

  selectedBannerUpdate!: IThemeBanner | null;

  queryParamMap!: ParamMap;

  public parentZoneId = '';

  skip: number = 0;

  get activeBanners() {
    return this.banners.filter((banner) => banner.isActive);
  }

  manipulationType = ManipulationType.ADD;

  manipulationTypes = ManipulationType;

  @ViewChild('deletePromptModal', { static: false }) deletePromptModal!: ElementRef;

  public bgColorConfigEnum = statusBarBgColorConfig;

  public textColorConfigEnum = statusBarTextColorConfig;

  public addressBarTextColorConfigEnum = addressBarTextColorConfig;

  public statusBarBgColors = Object.values(this.bgColorConfigEnum);

  public statusBarTextColors = Object.values(this.textColorConfigEnum);

  public addressBarTextColors = Object.values(this.addressBarTextColorConfigEnum);

  public disabled = false;

  public color: ThemePalette = 'primary';

  public touchUi = false;

  uniqueCategories: ISpecialCategoryConfig[] = [];

  public searchedCategory !: string;

  public bannerRedirectionTypesEnum = bannerRedirectionTypes;

  public deepLinkRedirections = Object.values(DeepLinkRedirectEnums);

  public getDeepLinkRedirectionType = getDeepLinkRedirectionTypeFromEnum;

  public generatedDeepLink = '';

  searchUpdate = new Subject<string>();

  kitchenSearchUpdate = new Subject<string>();

  couponSearchUpdate = new Subject<string>();

  public allCategory: ISpecialCategoryConfig[] = [];

  public kitchens: ISeller[] = [];

  public coupons: ICoupon[] = [];

  public searchText!: string;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private modalService: ModalService,
    public modalConfig: ModalConfig,
    private readonly toast: HotToastService,
    private readonly bannerService: BannerApiService,
    private readonly specialCategoryApiService: SpecialCategoryApiService,
    private readonly s3Service: S3Service,
    private readonly renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly zoneApiService: ZoneApiService,
    private readonly initialService: InitialService,
    public appAbility: AppAbility,
    public modalRef: ModalRef,

    private readonly cookApiService: CookApiService,
    private couponService: CouponApiService,
    private kitchenApiService: KitchenApiService,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
    this.createForm();
    this.searchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.fetchCategory(searchText);
      this.searchedCategory = searchText;
    });
  }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  extractRedirectId(url: string): string | null {
    const match = url.match(/redirectId=([^&]*)/);
    return match ? match[1] : null;
  }

  async ngOnInit() {
    this.searchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchCategory(searchText);
    });

    this.kitchenSearchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchKitchens(searchText);
    });

    this.couponSearchUpdate.pipe(debounceTime(400)).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.skip = 0;
      this.fetchCoupons(searchText);
    });

    if (this.modalConfig.data.manipulationType) {
      this.manipulationType = this.modalConfig.data.manipulationType;
    }
    if (this.manipulationType === ManipulationType.EDIT) {
      if (this.modalConfig.data.theme) {
        const { theme }: { theme: IThemeBanner } = this.modalConfig.data;
        this.selectedBannerUpdate = theme;
        this.form.patchValue({
          parentZoneId: theme.parentZoneId,
          bannerId: theme.bannerId,
          title: theme.bannerTitle,
          effectiveFrom: moment(theme.effectiveFrom).tz(timezone).endOf('day').toISOString(),
          expireAfter: theme.expireAfter,
          description: theme.description,
          bannerStatus: theme.isActive,
        });
        this.form.get('parentZoneId')?.disable();
        this.fetchCategory();
        this.bannerUploadImage = theme.fileHandle!;
        this.bannerSelectedStatus = theme?.isActive;

        if (theme.clickAction) {
          this.form.patchValue({
            deepLinkUrl: theme.clickAction,
          });
          if (theme.clickAction.includes('special-category')) {
            const specialCategoryId = this.extractRedirectId(theme.clickAction);
            if (specialCategoryId && specialCategoryId !== 'null') {
              this.form.patchValue({
                redirectionType: this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY,
                redirectionId: specialCategoryId,
              });
              const selectedSpecialCategory: ISpecialCategoryConfig = (await this.specialCategoryApiService.getSpecialCategoryById(specialCategoryId)).data;
              this.allCategory.unshift(selectedSpecialCategory);
            }
          } else if (theme.clickAction.includes('kitchen')) {
            const kitchenId = this.extractRedirectId(theme.clickAction);
            if (kitchenId && kitchenId !== 'null') {
              this.form.patchValue({
                redirectionType: this.bannerRedirectionTypesEnum.KITCHEN,
                redirectionId: kitchenId,
              });
              const selectedKitchen = (await this.kitchenApiService.get(kitchenId)).data;
              this.kitchens.unshift({
                kitchenName: selectedKitchen?.kitchenName,
                cookId: selectedKitchen?.cookId,
                kitchenId: selectedKitchen?.kitchenId,
                address: selectedKitchen?.address,
                assets: selectedKitchen?.assets,
                coverImageOriginalFileName: selectedKitchen?.coverImageOriginalFileName,
                coverImageS3FileName: selectedKitchen?.coverImageS3FileName,
                createdAt: selectedKitchen?.createdAt,
                foodCuisineStyle: selectedKitchen?.foodCuisineStyle,
                foodType: selectedKitchen?.foodType,
                isActive: selectedKitchen?.isActive,
                sellerCreatedAt: '',
                sellerName: '',
                sellerPhoneNumber: '',
                sellerStatus: '',
                product: {
                  kitchenName: '',
                  isActive: false,
                  isForceDisabled: false,
                },
              });
            }
          } else if (theme.clickAction.includes('coupon-description')) {
            const couponId = this.extractRedirectId(theme.clickAction);
            if (couponId && couponId !== 'null') {
              this.form.patchValue({
                redirectionType: this.bannerRedirectionTypesEnum.COUPON,
                redirectionId: couponId,
              });
              const selectedCoupon = (await this.couponService.get(couponId)).data;
              this.coupons.unshift(selectedCoupon);
            }
          } else {
            this.form.patchValue({
              redirectionType: this.bannerRedirectionTypesEnum.OTHERS,
              deepLinkUrl: theme.clickAction,
            });
          }
        }
      }
    }
    const eligibleParentZoneIds = await this.initialService.getElgibleParentZones();
    if (Array.isArray(eligibleParentZoneIds) && !eligibleParentZoneIds.includes('all')) {
      const responses = await this.zoneApiService.getAllEligibleParentZone();
      this.parentZonalDetails = responses.data.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    } else {
      const responses: Array<ParentZonalDetail> = [];
      responses.push(...(await this.zoneApiService.getAllParentZone()).data);
      this.parentZonalDetails = responses.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    }
    this.getParentParams();
  }

  // async fetchCategory(searchText?: string) { // need to revisit
  //   try {
  //     const query: any = {
  //       skip: this.skip,
  //       limit: defaultPaginationConfig.limit,
  //     };
  //     if (searchText || this.searchedCategory) {
  //       if (searchText) {
  //         this.skip = defaultPaginationConfig.skip;
  //         query.skip = this.skip;
  //       }
  //       query.searchText = searchText?.trim() || this.searchedCategory.trim();
  //     }
  //     const response = await this.specialCategoryApiService.getSpecialCategories(query);
  //     const newCategories: ISpecialCategoryConfig[] = response.data.specialCategories;

  //     if (newCategories.length) {
  //       this.skip += defaultPaginationConfig.limit;

  //       if (!searchText && !this.searchedCategory) {
  //         const uniqueNewCategories = newCategories.filter((newCategory) => !this.allCategory.some((existingCategory) => existingCategory.specialCategoryId === newCategory.specialCategoryId));

  //         this.allCategory = [...this.allCategory, ...uniqueNewCategories];

  //         const uniqueCategories: ISpecialCategoryConfig[] = this.allCategory.reduce<ISpecialCategoryConfig[]>((unique, category) => {
  //           const existingCategory = unique.find((c) => c.specialCategoryId === category.specialCategoryId);
  //           if (!existingCategory) {
  //             unique.push(category);
  //           }
  //           return unique;
  //         }, []);

  //         this.allCategory = uniqueCategories;
  //       } else {
  //         this.allCategory = newCategories;
  //       }
  //     }
  //   } catch (error) {
  //     this.allCategory = [];
  //   }
  // }

  searchSpecialCategory = (searchText: string) => {
    this.searchUpdate.next(searchText);
  };

  // onSearchCategory(searchText: string) { // meed to be optimized (search flow)
  //   if (!searchText.trim().length) {
  //     this.searchedCategory = '';
  //     this.skip = defaultPaginationConfig.skip;
  //     this.searchSpecialCategory('');
  //   } else {
  //     this.searchSpecialCategory(searchText);
  //   }
  // }

  // onClose() {
  //   this.allCategory = [];
  //   this.onSearchCategory('');
  // }

  async getParentParams() {
    const queryParamMap = await firstValueFrom(this.activatedRoute.queryParamMap);
    this.parentZoneId = queryParamMap.get('parentZoneId') || '';
    const filterParentZone = this.parentZonalDetails.find(
      (item: ParentZonalDetail) => (
        item.parentZoneId === this.parentZoneId
      ),
    );
    if (filterParentZone) {
      this.selectedParentZone = filterParentZone;
    } else if (this.parentZoneId === null) {
      this.selectedParentZone = null;
    } else {
      await this.router.navigate([], {
        queryParams: {
          parentZoneId: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
      this.selectedParentZone = null;
    }
  }

  private createForm() {
    this.form = new FormGroup({
      banner: new FormControl(''),
      bannerId: new FormControl(''),
      parentZoneId: new FormControl('', [Validators.required]),
      bannerStatus: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      effectiveFrom: new FormControl({ value: null, disabled: true }, [Validators.required]),
      expireAfter: new FormControl({ value: null, disabled: true }, [Validators.required]),
      description: new FormControl('', [Validators.maxLength(255)]),
      redirectionType: new FormControl(null),
      redirectionId: new FormControl(null),
      deepLinkUrl: new FormControl(''),
    });

    this.form.get('redirectionType')?.valueChanges.subscribe((value) => {
      const redirectionIdControl = this.form.get('redirectionId');
      const deepLinkUrlControl = this.form.get('deepLinkUrl');
      if (value) {
        redirectionIdControl?.setValidators(Validators.required);
        deepLinkUrlControl?.setValidators(Validators.required);

        if (value === 'others') {
          redirectionIdControl?.reset();
          deepLinkUrlControl?.enable();

          this.generatedDeepLink = '';
          deepLinkUrlControl?.setValue(this.generatedDeepLink);

          redirectionIdControl?.clearValidators();
        }

        redirectionIdControl?.setValue(null);
        deepLinkUrlControl?.setValue(null);
      } else {
        redirectionIdControl?.reset();
        redirectionIdControl?.clearValidators();
        deepLinkUrlControl?.clearValidators();

        this.generatedDeepLink = '';
        deepLinkUrlControl?.setValue(this.generatedDeepLink);
      }
      redirectionIdControl?.updateValueAndValidity();
      deepLinkUrlControl?.updateValueAndValidity();
      switch (value) {
        case 'special-category':
          this.skip = 0;
          this.fetchCategory();
          break;
        case 'kitchen':
          this.skip = 0;
          this.fetchKitchens();
          break;
        case 'coupon-description':
          this.skip = 0;
          this.fetchCoupons();
          break;
        default:
          console.log('Reidrection Type - Others');
      }
    });

    this.form.controls['redirectionId']?.valueChanges.subscribe((value) => {
      if (value) {
        const { redirectionType } = this.form.value;
        const redirectionId = value;
        this.searchText = '';

        this.generatedDeepLink = this.deepLinkGenerator(redirectionType, redirectionId);
        if (this.generatedDeepLink) {
          this.form.controls['deepLinkUrl'].setValue(this.generatedDeepLink);
          this.form.controls['deepLinkUrl'].disable();
        } else {
          this.form.controls['deepLinkUrl'].enable();
        }
      } else {
        this.generatedDeepLink = '';
        this.form.controls['deepLinkUrl'].enable();
      }
      this.form.get('specialCategoryTag')?.updateValueAndValidity();
      this.form.get('deepLinkUrl')?.updateValueAndValidity();
    });
  }

  onImageUpload(event: Event) {
    event.stopPropagation();
    const { files } = event.target as any;
    const fileList = generateFileHandles(files, this.sanitizer);
    this.onImageDrop(fileList);
    if (event.target) {
      (event.target as any).value = '';
    }
  }

  async onImageDrop(fileList: FileHandle[]) {
    if (fileList.length) {
      const file = fileList[0];
      const fileExtension = file.file.name.split('.').pop() || '';
      if (await this.validateFile(file.file)) {
        this.bannerUploadImage = null;
        this.bannerUploadImage = file;
        if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png' && fileExtension !== 'webp' && fileExtension !== 'gif') {
          this.toast.error('Please upload a valid image format(Supported - JPG, PNG, WEBP)');
          this.bannerUploadImage = null;
        } else if ((file.file.size / 1024) > 512) {
          this.bannerUploadImage = null;
          this.toast.error('File not uploaded! Upload less than or equal to 512kb!');
        }
      }
    }
  }

  validateFile(file: File) {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if ((width / height).toFixed(2) !== (360 / 314).toFixed(2)) {
          this.toast.error('Please upload an image with the specified dimensions. Supported Aspect ratio - 180:157');
          resolve(false);
          return;
        }
        resolve(true);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        return reject;
      };
    });
    img.src = objectUrl;
    return promise;
  }

  previewImage(event: Event, data: any) {
    event.stopPropagation();
    const uploadUrl: SafeUrl = data.url;
    this.modalConfig.data = uploadUrl;
    this.modalService.open(PreviewModalComponent);
  }

  onSelectStatus(event: any) {
    const status = event.target.value === 'true';
    this.bannerSelectedStatus = status;
  }

  async saveChange() {
    this.formSubmitted = true;
    if (this.form.valid && this.bannerUploadImage
      && this.bannerSelectedStatus !== undefined) {
      if (this.form.value?.description?.length > 255) {
        this.toast.warning('Description cannot be more than 255 characters!');
        this.formSubmitted = false;
        return;
      }
      if (this.selectedParentZone?.parentZoneId === undefined
        && this.selectedParentZone !== null) {
        this.toast.warning('Please select the Zone first!');
        this.formSubmitted = false;
        return;
      }
      const formValues = this.form.getRawValue();
      let fileName = '';
      let fileExtension = '';
      if (this.bannerUploadImage?.file) {
        fileExtension = this.bannerUploadImage?.file.name.split('.').pop() || '';
        fileName = `theme-banner-image-${uuid()}.${fileExtension}`;
        await this.s3Service.upload(this.bannerUploadImage.file, {
          fileName,
          contentType: this.bannerUploadImage.file.type,
        });
        if (this.selectedBannerUpdate) {
          // update-theme
          const updateBannerPayload: IThemeBanner = {
            bannerId: formValues.bannerId,
            bannerOriginalFileName: this.bannerUploadImage.file.name,
            bannerS3FileName: fileName,
            isActive: this.bannerSelectedStatus || false,
            bannerTitle: formValues.title,
            clickAction: formValues.deepLinkUrl ? formValues.deepLinkUrl : undefined,
            effectiveFrom: moment(formValues.effectiveFrom).tz(timezone).startOf('day').toISOString(),
            expireAfter: moment(formValues.expireAfter).tz(timezone).endOf('day').toISOString(),
            parentZoneId: formValues.parentZoneId,
            subZoneId: null,
            isAllParentZone: this.selectedBannerUpdate?.isAllParentZone,
            isAllSubZone: true,
            isPureVegCompatible: false,
            bannerFor: BannerUserView.CUSTOMER,
            bannerType: BannerTypes.THEME_BANNER,
            statusBarTextColor: {
              headerColorDefault: this.textColorConfigEnum.LIGHT_CONTENT,
              headerColorSticky: this.textColorConfigEnum.DARK_CONTENT,
            },
            statusBarContentColor: {
              headerColorDefault: this.bgColorConfigEnum.TRANSPARENT,
              headerColorSticky: this.bgColorConfigEnum.LIGHT_CONTENT,
            },
            headerOverLayColor: this.addressBarTextColorConfigEnum.LIGHT_CONTENT,
            sellerType: this.modalConfig.data.sellerType,
          };
          if (formValues.description !== '') {
            updateBannerPayload.description = formValues.description;
          }
          const configUpdatePromise = this.bannerService.updateThemeBanner(
            formValues.bannerId,
            updateBannerPayload,
          );
          from(configUpdatePromise).pipe(
            this.toast.observe({
              success: 'Banner updated successfully',
              loading: 'Updating Banner',
              error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
            }),
          ).subscribe(
            (res) => {
              if (res) {
                this.modalRef.dataEmit(res.data);
              }
            },
          );
          await configUpdatePromise;
          this.bannerUploadImage = null;
          this.selectedBannerUpdate = null;
          this.resetForm();
        } else {
          const createBannerPayload: IThemeBanner = {
            bannerOriginalFileName: this.bannerUploadImage?.file.name!,
            bannerS3FileName: fileName,
            isActive: this.bannerSelectedStatus || false,
            bannerTitle: formValues.title,
            effectiveFrom: moment(formValues.effectiveFrom).tz(timezone).startOf('day').toISOString(),
            expireAfter: moment(formValues.expireAfter).tz(timezone).endOf('day').toISOString(),
            parentZoneId: formValues.parentZoneId,
            subZoneId: null,
            isAllParentZone: false,
            isAllSubZone: true,
            isPureVegCompatible: false,
            bannerFor: BannerUserView.CUSTOMER,
            bannerType: BannerTypes.THEME_BANNER,
            statusBarTextColor: {
              headerColorDefault: this.textColorConfigEnum.LIGHT_CONTENT,
              headerColorSticky: this.textColorConfigEnum.DARK_CONTENT,
            },
            statusBarContentColor: {
              headerColorDefault: this.bgColorConfigEnum.TRANSPARENT,
              headerColorSticky: this.bgColorConfigEnum.LIGHT_CONTENT,
            },
            headerOverLayColor: this.addressBarTextColorConfigEnum.LIGHT_CONTENT,
            sellerType: this.modalConfig.data.sellerType,
          };
          if (formValues.description !== '') {
            createBannerPayload.description = formValues.description;
          }
          if (formValues.deepLinkUrl) {
            createBannerPayload.clickAction = formValues.deepLinkUrl;
          }
          const configUpdatePromise = this.bannerService
            .createThemeBanner('first', createBannerPayload);

          from(configUpdatePromise).pipe(
            this.toast.observe({
              success: 'Banner created successfully',
              loading: 'Creating Banner',
              error: (e) => {
                this.formSubmitted = false;
                return (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong';
              },
            }),
          ).subscribe(
            (res) => {
              if (res) {
                this.modalRef.dataEmit(res.data);
              }
            },
          );
          await configUpdatePromise;
          this.bannerUploadImage = null;
          this.selectedBannerUpdate = null;
          this.resetForm();
        }
      }
      this.modalRef.close();
    } else {
      this.toast.warning('Please enter valid input!');
    }
    setTimeout(() => {
      this.formSubmitted = false;
    }, 2000);
  }

  // isClearable() {
  //   const selectedValue = this.form.get('specialCategoryTag')?.value;
  //   return selectedValue !== '';
  // }

  removeUploadedFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.bannerUploadImage = null;
  }

  resetForm() {
    this.formSubmitted = false;
    this.form.reset();
    this.bannerUploadImage = null;
    this.selectedBannerUpdate = null;
    this.bannerSelectedStatus = null;
  }

  onSearchCategory(searchText: string) {
    this.searchUpdate.next(searchText?.trim());
  }

  onSearchKitchen(searchText: string) {
    this.kitchenSearchUpdate.next(searchText?.trim());
  }

  onSearchCoupon(searchText: string) {
    this.couponSearchUpdate.next(searchText?.trim());
  }

  async fetchCoupons(searchText?: string) {
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }
      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
      };

      if (searchText) {
        query.couponCode = searchText?.trim();
      }

      const { coupons } = (await this.couponService.getAllCoupons(
        this.skip,
        defaultPaginationConfig.limit,
        searchText?.trim(),
      )).data;

      if (coupons.length) {
        this.skip += defaultPaginationConfig.limit;
        if (searchText) {
          this.coupons = coupons;
        } else {
          this.coupons.push(...coupons);
          this.coupons = this.coupons.reduce((unique: ICoupon[], coupon) => {
            const existingCoupon = unique.find((c) => c.couponId === coupon.couponId);
            if (!existingCoupon) {
              unique.push(coupon);
            }
            return unique;
          }, []);
        }
      }
    } catch (error) {
      this.coupons = [];
    }
  }

  async fetchKitchens(searchText?: string) {
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }
      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
        status: ['active'],
        parentZoneId: this.form.getRawValue().parentZoneId,
      };
      if (searchText) {
        query.searchFor = 'kitchen';
        query.search = searchText?.trim();
      }

      const kitchens = (await this.cookApiService.getAllSellers(query)).data.kitchenProfiles;

      if (kitchens.length) {
        this.skip += defaultPaginationConfig.limit;

        if (searchText) {
          this.kitchens = kitchens;
        } else {
          this.kitchens.push(...kitchens);
          this.kitchens = this.kitchens.reduce((unique: ISeller[], kitchen) => {
            const existingKitchen = unique.find((c) => c.kitchenId === kitchen.kitchenId);
            if (!existingKitchen) {
              unique.push(kitchen);
            }
            return unique;
          }, []);
        }
      }
    } catch (error) {
      this.kitchens = [];
    }
  }

  async fetchCategory(searchText?: string) { // need to revisit
    try {
      if (!searchText && this.searchText) {
        searchText = this.searchText;
      }

      const query: any = {
        skip: this.skip,
        limit: defaultPaginationConfig.limit,
        parentZoneId: this.form.getRawValue().parentZoneId,
        isIncludeCommonToAllZones: true,
      };

      if (searchText) {
        query.searchText = searchText;
      }

      const newCategories = (await this.specialCategoryApiService.getSpecialCategories(query))
        .data.specialCategories;

      if (newCategories.length) {
        this.skip += defaultPaginationConfig.limit;

        if (searchText) {
          this.allCategory = newCategories;
        } else {
          this.allCategory.push(...newCategories);
          this.allCategory = this.allCategory
            .reduce<ISpecialCategoryConfig[]>((unique, category) => {
              const existingCategory = unique.find(
                (c) => c.specialCategoryId === category.specialCategoryId,
              );
              if (!existingCategory) {
                unique.push(category);
              }
              return unique;
            }, []);
        }
      }
    } catch (error) {
      this.allCategory = [];
    }
  }

  deepLinkGenerator(redirectionType: string = '', redirectionId: string = '') {
    const baseUrl = 'https://cookr.in';
    let generatedDeepLink = '';
    if (redirectionType && redirectionId) {
      generatedDeepLink = `${baseUrl}?redirect=${redirectionType}&redirectId=${redirectionId}`;
    }
    return generatedDeepLink;
  }

  onClose() {
    this.searchText = '';
    this.skip = 0;
    if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.SPECIAL_CATEGORY) {
      this.onSearchCategory(this.searchText);
    } else if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.KITCHEN) {
      this.onSearchKitchen(this.searchText);
    } else if (this.form.value.redirectionType === this.bannerRedirectionTypesEnum.COUPON) {
      this.onSearchCoupon(this.searchText);
    }
  }

  onParentZoneSelect() {
    this.form.controls['redirectionId'].setValue(null);
    this.skip = defaultPaginationConfig.skip;
    this.allCategory = [];
    this.kitchens = [];
    this.fetchCategory();
    this.fetchKitchens();
  }
}
