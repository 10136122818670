/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
export function deleteVoidProperties(object: any) {
  for (const i in object) {
    if (object[i] === null || object[i] === '') {
      delete object[i];
    } else if (typeof object[i] === 'object') {
      deleteVoidProperties(object[i]);
    }
  }
}
const extensionToMimeTypeMapping = {
  img: 'image/jpg',
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  jfif: 'image/jpeg',
  pdf: 'application/pdf',
};

export function getMimeTypeFromFileName(filename: string): string | null {
  if (filename) {
    const extension = filename?.split('.').pop();
    if (extension) {
      if (extension in extensionToMimeTypeMapping) {
        return (extensionToMimeTypeMapping as any)[extension];
      }
    }
  }
  return null;
}

export function getExtensionCheckTypeFromFileType(fileType: string): boolean {
  if (fileType === 'application/pdf') {
    return false;
  }
  return true;
}

export function generateBase64FromFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// mapping order statuses to show in the front end
export function constructOrderStatusName(key: string): string {
  let orderStatus;
  switch (key) {
    case 'orderPlaced':
      orderStatus = 'Order Placed';
      break;
    case 'orderConfirmed':
      orderStatus = 'Order Confirmed';
      break;
    case 'cookAcknowledged':
      orderStatus = 'Cook Acknowledged';
      break;
    case 'chefOrderCreated':
      orderStatus = 'Chef Order Created';
      break;
    case 'orderPreparing':
      orderStatus = 'Order Preparing';
      break;
    case 'deAssigning':
      orderStatus = 'Searching For Rider';
      break;
    case 'deAssigned':
      orderStatus = 'DE Assigned';
      break;
    case 'orderPickedUp':
      orderStatus = 'Order PickedUp';
      break;
    case 'deReached':
      orderStatus = 'DE Reached';
      break;
    case 'orderDelivered':
      orderStatus = 'Order Delivered';
      break;
    case 'orderUndelivered':
      orderStatus = 'Order Undelivered';
      break;
    case 'partiallyFulfilled':
      orderStatus = 'Order Undelivered';
      break;
    case 'orderCancelled':
      orderStatus = 'Order Cancelled';
      break;
    case 'orderFailed':
      orderStatus = 'Order Failed';
      break;
    case 'readyForPickUp':
      orderStatus = 'Requested For Rider';
      break;
    case 'orderCompleted':
      orderStatus = 'Order Completed';
      break;
    case 'orderInComplete':
      orderStatus = 'Order InComplete';
      break;
    case 'firstMileReached':
      orderStatus = 'First Mile Reached';
      break;
    case 'lastMileReached':
      orderStatus = 'Last Mile Reached';
      break;
    case 'refundInitiated':
      orderStatus = 'Refund Initiated';
      break;
    case 'refundFailed':
      orderStatus = 'Refund Failed';
      break;
    case 'refundProcessed':
      orderStatus = 'Refund Processed';
      break;
    case 'reAssigned':
      orderStatus = 'Re-Assigned';
      break;
    case 'noRiderAvailable':
      orderStatus = 'No Rider Available';
      break;
    case 'foodReady':
      orderStatus = 'Food Ready';
      break;
    default:
      orderStatus = '';
  }
  return orderStatus;
}
