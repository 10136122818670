<div class="modal-dialog modal-dialog-centered preview" role="document">
  <div class="modal-content">
    <div class="modal-header border-0 d-flex justify-content-between p-3">
      <h5 class="modal-title">Preview</h5>
      <div class="right-section">
        <img src="assets/icon-order-status-close.svg" class="cursor-pointer" alt="icon-close" (click)="modalClose()" />
      </div>
    </div>
    <div class="modal-body d-flex align-items-center justify-content-center">
      <img [src]="previewImage" width="100%" height="100%">
    </div>
  </div>
</div>