export enum BannerUserView {
  CUSTOMER = 'customer',
  ADMIN = 'admin',
  COOK = 'cook',
  RIDER = 'rider',
}

export enum BannerTypes {
  THEME_BANNER = 'theme',
  SCROLLABLE_BANNER = 'scrollable',
}
