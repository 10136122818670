import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService, ServiceType } from '../api.service';
import { HttpClientService } from '../http-client.service';

interface IGetQuery {
  skip: number,
  limit: number,
  parentZoneId?: string,
  search?: string,
}

interface IContactInfo {
  contactPersonName: string,
  contactPersonPhoneNumber: string
}

interface ILocation {
  type: string,
  coordinates: Array<number>
}

export interface ISatelliteStoreConfig {
  satelliteStoreId?: string,
  address: string,
  contactInfo: IContactInfo,
  location: ILocation,
  parentZoneId: string,
  storeName: string,
  subZoneId: string,
  parentZoneName?: string,
  subZoneName?: string,
  isActive?: boolean;
}

export interface ISatelliteStoresResponse {
  data: {
    satelliteStores: ISatelliteStoreConfig[],
  },
  totolCount: number,
  totalPages: number
}

@Injectable()
export class SatelliteStoreConfigService extends RestApiService {
  private adminOperationServiceURL = environment.config['ADMIN_SERVICE_URL'];

  private satelliteStoreConfigSerivceURL = `${this.adminOperationServiceURL}/satellite-stores`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllSatelliteStores(queryParams: IGetQuery): Promise<ISatelliteStoresResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if ('limit' in queryParams) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if ('parentZoneId' in queryParams) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      }
    }

    const apiPath = `${this.satelliteStoreConfigSerivceURL}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSatelliteStoreById(satelliteStoreId: string): Promise<{ data: ISatelliteStoreConfig }> {
    const apiPath = `${this.satelliteStoreConfigSerivceURL}/${satelliteStoreId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async createSatelliteStore(
    payload: ISatelliteStoreConfig,
  ): Promise<{ data: ISatelliteStoreConfig }> {
    const apiPath = `${this.satelliteStoreConfigSerivceURL}`;
    return this.httpPost(apiPath, payload);
  }

  async updateSatelliteStore(
    satelliteStoreId: string,
    payload: ISatelliteStoreConfig,
  ): Promise<{ data: ISatelliteStoreConfig }> {
    const apiPath = `${this.satelliteStoreConfigSerivceURL}/${satelliteStoreId}`;
    return this.httpPatch(apiPath, payload);
  }
}
