/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ModalRef {
  private readonly _close = new Subject<any>();

  private readonly _data = new Subject<any>();

  closeEmitter: Observable<any> = this._close.asObservable();

  dataEmitter: Observable<any> = this._data.asObservable();

  close(result?: any) {
    this._close.next(result);
  }

  dataEmit(result?: any) {
    this._data.next(result);
  }
}
