// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  config: {
    CONTROL_TOWER_URI: 'https://controltower.dev.cookr.in/',
    REPORT_DASHBOARD_URI: 'https://reports.dev.cookr.in/',
    SELLER_PIPELINE_URI: 'https://sellerpipeline.dev.cookr.in/',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCUlqQY-y4HE9uQ6QYNlz-A7ovI7drPgJE',

    PAYMENT_SERVICE_URI: 'https://dev.cookr.in/api/payment-service/v2',
    COOK_SERVICE_URI: 'https://dev.cookr.in/api/cook-service/v2',
    CUSTOMER_SERVICE_URL: 'https://dev.cookr.in/api/customer-service/v2',
    KITCHEN_SERVICE_URL: 'https://dev.cookr.in/api/kitchen-service/v2',
    AUTH_SERVICE_URL: 'https://dev.cookr.in/api/auth-service/v2',
    LOGISTIC_SERVICE_URL: 'https://dev.cookr.in/api/logistic-service/v2',
    CONFIG_SERVICE_URL: 'https://dev.cookr.in/api/config-service/v2',
    REVIEW_SERVICE: 'https://dev.cookr.in/api/review-service/v2',
    ADMIN_SERVICE_URL: 'https://dev.cookr.in/api/admin-operations-service/v2',
    ORDER_SERVICE_URL: 'https://dev.cookr.in/api/order-service/v2',
    SELLER_PIPELINE_SERVICE_URL: 'https://dev.cookr.in/api/seller-pipeline-service/v2',
    SUBSCRIPTION_SERVICE_URL: 'https://dev.cookr.in/api/subscription-service/v1',
    azureAd: {
      CLIENT_ID: 'c798b78d-825c-4f56-83aa-930652c8ab0e',
      AUTHORITY: 'https://login.microsoftonline.com/40a4ede4-f920-4fc1-968a-d08f490515be',
      REDIRECT_URI: '/',
      POST_LOGOUT_REDIRECT_URI: '/',
      API_SCOPE: 'api://2bf21aca-2837-404c-899b-02e1a490f8dd/api.scopes',
      USER_PROFILE_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
      ALL_USERS_PROFILE_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/users',
      REPTOREES_PROFILE_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me/directReports',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
