import {
  ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { ISidebarItem, ISubTabItems } from '../../../constants/sidebar.constants';
import { TabTypes } from '../../../enums/tab.types';
import { SidebarService } from './sidebar.service';
import { AppAbilityKey } from '../../../enums/ability-keys.enum';
import { AppAbility } from '../../../services/appAbility';
import { InitialService } from '../../../services/initial.service';
import { MenuApprovalApiService } from '../../../services/api/menu-approval-api.service';
import { SideBarSharedService } from '../../../services/api/sidebar-shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SidebarComponent implements OnInit {
  public sidebarItems: ISidebarItem[] = [];

  public basePath = '/dashboard/cook-admin/';

  private accessibleSidebarTabs: Map<string, ISidebarItem> = new Map();

  public menuApprovalCount: number = 0;

  @Input()
  set currentAdminView(currentTab: TabTypes) {
    this.fetchAssociatedItems(currentTab);
  }

  constructor(
    private readonly router: Router,
    private readonly sidebarService: SidebarService,
    public appAbility: AppAbility,
    private initialService: InitialService,
    private menuApprovalApiService: MenuApprovalApiService,
    private sideBarSharedService: SideBarSharedService,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
    this.getMenuApprovalCount();
  }

  ngOnInit() {
    this.sideBarSharedService.data$.subscribe((data) => {
      if (data) {
        this.getMenuApprovalCount();
      }
    });
  }

  async getMenuApprovalCount() {
    const promise = this.menuApprovalApiService.menuApprovalCount();
    from(promise).pipe().subscribe();
    const res = await promise;
    if (res.data) {
      this.menuApprovalCount = res.data.totalCount;
    } else {
      this.menuApprovalCount = 0;
    }
  }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  private async fetchAssociatedItems(currentTab: TabTypes) {
    if (currentTab === TabTypes.COOK_ADMIN) {
      this.basePath = '/dashboard/cook-admin/';
    } else if (currentTab === TabTypes.DRIVER_ADMIN) {
      this.basePath = '/dashboard/driver-admin/';
    } else if (currentTab === TabTypes.CUSTOMER_ADMIN) {
      this.basePath = '/dashboard/customer-admin/';
    }
    const sidebar = this.sidebarService.getSidebarItems(currentTab);
    this.sidebarItems = sidebar.filter((items) => {
      if (Array.isArray(items.abilityKey)) {
        const abilities = items.abilityKey.some((abilityKey) => this.appAbility.can('view', abilityKey));
        if (abilities) {
          if (items.route) {
            this.accessibleSidebarTabs.set(items.route, items);
          }
          return items;
        }
      } else if (this.appAbility.can('view', items.abilityKey)) {
        if (items.route) {
          this.accessibleSidebarTabs.set(items?.route, items);
        }
        return items;
      }
      return null;
    });
  }

  public navigate(path: string = ''): void {
    this.getMenuApprovalCount();
    const { basePath } = this;
    const routePathApartFromChild = ['cook-kitchen-onboarding', 'cook-profile'];
    if (!routePathApartFromChild.includes(path)) {
      if (this.accessibleSidebarTabs.has(path)) {
        const subTabs: ISubTabItems[] | undefined = this.accessibleSidebarTabs.get(path)?.children;
        if (subTabs && Array.isArray(subTabs) && subTabs?.length) {
          for (let i = 0; i < subTabs.length; i += 1) {
            const subTabRoute = subTabs[i].route;
            if (subTabs[i].abilityKey) {
              if (this.appAbility.can('view', subTabs[i].abilityKey)) {
                this.router.navigate([`${basePath}${path}/${subTabRoute}`]);
                break;
              }
            } else {
              this.router.navigate([`${basePath}${path}/${subTabRoute}`]);
              break;
            }
          }
        } else {
          this.router.navigate([`${basePath}${path}`]);
        }
      } else {
        this.router.navigate([`${basePath}${path}`]);
      }
    } else {
      this.router.navigate([`${basePath}${path}`]);
    }
  }
}
