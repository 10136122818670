<div mat-dialog-content>
  <form [formGroup]="form" [ngClass]="{'submitted':formSubmitted}">
  <div class="title formInput">
    <label class="labelFont required mb-1" for="discountType">Select Sequence</label><br>
    <div class="form-check form-check-inline formInputTitle" *ngFor="let item of queryGroup.items">
      <input class="form-check-input mt-0" name="selectQuery" type="radio" [value]="item.value"
        formControlName="selectQuery">
      <label class="form-check-label">{{item.label}}</label>
    </div>
  </div>
</form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial>Submit</button>
</div>