import { Injectable } from '@angular/core';
import { RestApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { HttpClientService } from '../http-client.service';
import { ICreatedOrUpdatedBy } from './pre-booking-api.service';

export enum EntityType {
  KITCHEN = 'kitchen'
}

export interface IReviewTrackingPayload {
  addressedBy: ICreatedOrUpdatedBy;
  issueType: string;
  customerResponse: string;
  cookResponse: string;
  amountToBeDeducted: number;
  action: string;
  remarks: string;
}

export interface IReviewDetails {
  simplifiedOrderId: string;
  customerName: string;
  customerMobileNumber: string;
  parentZone: string;
  kitchenName: string;
  rating: number;
  ratingId: string;
  review: string;
  createdAt: string;
  mealTime: string;
  hasReviewTracking: boolean;
}

export interface IReviewsMetrics {
  totalReviewsCount: number;
  totalPages: number;
}

export interface IAllReviewsResponse {
  reviewDetails: Array<IReviewDetails>;
  metrics: IReviewsMetrics;
}

export interface IReviewTrackingDetails {
  customerName: string;
  customerMobileNumber: string;
  simplifiedOrderId: string;
  orderedDate: string;
  parentZone: string;
  mealTime: string;
  kitchenName: string;
  rating: number;
  feedback: string;
  issueType: string;
  customerResponse: string;
  addressedBy: ICreatedOrUpdatedBy;
  cookResponse: string;
  amountDeducted: number;
  action: string;
  remarks: string;
  updatedAt: string;
}

export interface IReviewsTrackingDetails {
  _id: string;
  ratingId: string;
  reviewCreatedAt: string;
  mealTime: string;
  addressedBy: ICreatedOrUpdatedBy;
  issueType: string;
  customerResponse: string;
  cookResponse: string;
  amountToBeDeducted: number;
  action: string;
  remarks: string;
  createdBy: ICreatedOrUpdatedBy;
  updatedBy: ICreatedOrUpdatedBy;
  createdAt: string;
  updatedAt: string;
  __v: number;
  simplifiedOrderId: string;
  customerName: string;
  customerMobileNumber: string;
  parentZone: string;
  kitchenName: string;
  rating: number;
  review: string;
}

export interface IReviewsTrackingMetrics {
  totalReviewTrackingDetailsCount: number;
  totalPages: number;
}

export interface IAllReviewsTrackingResponse {
  reviewTrackingDetails: Array<IReviewsTrackingDetails>;
  metrics: IReviewsTrackingMetrics;
}

@Injectable()
export class ReviewsTrackingService extends RestApiService {

  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.adminApiUrl}/ratings`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  // async createReviewTracking(ratingId: string, payload: IReviewTrackingPayload) {
  //   const apiPath = `${this.servicePath}/review-tracking/${ratingId}`;
  //   return this.httpPost(apiPath, payload);
  // }

  async updateReviewTracking(ratingId: string, payload: IReviewTrackingPayload) {
    const apiPath = `${this.servicePath}/review-tracking/${ratingId}`;
    return this.httpPut(apiPath, payload);
  }

  async getReviewsKitchenList(queryParams: Partial<any>)
    : Promise<{ data: IAllReviewsResponse }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['entity']) {
        searchParams.set('entity', String(queryParams['entity']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/kitchen-reviews/list/?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getReviewsTrackingDetails(ratingId: string)
    : Promise<{ data: IReviewTrackingDetails }> {
    const apiPath = `${this.servicePath}/reviews-details/${ratingId}`;
    return this.httpGet(apiPath);
  }

  async getReviewsTrackingList(queryParams: Partial<any>)
    : Promise<{ data: IAllReviewsTrackingResponse }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (queryParams['issueType'] !== undefined) {
        if (Array.isArray(queryParams['issueType'])) {
          queryParams['issueType'].forEach((item) => {
            searchParams.append('issueType', item);
          });
        }
      }
      if (queryParams['action'] !== undefined) {
        if (Array.isArray(queryParams['action'])) {
          queryParams['action'].forEach((item) => {
            searchParams.append('action', item);
          });
        }
      }
      if (queryParams['rating'] !== undefined) {
        if (Array.isArray(queryParams['rating'])) {
          queryParams['rating'].forEach((item) => {
            searchParams.append('rating', item);
          });
        }
      }
      if (queryParams['entity']) {
        searchParams.set('entity', String(queryParams['entity']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}/review-tracking-details/list/?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }
}
