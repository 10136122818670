import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { ISubCategoryResponse } from './sub-category.service';

export interface IEntityTpe {
  entityId: string;
  entityType: string;
  entityName: string;
}

export interface ICreateOrUpdateCategoryPayload {
  categoryName: string;
  categoryS3FileName: string;
  categoryOriginalFileName: string;
  categoryDescription: string;
  isActive: boolean;
}

export interface ICategoryResponse {
  categoryId: string;
  categoryName: string;
  categoryDescription: string;
  categoryS3FileName: string;
  categoryOriginalFileName: string;
  createdBy: IEntityTpe;
  isActive: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: IEntityTpe;
}

export interface IFlavour {
  flavourId: string,
  levels: Array<string>
}

export interface ISubCategory {
  subCategoryName: string,
  subCategoryS3FileName?: string,
  subCategoryOriginalFileName?: string,
  subCategoryDescription: string,
  categoryId: string,
  flavours?: Array<IFlavour>,
  isActive: boolean
}

export interface ICategoryDetailsResponse {
  categoryId: string;
  categoryName: string;
  categoryDescription: string;
  categoryS3FileName: string;
  categoryOriginalFileName: string;
  createdBy: IEntityTpe;
  isActive: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: IEntityTpe;
  subCategories: Array<ISubCategoryResponse>;
  categoryImageUrl: string;
}
export interface ICategoryDetails {
  categoryId: string;
  categoryName: string;
  categoryDescription?: string;
  categoryS3FileName?: string;
  categoryOriginalFileName?: string;
  createdBy?: IEntityTpe;
  isActive?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: IEntityTpe;
  subCategories?: Array<ISubCategoryResponse>;
  categoryImageUrl?: string;
}

export interface IAllCategoryResponse {
  categories: Array<ICategoryDetailsResponse>;
  catagoriesCount: number;
}

@Injectable()
export class CategoryService extends RestApiService {
  protected kitchenApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // private servicePath = `${this.kitchenApiUrl}/common/products-category`;

  private servicePath = `${this.adminApiUrl}/products-category`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async createCategory(addTo: string, payload: ICreateOrUpdateCategoryPayload)
    : Promise<{ data: ICategoryResponse }> {
    const apiPath = `${this.servicePath}?addTo=${addTo}`;
    return this.httpPost(apiPath, payload);
  }

  async updateCategory(categoryId: string, payload: ICreateOrUpdateCategoryPayload)
    : Promise<{ data: ICategoryResponse }> {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpPatch(apiPath, payload);
  }

  async getCategoryById(categoryId: string)
    : Promise<{ data: ICategoryDetailsResponse }> {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpGet(apiPath);
  }

  async getAllCategories(queryParams: Partial<any>)
    : Promise<{ data: IAllCategoryResponse }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async deleteCategory(categoryId: string) {
    const apiPath = `${this.servicePath}/${categoryId}`;
    return this.httpDelete(apiPath);
  }

  async getProductCategorySequence(queryParams?: Record<string, any>)
    : Promise<{ data: ICategoryDetailsResponse[] }> {
    const searchParams = new URLSearchParams({
    });
    if (queryParams?.['parentZoneId']) {
      searchParams.set('parentZoneId', queryParams['parentZoneId']);
    }
    const apiPath = `${this.servicePath}/sequence/list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateProductCategorySequence(
    parentZoneId: string,
    payload: { categoryIds: (string | undefined)[] },
  ): Promise<ICategoryDetailsResponse[]> {
    const apiPath = `${this.servicePath}/modify/sequence?parentZoneId=${parentZoneId}`;
    return this.httpPut(apiPath, payload);
  }
}
