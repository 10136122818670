import {
  Directive, ElementRef, Input, Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { matchPath } from '../../helpers/path-match.helpers';

const defaultClassName = 'active';

@Directive({
  selector: '[activeLink]',
})
export class ActiveLinkDirective {
  @Input()
    activeLink?: string | string[];

  @Input()
    activeClassName: string | string[] = defaultClassName;

  @Input()
    inactiveClassName?: string;

  private unsubscribeRoute$ = new Subject<void>();

  constructor(
    public element: ElementRef,
    public renderer: Renderer2,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.init();
    this.router.events.pipe(
      takeUntil(this.unsubscribeRoute$),
      filter((event) => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.init();
    });
  }

  private init() {
    let match = false;
    if (Array.isArray(this.activeLink)) {
      match = this.activeLink.some((link) => this.checkPathMatch(link));
    } else if (this.activeLink) {
      match = this.checkPathMatch();
    }
    this.alterActiveClass(match);
  }

  private checkPathMatch(link?: string): boolean {
    const pathToMatch = link || this.activeLink as string;
    const matching = !!matchPath(pathToMatch, window.location.pathname);
    return matching;
  }

  private alterActiveClass(apply: boolean) {
    if (Array.isArray(this.activeClassName)) {
      this.activeClassName.forEach((className: string) => {
        this.applyOrRemoveClass(apply, className);
      });
    } else {
      this.applyOrRemoveClass(apply, this.activeClassName);
    }
  }

  private applyOrRemoveClass(apply: boolean, className: string) {
    if (apply) {
      this.renderer.addClass(this.element.nativeElement, className);
      if (this.inactiveClassName) {
        this.renderer.removeClass(this.element.nativeElement, this.inactiveClassName);
      }
    } else {
      this.renderer.removeClass(this.element.nativeElement, className);
      if (this.inactiveClassName) {
        this.renderer.addClass(this.element.nativeElement, this.inactiveClassName);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeRoute$.next();
    this.unsubscribeRoute$.complete();
  }
}
