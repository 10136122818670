import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface IRiderProfile {
  availableStatus: string;
  cognito_username: string;
  deviceToken: string;
  emailId: string;
  name: string;
  phone_number: string;
  phone_number_verified: boolean;
  updatedAt: string;
  userId: string;
  __v: number;
  _id: string;
}

export interface IAbility {
  subjectValue: string;
  actions: Array<string>;
}

export interface IAttributes {
  attributeName: string;
  attributeValue: string | Array<string>;
}

export interface ILastUpdatedBy {
  entityId: string;
  entityName: string;
  entityType: string;
}
export interface IAdminUser {
  formatedUserName?: string;
  userId?: string;
  managerId: string;
  isActive: boolean;
  userName: string;
  userMailId: string;
  abilities: IAbility[];
  attributes: IAttributes[];
  lastUpdatedBy?: ILastUpdatedBy;
  updatedAt?: string;
  azureStatus?: boolean;
  designation?: string;
}
export interface IAllUser {
  adminUsers: Array<IAdminUser>;
  totalAdminUsers: number;
  totalPages: number;
}

export interface IRole {
  roleId?: string;
  roleName: string;
  appName: string;
  isCustomRole: boolean;
  abilities: IAbility[];
}

export interface IDisableUserPayload {
  reason: string;
}

export interface IUserInfo {
  _id?: string;
  favKitchens: Array<string>;
  deviceToken: string;
  phoneNumber: string;
  phone_number_verified: boolean;
  cognito_username: string;
  name: string;
  dob?: string;
  gender?: string,
  email?: string,
  customerId: string;
  isTermsConditionsAccepted?: boolean;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  isPureVeg: boolean;
  disableReason?: string;
  disabledOn?: Date;
  disableStatusUpdatedBy?: ILastUpdatedBy;
  paymentModePreferences: {
    cod: boolean;
    online: boolean;
  };
  coinBalance: {
    _id: string;
    customerId: string;
    unSettledCoins: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    availableCoins: number;
  };
}

export interface IUsersInfoList {
  // totalUsers: number,
  // totalPages: number,
  profilesCount: number,
  customerProfiles: Array<IUserInfo>,
}

export interface IPaymentModePreferences {
  cod: boolean;
  online: boolean;
}

export interface IUpdateUserInfo {
  customerId: string,
  name: string,
  email?: string,
  dob?: string,
  isPureVeg: boolean,
  gender?: string,
  paymentModePreferences: IPaymentModePreferences;
}

export interface IUserOTPReset {
  userId: string;
}

export interface IUserMetrics {
  maleCustomers: number,
  femaleCustomers: number,
  nonBinaryCustomers: number,
  preferNotToSayCustomers: number,
  others?: number,
  totalActiveCustomers: number,
  belowEighteenCustomers: number,
  eighteenToThirtyCustomers: number,
  thirtyToFourtyFiveCustomers: number,
  fourtyFiveToSixtyCustomers: number,
  aboveSixtyCustomers: number,
  androidDevices: number,
  iosDevices: number,
  totalPureVegCustomers: number,
}
export interface IPagination {
  skip: number,
  limit: number,
}

export interface IAdminUsersQuery {
  skip: number;
  limit: number;
  status?: string;
  designation?: string;
  search?: string
}

@Injectable()
export class UserApiService extends RestApiService {
  private servicePath = environment.config['AUTH_SERVICE_URL'];

  // TODO: need to remove this endpoint
  protected apiUrl = environment.config['ADMIN_SERVICE_URL'];

  protected adminBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private adminUserServicePath = `${this.adminBaseApiUrl}/admin-users`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getUserMetrics(): Promise<IUserMetrics> {
    const apiPath = `${this.apiUrl}/users/management-metrics`;
    return this.httpGet(apiPath);
  }

  async get(id: string) {
    const apiPath = `${this.servicePath}/users/profile/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateUserProfile(payload: IUpdateUserInfo) {
    const apiPath = `${this.adminBaseApiUrl}/users/profile`;
    return this.httpPut(apiPath, payload);
  }

  async userOTPRestrictionReset(payload: IUserOTPReset) {
    const apiPath = `${this.adminBaseApiUrl}/users/reset/otp-restriction`;
    return this.httpPatch(apiPath, payload);
  }

  async fetchUserAddress(addressId: string) {
    const apiPath = `${this.adminBaseApiUrl}/users/user-address/${addressId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getRider(id: string) {
    const apiPath = `${this.servicePath}/riders/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll() {
    const apiPath = this.servicePath;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllUserInfo(skip: number, limit: number, phoneNumber?: string, status?: string)
    : Promise<{ data: IUsersInfoList; }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (phoneNumber && phoneNumber !== undefined) {
      searchParams.set('phoneNumber', phoneNumber);
    }
    if (status && status !== undefined && status !== 'all') {
      searchParams.set('status', status);
    }
    const apiPath = `${this.adminBaseApiUrl}/users?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: object) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: object) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpPut(apiPath, payload);
  }

  async disableUser(userId: string, payload: IDisableUserPayload): Promise<IUserInfo> {
    const apiPath = `${this.adminBaseApiUrl}/users/disable-account/${userId}`;
    return this.httpPatch(apiPath, payload);
  }

  async enableUser(userId: string): Promise<IUserInfo> {
    const apiPath = `${this.adminBaseApiUrl}/users/enable-account/${userId}`;
    return this.httpPatch(apiPath);
  }

  async updatePaymentMode(userId: string, payload: IPaymentModePreferences) {
    const apiPath = `${this.servicePath}/users/payment-preference/${userId}`;
    return this.httpPatch(apiPath, payload);
  }

  // Admin User API's
  async updateOrCreateAdminUser(payload: Partial<IAdminUser>) {
    const apiPath = `${this.adminUserServicePath}`;
    return this.httpPut(apiPath, payload);
  }

  async getAllAdminUser(): Promise<IAdminUser[]> {
    const apiPath = `${this.adminUserServicePath}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllReporteesOfAdminUser(adminUserId: string): Promise<{ data: IAdminUser[]; }> {
    const apiPath = `${this.adminUserServicePath}/${adminUserId}/reportees`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async fetchUserByEmailChar(paginationConfig: {
    skip: number,
    limit: number,
  }, email: string) {
    const searchParams = new URLSearchParams();
    if (paginationConfig.skip !== undefined) {
      searchParams.set('skip', String(paginationConfig.skip));
    }
    if (paginationConfig.limit !== undefined) {
      searchParams.set('limit', String(paginationConfig.limit));
    }
    if (email) {
      searchParams.set('search', email);
    }
    const apiPath = `${this.adminUserServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
      sendAzureToken: true,
    });
  }

  async fetchUserByEmail(email: string): Promise<{ data: IAdminUser; }> {
    const searchParams = new URLSearchParams();
    if (email) {
      searchParams.set('email', email);
    }
    const apiPath = `${this.adminUserServicePath}/user-by-email/?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
      sendAzureToken: true,
    });
  }

  async getAdminUserById(adminUserId: string): Promise<{ data: IAdminUser; }> {
    const apiPath = `${this.adminUserServicePath}/fetch-user/${adminUserId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async patchAdminUserStatus(adminUserId: string, isActive: boolean) {
    const apiPath = `${this.adminUserServicePath}/edit-status/${adminUserId}?${isActive}`;
    return this.httpPatch(apiPath);
  }

  async deleteAdminUser(adminUserId: string) {
    const apiPath = `${this.adminUserServicePath}/remove-user/${adminUserId}`;
    return this.httpDelete(apiPath);
  }

  // Admin User's Role API's
  async createRole(payload: IRole) {
    const apiPath = `${this.adminUserServicePath}/role`;
    return this.httpPost(apiPath, payload);
  }

  async getAllRole(): Promise<{ data: IRole[]; }> {
    const apiPath = `${this.adminUserServicePath}/role`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getRoleById(roleId: string): Promise<IRole> {
    const apiPath = `${this.adminUserServicePath}/role/${roleId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateRole(roleId: string, payload: IRole) {
    const apiPath = `${this.adminUserServicePath}/role/${roleId}`;
    return this.httpPut(apiPath, payload);
  }

  async addAbilityToRole(roleId: string, payload: IAbility) {
    const apiPath = `${this.adminUserServicePath}/role/${roleId}/ability`;
    return this.httpPut(apiPath, payload);
  }

  async deleteRole(roleId: string) {
    const apiPath = `${this.adminUserServicePath}/role/${roleId}`;
    return this.httpDelete(apiPath);
  }

  async getAllReportees(queryParams: IAdminUsersQuery): Promise<{ data: IAllUser; }> {
    const {
      skip, limit, status, designation, search,
    } = queryParams;
    const searchParams = new URLSearchParams();
    if (typeof skip === 'number' && !Number.isNaN(skip)) {
      searchParams.set('skip', String(skip));
    }
    if (typeof limit === 'number' && !Number.isNaN(limit)) {
      searchParams.set('limit', String(limit));
    }
    if (status) {
      const userStatus = status === 'active';
      searchParams.set('isActive', String(userStatus));
    }
    if (designation) {
      searchParams.set('designation', String(designation));
    }
    if (search) {
      searchParams.set('search', String(search));
    }
    const apiPath = `${this.adminUserServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
      sendAzureToken: true,
    });
  }
}
