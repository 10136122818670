import { Component, OnInit } from '@angular/core';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ModalRef } from '../../../../lib/modal/modal-ref';

@Component({
  selector: 'app-video-preview-modal',
  templateUrl: './video-preview-modal.component.html',
  styleUrls: ['./video-preview-modal.component.scss']
})
export class VideoPreviewModalComponent implements OnInit {

  previewImage;

  constructor(
    private modalRef : ModalRef,
    private modalConfig: ModalConfig,
  ) {
    this.previewImage = this.modalConfig.data;
  }

  ngOnInit(): void {
  }

  modalClose() {
    this.modalRef.close();
  }

}
