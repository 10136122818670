import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface ICancellationReasonPayload {
  reason: string;
  isActive: boolean;
}

export interface ICancellationReasonDetails {
  referenceId: string;
  name: string;
  phoneNumber: string;
  kitchenId: string;
  kitchenName: string;
  dateAndTime: string;
  mealTime: string;
  paymentId: string;
  totalAmount: number;
  istDate: string;
  istTime: string;
}

export interface ICancellationReasonInfo {
  totalCounts: number;
  totalPages: number;
  cancellationReasons: ICancellationReasonDetails[];
}

export interface IFetchOneReason {
  data: ICancellationReasonInfo;
}

export interface ICancellationReasons {
  cancellationReasonId: string;
  reason: string;
  count: number;
  isActive: boolean;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IFetchAllReasonsWithMetrics {
  totalCancellationCount: number;
  todayTotalCancellationCount: number;
  highestReason: string;
  totalAdminCancellationCountToday: number;
}

export interface IFetchAllActiveInactiveReasons {
  cancellationReasons: ICancellationReasons[];
  totalCounts: number;
  totalPages: number;
}

export interface IFetchAllReasons {
  data: IFetchAllReasonsWithMetrics;
}

export interface IFetchAllReasonsList {
  data: IFetchAllActiveInactiveReasons;
}

@Injectable()
export class CancellationReasonApiService extends RestApiService {
  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private adminServicePath = `${this.adminApiUrl}/cancellation-reasons`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async fetchCancellationReasonsMetrics(queryParams?: Record<any, (string)> | undefined)
    : Promise<IFetchAllReasons> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['startDate']) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
    }
    const apiPath = `${this.adminServicePath}/metrics/details?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async fetchAllActiveInactiveCancellationReasons(queryParams?: Record<any, (string)> | undefined)
    : Promise<IFetchAllReasonsList> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      searchParams.set('isActive', String(queryParams['isActive']));
    }
    const apiPath = `${this.adminServicePath}/active-inactive/reasons?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async createCancellationReason(payload: ICancellationReasonPayload) {
    const apiPath = `${this.adminServicePath}/create`;
    return this.httpPost(apiPath, payload);
  }

  async updateCancellationReason(reasonId: string, payload: ICancellationReasonPayload) {
    const apiPath = `${this.adminServicePath}/update/${reasonId}`;
    return this.httpPatch(apiPath, payload);
  }
}
