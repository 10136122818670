import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CookStatus, FoodType } from '../../constants/view-cook.constants';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IS3FileResponse } from '../upload.service';
import { IUserLocation } from '../../constants/place-order.constants';

export enum SellerType {
  FOOD_SELLER = 'foodSeller',
  PRODUCT_SELLER = 'productSeller',
  SUBSCRIPTION = 'subscription',
}
export interface IGatewayResponse {
  accNumber?: string;
  ifscCode?: string;
}
export interface CookBankDetails {
  accHolderName: string;
  accNumber: string;
  bankBranchName: string;
  accType: string;
  ifscCode: string;
  upiId: string;
  gpayNumber: string;
  verifyStatus?: string;
  gatewayResponse: IGatewayResponse;
  proofImage?: {
    s3FileName: string;
    originalFileName: string;
    url?: IS3FileResponse;
  };
}

export interface CookDocumentDetails {
  panNumber?: string;
  fssaiRegNumber: string;
  panS3Filename?: string;
  panOriginalFileName?: string;
  panUrl?: IS3FileResponse,
  fssaiS3FileName: string;
  fssaiOriginalFileName: string;
  fssaiUrl?: IS3FileResponse,
  fssaiIssueDate?: Date,
  fssaiExpiryDate: Date,
  isGstInAvailable?: boolean;
  gstInNumber?: string,
  maskedGstInNumber?: string;
  isGstInVerified?: boolean;
  maskedPanNumber?: string;
  isPanVerified?: boolean;
  aadhaarNumber?: string;
  maskedAadhaarNumber?: string;
  isAadhaarVerified?: boolean,
}

export interface Terms {
  termConditions1S3FileName: string;
  termConditions1OriginalFileName: string;
  termConditions1Url?: IS3FileResponse,
  termConditions2S3FileName: string;
  termConditions2OriginalFileName: string;
  termConditions2Url?: IS3FileResponse,
  termConditions3S3FileName: string;
  termConditions3OriginalFileName: string;
  termConditions3Url?: IS3FileResponse,
}

export interface KitchenView {
  view1S3FileName: string;
  view1OriginalFileName: string;
  view1Url?: IS3FileResponse;
  view2S3FileName: string;
  view2OriginalFileName: string;
  view2Url?: IS3FileResponse;
  view3S3FileName: string;
  view3OriginalFileName: string;
  view3Url?: IS3FileResponse;
}

export interface CookAddress {
  line1Address: string;
  line2Address: string;
  line3Address: string;
  state: string;
  nearestLocation?: string;
  pincode: string;
  location: IUserLocation;
}

export interface CookPersonalInformation {
  title: string;
  name: string;
  age: number;
  email: string;
  mobilePrimary: string;
  mobileSecondary: string;
  address: CookAddress,
  foodCuisineStyle: string[];
  foodType: FoodType[];
  photoS3FileName?: string;
  photoOriginalFileName?: string;
  photoUrl?: IS3FileResponse;
  parentZoneId: string;
  subZoneId: string;
}

export interface CreateCookPayload {
  cookId?: string;
  status?: string;
  personalInformation?: CookPersonalInformation;
  address?: CookAddress;
  bank?: CookBankDetails;
  documents?: CookDocumentDetails;
  termConditions?: Terms;
  kitchenView?: KitchenView;
}

export interface ICook {
  _id: string,
  cookId: string,
  personalInformation: CookPersonalInformation,
  bank: CookBankDetails,
  documents: CookDocumentDetails,
  kitchenView: KitchenView,
  kitchenInspection: any,
  status: string,
  termConditions: Terms,
  updatedAt: string;
  createdAt: string,
  kitchenId?: string,
  kitchen?: {
    kitchenId: string;
    kitchenName: string;
    product?: {
      kitchenName: string;
    };
  };
  __v: number,
}

export interface ICookResponse {
  name: string,
  kitchenName?: string,
  cookId: string,
  kitchenId: string,
  mobilePrimary: string,
  sellerType?: string,
  foodType: Array<FoodType>,
  status: CookStatus,
  fssaiExpiryDate: Date,
  fssaiExpiryRemainderDate: Date,
  showFssaiExpiryWarningState?: boolean,
  createdAt: string,
  updatedAt: string;
  __v?: number,
  kitchenStatus: boolean;
  isForceDisabled?: boolean;
  product?: {
    kitchenName?: string;
  };
}
export interface ISeller {
  address: string,
  assets: [],
  cookId: string,
  coverImageOriginalFileName: string,
  coverImageS3FileName: string,
  createdAt: string,
  foodCuisineStyle: string[],
  foodType: Array<FoodType>,
  isActive: boolean,
  isForceDisabled?: boolean;
  kitchenId: string,
  kitchenName?: string,
  sellerCreatedAt: string,
  sellerName: string,
  sellerPhoneNumber: string,
  sellerStatus: string,
  product: {
    kitchenName: string;
    isActive: boolean;
    isForceDisabled?: boolean;
    revenueSharingPercentage?: number;
  };
  revenueSharingPercentage?: number;

}

export interface ICookDetailsResponse {
  cookProfiles: ICookResponse[];
  totalCookProfilesCount: number;
  totalPages: number;
}

export interface ISellerDetailsResponse {
  kitchenProfiles: ISeller[];
  kitchenCount: number;
  totalPages: number;
}

export interface IUpdateBrandStatusPayload {
  isActive: boolean;
  isForceDisabled?: boolean;
}

export interface IVerifyGST {
  legal_name_of_business: string;
  trade_name_of_business: string;
  nature_of_business_activities: Array<string>;
  principal_place_address: string;
}

export interface IGenerateOTP {
  ref_id: string;
  status: string;
  message: string;
}

export interface IVerifyOTP {
  ref_id: string;
  otp: string;
}

@Injectable({
  providedIn: 'root',
})
export class CookApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['COOK_SERVICE_URI'];

  protected adminServiceUrl = environment.config['ADMIN_SERVICE_URL'];

  protected apiUrl = environment.config['COOK_SERVICE_URI'];

  private servicePath = `${this.baseApiUrl}/common/profiles`;

  protected adminBaseApiUrl = environment.config.ADMIN_SERVICE_URL;

  private adminServicePath = `${this.adminBaseApiUrl}/cooks/`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async get(id: string): Promise<{ data: ICook; }> {
    const apiPath = `${this.baseApiUrl}/profiles/${id}`;
    return this.httpGet(apiPath);
  }

  async getAll(queryParams?: Record<string, any>): Promise<{ data: ICookDetailsResponse; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams?.['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams?.['subZoneId']);
      }
      if (queryParams['search']) {
        searchParams.set('search', queryParams?.['search']);
      }
      if (queryParams['searchFor']) {
        searchParams.set('searchFor', queryParams?.['searchFor']);
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllSellers(queryParams?: Record<string, any>): Promise<{
    data: ISellerDetailsResponse;
  }> {
    const searchParams = new URLSearchParams();
    let sellerType = '';
    if (queryParams) {
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams?.['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams?.['subZoneId']);
      }
      if (queryParams['search']) {
        searchParams.set('search', queryParams?.['search']);
      }
      if (queryParams['searchFor']) {
        searchParams.set('searchFor', queryParams?.['searchFor']);
      }
      if (queryParams['isProductSeller'] !== null) {
        sellerType = queryParams['isProductSeller'] ? SellerType.PRODUCT_SELLER : SellerType.FOOD_SELLER;
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
    }
    const apiPath = `${this.adminServiceUrl}/kitchens/sellers-list/seller-type${sellerType.length ? `/${sellerType}` : ''}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllKitchen(queryParams?: Record<string, any>): Promise<ICookResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (Array.isArray(queryParams['status'])) {
        queryParams['status'].forEach((item) => {
          searchParams.append('status', item);
        });
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams?.['parentZoneId']);
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', queryParams?.['subZoneId']);
      }
    }
    const apiPath = `${this.apiUrl}/cooks/?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: CreateCookPayload) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: Partial<CreateCookPayload>) {
    const apiPath = `${this.servicePath}/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async updateBankDetails(id: string) {
    const apiPath = `${this.adminServicePath}update-bank-details/with-gateway-info/${id}`;
    return this.httpPatch(apiPath);
  }

  async sendOtpForAadhaar(aadhaarNumber: string) {
    const apiPath = `${this.baseApiUrl}/common/profiles/verify-aadhaar/generate-otp`;
    const payload = {
      aadhaarNumber,
    };
    return this.httpPost(apiPath, payload);
  }

  async sendVerificationForAadhaar(payload: any) {
    const apiPath = `${this.baseApiUrl}/common/profiles/verify-aadhaar/verify-otp`;
    return this.httpPost(apiPath, payload);
  }

  async updateFoodOrBrandStatus(kitchenId: string, sellerType: SellerType, payload: IUpdateBrandStatusPayload) {
    const apiPath = `${this.adminServiceUrl}/kitchens/${kitchenId}/seller-status/seller-type/${sellerType}`;
    return this.httpPatch(apiPath, payload);
  }

  async verifyGst(cookId: string, payload: { GSTIN: string; }): Promise<{ data: IVerifyGST; }> {
    const apiPath = `${this.servicePath}/gst-in-verification/${cookId}`;
    return this.httpPost(apiPath, payload);
  }

  async verifyPan(cookId: string, payload: { pan: string; }): Promise<{ data: any; }> {
    const apiPath = `${this.servicePath}/pan-card-verification/${cookId}`;
    return this.httpPost(apiPath, payload);
  }

  async generateOtp(payload: { aadhaarNumber: string; }): Promise<{ data: IGenerateOTP; }> {
    const apiPath = `${this.servicePath}/aadhaar-verification/generate-otp`;
    return this.httpPost(apiPath, payload);
  }

  async verifyOtp(cookId: string, payload: IVerifyOTP): Promise<{ data: any; }> {
    const apiPath = `${this.servicePath}/aadhaar-verification/verify-otp/${cookId}`;
    return this.httpPost(apiPath, payload);
  }
}
