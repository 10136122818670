import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { envName, RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IAllITagMasters, IAllITagMastersOffer, IGetITagMasters, ITagMasters } from '../../constants/tag-master.constants';

@Injectable()
export class TagMasterApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config.ADMIN_SERVICE_URL;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getTag(id: string): Promise<IGetITagMasters> {
    const apiPath = `${this.baseApiUrl}/tags/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllTags(skip: number, limit: number, searchText: string): Promise<IAllITagMasters> {
    const searchParams = new URLSearchParams();
    if (Number(skip) || Number(skip) === 0) {
      searchParams.set('skip', String(skip));
    }
    if (Number(limit)) {
      searchParams.set('limit', String(limit));
    }
    if (searchText) {
      searchParams.set('searchText', String(searchText));
    }
    const apiPath = `${this.baseApiUrl}/tags?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllTagsByTagType(skip: number, limit: number, tagType: string, searchText: string): Promise<IAllITagMastersOffer> {
    const searchParams = new URLSearchParams();
    if (Number(skip) || Number(skip) === 0) {
      searchParams.set('skip', String(skip));
    }
    if (Number(limit)) {
      searchParams.set('limit', String(limit));
    }
    if (searchText) {
      searchParams.set('searchText', String(searchText));
    }
    if (tagType) {
      searchParams.set('tagType', String(tagType));
    }
    const apiPath = `${this.baseApiUrl}/tags?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async createTag(payload: ITagMasters): Promise<IGetITagMasters> {
    delete payload.tagId;
    const apiPath = `${this.baseApiUrl}/tags`;
    return this.httpPost(apiPath, payload);
  }

  async updateTag(payload: ITagMasters): Promise<IGetITagMasters> {
    const apiPath = `${this.baseApiUrl}/tags/${payload.tagId}`;
    return this.httpPut(apiPath, payload);
  }

  async deleteTag(tagId: string) {
    const apiPath = `${this.baseApiUrl}/tags/${tagId}`;
    return this.httpDelete(apiPath);
  }
}
