/* eslint-disable no-use-before-define */
import { Directive } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions, FormArray, FormControl, FormGroup, RequiredValidator, ValidatorFn,
  Validator,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import moment from 'moment';
import { timezone } from '../constants/time-zone.constants';

export interface IChoice {
  name: string,
  value: string,
  selected?: boolean,
  selectable?: boolean,
}
export interface ICheckboxItemControlInput {
  label: string;
  value: string;
  selectable?: boolean;
  defaultValue?: boolean;
}

export class CheckboxItemControl {
  label: string;

  value: string;

  selectable?: boolean;

  control: FormControl;

  constructor(input: ICheckboxItemControlInput) {
    this.label = input.label;
    this.value = input.value;
    this.selectable = input.selectable;
    this.control = new FormControl(input.defaultValue || false);
  }

  get selected(): boolean {
    return Boolean(this.control.value);
  }
}

export class CheckboxGroupControl {
  name?: string;

  items: CheckboxItemControl[];

  control: FormArray;

  constructor(
    name: string,
    items: CheckboxItemControl[],
    validaton: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null | undefined = [],
  ) {
    this.name = name;
    this.items = items;

    this.control = new FormArray(
      this.getAllItemsControls(),
      validaton,
    );
  }

  get value(): string[] {
    return this.selectedItems.map((item) => item.value);
  }

  private get selectedItems(): CheckboxItemControl[] {
    return this.items.filter((item) => item.selected);
  }

  private getAllItemsControls(): FormControl[] {
    return this.items.map((item) => item.control);
  }
}

export const generateCheckboxGroupControl = (
  name: string,
  choices: IChoice[],
  validators: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null | undefined = [],
) => new CheckboxGroupControl(
  name,
  choices.map((choice) => new CheckboxItemControl({
    label: choice.name,
    value: choice.value,
    selectable: choice.selectable,
    defaultValue: !!choice.selected,
  })),
  validators,
);

@Directive({
  selector: '[checkboxRequiredValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CheckboxRequiredValidator,
    multi: true,
  }],
})
export class CheckboxRequiredValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const oneCheckboxSelected = control.value.some((value: boolean) => value);
    if (!oneCheckboxSelected) {
      return ({
        required: true,
      });
    }
    return null;
  }
}

export function formatDateWithTimezone(date: string | Date, format: string) {
  if (date) {
    return moment.tz(date, timezone).format(format);
  }
  return '-';
}
