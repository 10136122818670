import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export enum CouponSubCategory {
  orderRefund = 'orderRefund'
}

export interface ICouponConstraints {
  maxRedemption?: number,
  minOrderValue?: number,
  cookrCoinsRequired?: number,
  maxDiscountPrice?: number,
  userId?: string,
  hidden?: boolean,
  orderType?: string,
  orderTimeDifference?: number,
  customerMobile?: string,
  itemId?: string,
  kitchenId?: string,
  referenceId?: string,
  mealTime?: Array<string>;
  parentZoneId?: Array<string>;
}

export interface IOfferConstraints {
  minOrderValue?: number;
  maxDiscountPrice?: number;
  entityIds: Array<string>;
  entity: string;
  hidden?: boolean;
  orderTimeDifference?: number;
  orderType?: string;
  tagId?: string,
}
export interface ICreateCouponPayload {
  category: string;
  couponCode: string;
  couponName: string;
  description: string;
  remarks?: string;
  subCategory?: string;
  discount: number;
  discountType: string;
  offerConstraints: IOfferConstraints | null;
  couponConstraints: ICouponConstraints | null,
  isActive: boolean;
  couponId?: string;
  detailedDescription: string;
  termsAndCondition: string;
  validTill?: Date;
  currency?: string;
  createdFor: string;
  sellerCategory: string;
  cookOfferPercentage?: number;
  createdBy?: any;
  updatedBy?: any;
  __v?: any;
}

export interface ICoupon {
  category: string;
  createdFor: string;
  offerConstraints: IOfferConstraints | null;
  couponConstraints: ICouponConstraints | null,
  isActive: boolean;
  description: string;
  remarks?: string;
  couponName: string;
  discount: number;
  discountType: string;
  couponCode: string;
  couponId: string;
  detailedDescription: string;
  termsAndCondition: string;
  validTill: Date;
  currency: string;
  cookOfferPercentage?: number;
  createdBy?: {
    entityName: string;
    entityId: string,
    entityType: string
  };
}

export interface IGetAllKitchens {
  kitchenName: string;
  kitchenId: string;
}

export interface IGetAllKitchensByPagination {
  kitchens: Array<IGetAllKitchens>;
  totalKitchens?: number;
  totalPages: number;
  currentPage?: number;
}
export interface IGetAllItems {
  title: string;
  itemId: string;
  kitchenName: string;
}

export interface IGetKitchenSearchResults {
  kitchens: Array<IGetAllKitchens>;
  totalKitchens: number;
  totalPages: number;
}

export interface IGetItemSearchResults {
  items: Array<IGetAllItems>;
  totalItems: number;
  totalPages: number;
}

export interface IGetAllItemsByPagination {
  items: Array<IGetAllItems>;
  totalItems?: number;
  totalPages: number;
  currentPage?: number;
}

export interface IGetCoupons {
  coupons: Array<ICoupon>;
  totalCoupons: number;
  totalPages: number;
}
export interface IGetOffers {
  offers: Array<ICoupon>;
  totalOffers: number;
  totalPages: number;
}

export interface ICouponLogs {
  couponId: string;
  couponCode: string;
  userId: string;
  savingsOnDiscount: number;
  couponLogId: string;
  createdAt: Date;
  updatedAt: Date;
  orderId?: string;
  userName: string;
  phoneNumber: number;
}

export interface IGetCouponLogs {
  couponLogs: Array<ICouponLogs>;
  couponLogsCount: number;
}

export enum SellerCategory {
  FOOD_SELLER = 'foodSeller',
  PRODUCT_SELLER = 'productSeller',
}
export enum SellerProductEntity {
  SELLERS = 'seller',
  SELLER_PRODUCT = 'sellerProduct'
}

@Injectable()
export class CouponApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  private adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllKitchens(
    skip: number,
    limit: number,
    parentZoneId?: string,
    subZoneId?: string,
  ): Promise<{ data: IGetAllKitchensByPagination }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (parentZoneId) {
      searchParams.set('parentZoneId', parentZoneId);
    }
    if (subZoneId) {
      searchParams.set('subZoneId', subZoneId);
    }
    const apiPath = `${this.adminApiUrl}/kitchens/kitchens-list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSearchKitchens(
    skip: number,
    limit: number,
    searchText: string,
    parentZoneId?: string,
    subZoneId?: string,
  ): Promise<{ data: IGetKitchenSearchResults }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (searchText !== undefined) {
      searchParams.set('searchText', String(searchText));
    }
    if (parentZoneId) {
      searchParams.set('parentZoneId', parentZoneId);
    }
    if (subZoneId) {
      searchParams.set('subZoneId', subZoneId);
    }
    const apiPath = `${this.adminApiUrl}/kitchens/search-by-kitchen-name?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSelectedKitchens(payload: Array<string>): Promise<{ data: IGetAllKitchens[] }> {
    const apiPath = `${this.adminApiUrl}/kitchens/search-by-kitchen-id`;
    const result = {
      kitchenIds: payload,
      sellerCategory: SellerCategory.FOOD_SELLER,
    };
    return this.httpPost(apiPath, result);
  }

  async getSearchItems(
    skip: number,
    limit: number,
    searchText: string,
    parentZoneId?: string,
    subZoneId?: string,
  ): Promise<{ data: IGetItemSearchResults }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (searchText !== undefined) {
      searchParams.set('searchText', String(searchText));
    }
    if (parentZoneId) {
      searchParams.set('parentZoneId', parentZoneId);
    }
    if (subZoneId) {
      searchParams.set('subZoneId', subZoneId);
    }
    const apiPath = `${this.adminApiUrl}/items/search-by-item-name?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllItems(
    skip: number,
    limit: number,
    parentZoneId?: string,
    subZoneId?: string,
  ): Promise<{ data: IGetAllItemsByPagination }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (parentZoneId) {
      searchParams.set('parentZoneId', parentZoneId);
    }
    if (subZoneId) {
      searchParams.set('subZoneId', subZoneId);
    }
    const apiPath = `${this.adminApiUrl}/items/items-list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getSelectedItems(payload: Array<string>): Promise<{ data: IGetAllItems[] }> {
    const apiPath = `${this.adminApiUrl}/items/search-by-item-id`;
    const result = {
      itemIds: payload,
    };
    return this.httpPost(apiPath, result);
  }

  async get(id: string): Promise<{ data: ICoupon }> {
    const apiPath = `${this.servicePath}/coupons/${id}/details`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll(): Promise<ICoupon[]> {
    const apiPath = `${this.servicePath}/coupons/`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllCoupons(skip: number, limit: number, couponCode?: string, subCategory?: string, sellerCategory?: string)
    : Promise<{ data: IGetCoupons }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    if (subCategory !== undefined) {
      searchParams.set('subCategory', String(subCategory));
    }
    if (sellerCategory !== undefined) {
      searchParams.set('sellerCategory', String(sellerCategory));
    }
    if (couponCode && couponCode !== undefined) {
      searchParams.set('couponCode', String(couponCode));
    }
    const apiPath = `${this.adminApiUrl}/coupons/coupons-list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllOffers(filters: { skip: number, limit: number, offerCode: string, sellerCategory: string }): Promise<{ data: IGetOffers }> {
    const searchParams = new URLSearchParams();
    if (filters.skip || filters.skip === 0) {
      searchParams.set('skip', String(filters.skip));
    }
    if (filters.limit) {
      searchParams.set('limit', String(filters.limit));
    }
    if (filters.offerCode) {
      searchParams.set('offerCode', String(filters.offerCode));
    }
    if (filters.sellerCategory) {
      searchParams.set('sellerCategory', String(filters.sellerCategory));
    }
    const apiPath = `${this.adminApiUrl}/coupons/offers-list?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: ICreateCouponPayload) {
    const apiPath = `${this.servicePath}/coupons/`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}/coupons/${id}`;
    return this.httpDelete(apiPath);
  }

  async update(id: string, payload: Partial<ICreateCouponPayload>) {
    const apiPath = `${this.servicePath}/coupons/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async createOffer(payload: ICreateCouponPayload) {
    const apiPath = `${this.servicePath}/offers/`;
    return this.httpPost(apiPath, payload);
  }

  async offerUpdate(id: string, payload: Partial<ICreateCouponPayload>) {
    const apiPath = `${this.servicePath}/offers/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async getCouponLogs(
    id: string,
    skip: number,
    limit: number,
    mobileNumber?: string,
  ): Promise<{ data: IGetCouponLogs }> {
    const searchParams = new URLSearchParams();
    if (skip !== undefined) {
      searchParams.set('skip', String(skip));
    }
    if (mobileNumber && mobileNumber !== undefined) {
      searchParams.set('mobileNumber', mobileNumber);
    }
    if (limit !== undefined) {
      searchParams.set('limit', String(limit));
    }
    const apiPath = `${this.adminApiUrl}/coupons/logs/applied/${id}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }
}
