import { Injectable } from '@angular/core';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { environment } from '../../environments/environment';

export enum BroadcastMessageEnum {
  SMALL = 'small',
  LARGE = 'large',
}

export interface IFile {
  url: string;
  expiresIn: number;
}

export interface IAttachments {
  originalFileName: string;
  s3FileName: string;
  fileUrl?: IFile;
  attachmentImageType: string
}

export interface ICreatedBy {
  entityType: string;
  entityId: string;
  entityName: string;
}

export interface ICreateBroadcastMessage {
  title: string;
  message: string;
  parentZoneId: string;
  attachments?: Array<IAttachments>;
}

export interface IBroadcastMessages {
  attachments: Array<IAttachments>;
  createdAt: string;
  createdBy: ICreatedBy;
  isActive: boolean;
  message: string;
  messageId: string;
  title: string;
  updatedAt: string;
  __v: string;
  _id: string;
}

export interface IBroadcastMessageResponse {
  totalBroadcastMessage: number;
  totalPages: number;
  broadcastMessages: Array<IBroadcastMessages>
  isActive: boolean;
  parentZoneId: string;
  createdBy: ICreatedBy;
  createdAt: string;
  updatedAt: string;
  _id: string;
  __v: string;
}

@Injectable()
export class BroadcastMessageService extends RestApiService {
  protected adminApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.adminApiUrl}/broadcast-message`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async createBroadcastMessage(payload: ICreateBroadcastMessage)
    : Promise<{ data: IBroadcastMessages }> {
    const apiPath = `${this.servicePath}`;
    return this.httpPost(apiPath, payload);
  }

  async getAllBroadcastMessage(queryParams?: Record<any, (string)> | undefined)
    : Promise<{ data: IBroadcastMessageResponse }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', queryParams['parentZoneId']);
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }
}
