import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface ICategories {
  categoryId: string;
  categoryName: string;
  categoryDescription: string;
  categoryS3FileName: string;
  categoryOriginalFileName: string;
  createdBy: {
    entityName: string;
    entityType: string;
    entityId: string;
  },
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: {
    entityName: string;
    entityType: string;
    entityId: string;
  },
  categoryImageUrl: string;
}

export interface IGetAllCategoryResponse {
  categories: Array<ICategories>,
  catagoriesCount: number
}

export interface IAssets {
  's3FileName': string;
  'originalFileName': string;
  'contentType': string;
  'fileType': string;
  'thumbnailOriginalFileName': string;
  'thumbnailS3FileName': string;
  'fileUrl': {
    'url': string;
    'expiresIn': number
  }
}

export interface IProducts {
  productId?: string;
  approvalStatus?: string;
  assets?: Array<IAssets>,
  categoryId?: string;
  certificates?: any,
  createdAt?: string;
  createdBy?: {
    entityId: string;
    entityType: string;
  },
  crossSellProductId?: any;
  description: string;
  expirationDetails?: any,
  flavours?: any,
  foodType?: string;
  ingredients?: any,
  kitchenId?: string;
  name?: string;
  nutritionalInfo?: any;
  productInfo?: any;
  subCategoryId?: string;
  updatedAt?: string;
  updatedBy?: {
    entityId: string;
    entityType: string;
  }
}

export interface IGetAllProductsResponse {
  products: Array<IProducts>,
  productsCount: number
}

export interface IGetAllSubCategory {
  subCategoryId: string;
  subCategoryName: string;
  subCategoryDescription: string;
  categoryId: string;
}

export interface IFlavous {
  flavourId: string;
  levels: Array<string>;
  flavourName: string;
}

export interface IGetAllProductList {
  name: string;
  productId: string;
}

export interface IGetSubCategoryDetails {
  'subCategoryId': string;
  'subCategoryName': string;
  'subCategoryDescription': string;
  'subCategoryS3FileName': string;
  'subCategoryOriginalFileName': string;
  'categoryId': string;
  'flavours': Array<IFlavous>;
  'createdBy': {
    'entityName': string;
    'entityType': string;
    'entityId': string;
  },
  'isActive': boolean;
  'createdAt': string;
  'updatedAt': string;
  'updatedBy': {
    'entityName': string;
    'entityType': string;
    'entityId': string;
  },
  'customizeOptions': any,
  'subCategoryImageUrl': string;
}

export interface IProductDescriptionPayload {
  productName: string;
  productId: string;
}

export interface IProductDescription {
  productDescription: string;
  productName: Array<string>;
}

@Injectable()
export class ProductItemApiService extends RestApiService {
  private readonly headers: any;

  // TODO: update this end point
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/products`;

  private idToken = localStorage.getItem('idToken') || '';

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllProducts(
    skip: number,
    limit: number,
    search: string,
    kitchenId: string,
  ): Promise<{ data: IGetAllProductsResponse }> {
    const searchParams = new URLSearchParams();
    if (skip || skip === 0) {
      searchParams.set('skip', String(skip));
    }
    if (limit) {
      searchParams.set('limit', String(limit));
    }
    if (search) {
      searchParams.set('search', String(search));
    }
    if (kitchenId) {
      searchParams.set('kitchenId', String(kitchenId));
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getProductById(productId: any): Promise<any> {
    const apiPath = `${this.servicePath}/${productId}`;
    return this.httpGet(apiPath);
  }

  async createProduct(payload: any): Promise<any> {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async updateProduct(payload: any, productId: string): Promise<any> {
    const apiPath = `${this.servicePath}/${productId}`;
    return this.httpPut(apiPath, payload);
  }

  async getProductList(queryParams: Record<any, (string | number)>):
    Promise<{ data: Array<IGetAllProductList> }> {
    const searchParams = new URLSearchParams();
    let kitchenId = '';
    if (queryParams['currentProductId']) {
      searchParams.set('excludeProductId', String(queryParams['currentProductId']));
    }
    if (queryParams['kitchenId']) {
      kitchenId = String(queryParams['kitchenId']);
    }
    const apiPath = `${this.servicePath}/kitchens/${kitchenId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async deleteProduct(productId: string) {
    const apiPath = `${this.servicePath}/${productId}`;
    return this.httpDelete(apiPath);
  }

  async getProductDescription(payload: IProductDescriptionPayload):
    Promise<{ data: IProductDescription }> {
    const apiPath = `${this.servicePath}/generate-description`;
    return this.httpPost(apiPath, payload);
  }
}
