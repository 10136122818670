/* eslint-disable no-param-reassign */
import { Component, OnInit } from '@angular/core';
import {
  Observable, firstValueFrom, from, of,
} from 'rxjs';
import { ManipulateDeliveryPreferenceModalComponent } from '../../../shared/pop-ups/manipulate-delivery-preference-modal/manipulate-delivery-preference-modal.component';
import { ModalService } from '../../../../lib/modal/modal.service';
import { AppAbilityKey } from '../../../../enums/ability-keys.enum';
import { InitialService } from '../../../../services/initial.service';
import { AppAbility } from '../../../../services/appAbility';
import { ConfigApiService, IConfigResponse, IOrderNowConfigurationPayload} from '../../../../services/api/config.service';
import { envName } from '../../../../services/api.service';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import { ZoneApiService } from '../../../../services/api/zone-api.service';
import { HotToastService } from '@ngneat/hot-toast';

const paginationConfig = {
  skip: 0,
  limit: 10,
  currentPage: 1,
  totalItems: 0,
};

export enum IDeliveryTimeSlotOption {
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}

export enum IDeliveryPreferencesOption {
  ORDER_NOW = 'Order Now',
  ORDER_LATER = 'Order Later',
  BOTH = 'Both',
}

@Component({
  selector: 'app-delivery-preference',
  templateUrl: './delivery-preference.component.html',
  styleUrls: ['./delivery-preference.component.scss'],
})
export class DeliveryPreferenceComponent implements OnInit {
  public paginationConfig = { ...paginationConfig };

  public deliveryTimeSlotOption = IDeliveryTimeSlotOption;

  public deliveryPreferences: any;

  filteredParentZones$!: Observable<any>;

  constructor(
    private configService: ConfigApiService,
    private readonly initialService: InitialService,
    public appAbility: AppAbility,
    private modalConfig: ModalConfig,
    private readonly modalService: ModalService,
    private readonly zoneService: ZoneApiService,
    private readonly toast: HotToastService,
  ) {
    this.appAbility = this.initialService.fetchAbilities();
  }

  get appAbilityKey(): typeof AppAbilityKey {
    return AppAbilityKey;
  }

  async ngOnInit() {
    await this.loadDependencies();
  }

  private async loadDependencies() {
    const configSettingResponse: { data: IConfigResponse } = await this.configService.getConfigSettings(`app-configs-customer-${envName}`);
    this.deliveryPreferences = configSettingResponse.data.orderNowConfiguration;
    this.filteredParentZones$ = this.getFilteredParentZones();
  }

  getFilteredParentZones(): Observable<any> {
    if (this.deliveryPreferences) {
      const parentZonePromises = Object.keys(this.deliveryPreferences)
        .filter((key) => key.startsWith('PARENT_ZONE-') || key === 'default')
        .map(async (key) => {
          const parentZoneId = key.replace('PARENT_ZONE-', '');
          const parentZoneName = await this.getParentZoneName(parentZoneId);
          return {
            parentZoneId,
            parentZoneName,
            displayDeliveryTimeSlot: this.deliveryPreferences[key].displayDeliveryTimeSlot,
            deliveryTimeSlotMessage: this.deliveryPreferences[key].deliveryTimeSlotMessage,
            isActive: this.deliveryPreferences[key].isActive,
            deliveryTimeSlotOption: this.deliveryPreferences[key].deliveryTimeSlotOption,
          };
        });
      return from(Promise.all(parentZonePromises));
    }
    return of([]);
  }

  async getParentZoneName(parentZoneId: string): Promise<string> {
    if (parentZoneId === 'default') {
      return 'Common';
    }
    return this.zoneService.getParentZone(parentZoneId)
      .then((result) => result.data.parentZoneName);
  }

  async openAddDeliveryPreferenceConfigModal() {
    this.modalConfig.data = {
      manipulationType: 'ADD',
    };
    const modalRef = this.modalService.open(ManipulateDeliveryPreferenceModalComponent);
    const addedDeliveryPreferenceInfo = await firstValueFrom(modalRef.dataEmitter);
    if (addedDeliveryPreferenceInfo) {
      this.loadDependencies();
    }
  }

  async openEditDeliveryPreferenceConfigModal(data: any) {
    this.modalConfig.data = {
      deliveryPreference: data,
      manipulationType: 'EDIT',
    };
    const modalRef = this.modalService.open(ManipulateDeliveryPreferenceModalComponent);
    const updatedDeliveryPreferenceInfo = await firstValueFrom(modalRef.dataEmitter);
    if (updatedDeliveryPreferenceInfo) {
      this.loadDependencies();
    }
  }

  async updateDeliveryPreferencesStatus(event: Event,deliveryPreferences: IOrderNowConfigurationPayload){
    const isActive = (event.target as any).checked;
    const payload = {
      parentZoneId : deliveryPreferences.parentZoneId !== "default" ?  deliveryPreferences.parentZoneId : undefined,
      configuration: {
        deliveryTimeSlotMessage: deliveryPreferences.deliveryTimeSlotMessage,
        deliveryTimeSlotOption: deliveryPreferences.deliveryTimeSlotOption,
        displayDeliveryTimeSlot: deliveryPreferences.displayDeliveryTimeSlot,
        isActive: isActive
      }
    }

    const deliveryPreference = this.configService.updateOrderNowConfig('customer', payload);
    from(deliveryPreference).pipe(
      this.toast.observe({
        error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
      }),
    ).subscribe();
    const res = await deliveryPreference.catch(() => {

    });
    if (res) {
      this.loadDependencies();
    }
  }
}
