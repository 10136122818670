<div class="modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <div class="modal-title">Preview Video</div>
      <img src="assets/icon-order-status-close.svg" class="cursor-pointer" alt="icon-close" (click)="modalClose()" />
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
      <video controls class="video">
        <source [src]="previewVideo" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</div>