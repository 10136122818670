import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export enum NOTIFICATION_EVENT_TYPE {
  NOTIFICATION = 'notification',
  CALL = 'call',
  ALL = 'all',
  COUPON = 'coupon'
}

export interface ICreatePayloadPushNotification {
  userType: string;
  sendType: string;
  phoneNumberList?: Array<string> | null,
  title: string,
  body: string,
  photoS3FileName?: string,
  photoOriginalFileName?: string,
  data?: object,
}

export interface IDelayNotification {
  templateId?: string;
  templateCategory: string;
  templateName: string;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
}

export interface IDelayNotificationPayload {
  templateId?: string;
  templateCategory: string;
  templateName: string;
  title: string;
  content: string;
}

export interface ICouponPayload {
  couponId?: string,
  couponName?: string,
  eventType?: NOTIFICATION_EVENT_TYPE,
  callMadeToCustomer: boolean,
  remarks?: string,
}

export const TemplateCategories = [{
  label: 'Customer Delay',
  value: 'customerDelay',
},
];

@Injectable()
export class PushNotificationService extends RestApiService {
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/push-notifications`;

  private delayNotificationServicePath = `${this.baseApiUrl}/todays-order/send-delay-notification`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async create(payload: ICreatePayloadPushNotification) {
    const apiPath = this.servicePath;
    return this.httpPost(apiPath, payload);
  }

  async getDelayNotification(id: string): Promise<IDelayNotification> {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllDelayNotifications(templateCategory: string):
    Promise<{ data: IDelayNotification[] }> {
    const apiPath = `${this.baseApiUrl}/templates/list/${templateCategory}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async createDelayNotification(payload: IDelayNotificationPayload) {
    const apiPath = `${this.baseApiUrl}/templates`;
    return this.httpPost(apiPath, payload);
  }

  async deleteDelayNotification(id: string) {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpDelete(apiPath);
  }

  async updateDelayNotification(id: string, payload: IDelayNotificationPayload) {
    const apiPath = `${this.baseApiUrl}/templates/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async updateCouponResponse(referenceId: string, payload: ICouponPayload) {
    const apiPath = `${this.delayNotificationServicePath}/reference/${referenceId}`;
    return this.httpPost(apiPath, payload);
  }
}
