import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss']
})
export class VideoPreviewComponent implements OnInit {

  public previewVideo: SafeUrl | null = null;

  @Output()
  public close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  modalClose() {
    this.close.emit();
  }

}
