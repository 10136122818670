import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { RestApiService } from './api.service';
import { HttpClientService } from './http-client.service';

export interface IUploadS3FileQuery {
  fileName: string;
  contentType: string;
}

export interface IDownloadS3FileQuery {
  fileName: string;
  originalFileName: string;
}

export interface IS3FileResponse {
  url: string;
  expiresIn?: number;
}

@Injectable()
export class S3Service extends RestApiService {
  protected override baseApiUrl = `${environment.config.ADMIN_SERVICE_URL}/aws-store`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  updateBaseService(serviceUrl: string) {
    this.baseApiUrl = `${serviceUrl}/aws-store`;
  }

  private async getDownloadUrl(
    downloadUrlOptions: IDownloadS3FileQuery,
  ): Promise<IS3FileResponse> {
    const queryParams = new URLSearchParams({
      fileName: String(downloadUrlOptions.fileName),
      originalFileName: String(downloadUrlOptions.originalFileName),
    });
    const path = `${this.baseApiUrl}/download-url/?${queryParams.toString()}`;
    return this.httpGet(path) as Promise<IS3FileResponse>;
  }

  private async getUploadUrl(
    uploadUrlOptions: IUploadS3FileQuery,
  ): Promise<{ data: IS3FileResponse }> {
    const queryParams = new URLSearchParams({
      fileName: uploadUrlOptions.fileName,
      contentType: uploadUrlOptions.contentType,
    });
    const path = `${this.baseApiUrl}/upload-url/?${queryParams.toString()}`;
    return this.httpGet(path) as Promise<{ data: IS3FileResponse }>;
  }

  public async get(options: IDownloadS3FileQuery): Promise<any> {
    const fileResponse = await this.getDownloadUrl(options);
    return fileResponse;
  }

  public async upload(file: File, options?: IUploadS3FileQuery) {
    let uploadOption = options || {};
    const headers: Record<string, string> = { 'Content-Type': file.type };
    if (!options?.fileName) {
      uploadOption = {
        contentType: file.type,
        fileName: file.name,
      } as IUploadS3FileQuery;
    }
    const presignedResponse = await this.getUploadUrl(uploadOption as IUploadS3FileQuery);
    const uploadedResponse = await this.httpPut(presignedResponse.data.url, file, headers);
  }
}
