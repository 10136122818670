export enum StorageKey {
  KITCHEN_INFO = 'KITCHEN_INFO',
  COOK_PROFILE = 'COOK_PROFILE',
  DE_PROFILE = 'DE_PROFILE',
  ALLOW_ALL_TAB_NAVIGATION = 'ALLOW_ALL_TAB_NAVIGATION',
  KITCHEN_ACTIVE_STATE = 'KITCHEN_ACTIVE_STATE',
  USER_ABILITIES = 'USER_ABILITIES',
  REPORTEE_USER_PROFILE = 'REPORTEE_USER_PROFILE',
  GENERATE_COOK_PAYOUT_DETAILS = 'GENERATE_COOK_PAYOUT_DETAILS',
  VIEW_COOK_PAYOUTS_DETAILS ='VIEW_COOK_PAYOUTS_DETAILS',
  GENERATE_PRODUCT_PAYOUT_DETAILS = 'GENERATE_PRODUCT_PAYOUT_DETAILS',
  VIEW_PRODUCT_PAYOUTS_DETAILS ='VIEW_PRODUCT_PAYOUTS_DETAILS',
}
