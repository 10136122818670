import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IDeliverySlot } from '../../constants/delivery-slot-management.constants';

interface Location {
  type: string;
  coordinates: number[];
}

export interface ILatitudeAndLongitude {
  lng: number;
  lat: number;
}
interface Coordinates {
  type: string;
  coordinates: Array<Array<Array<number>>>;
}

export interface subZonalDetail {
  subZoneId: string;
  radiusInKms?: number;
  parentZoneAliasName: string;
  parentZoneId: string;
  parentZoneName: string;
  subZoneName: string;
  subZoneAliasName: string;
  location: Location;
  active?: boolean;
  zoneCoordinates: Coordinates;
  inactiveMessageId?: string;
  maxRadiusInKms?: number;
  isMaxRadiusActive?: boolean;
}

export interface getSubZonalDetail {
  parentZoneId: string;
  parentZoneName: string;
  subZoneId: string;
  radiusInKms: number;
  subZoneName: string;
  subZoneAliasName: string;
  location: Location;
  active: boolean;
}

export type CreateSubZone = {
  parentZoneId?: string;
  subZoneId?: string;
  radiusInKms?: number;
  subZoneName: string;
  subZoneAliasName: string;
  location?: Location;
  active: boolean;
  zoneCoordinates: Coordinates;
  inactiveMessageId?: string;
  maxRadiusInKms?: number;
  isMaxRadiusActive?: boolean;
}

export type CreateParentZone = {
  radiusInKms?: number;
  parentZoneName: string;
  parentZoneAliasName: string;
  location?: Location;
  firstMileDistance?: number;
  lastMileDistance?: number;
  zoneCoordinates: Coordinates;
  isTplPreferable: boolean;
  vendorName: Array<string>;
  city?: string;
  state?: string;
}

export type ParentZonalDetail = {
  parentZoneId: string;
  radiusInKms?: number;
  parentZoneName: string;
  parentZoneAliasName: string;
  location: Location;
  selected?: boolean;
  zoneCoordinates: Coordinates;
  firstMileDistance?: number;
  lastMileDistance?: number;
  isTplPreferable: boolean;
  vendorName: Array<string>;
  checked?: boolean;
  isClosed?: boolean;
  city?: string;
  state?: string;
  productInstantDeliveryRadiusInKms?: number;
  productSellersRadiusInKms?: number;
  parentZoneNameAbbreviation?: string;
}

@Injectable()
export class ZoneApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = `${environment.config['CONFIG_SERVICE_URL']}/common`;

  protected adminBaseApiUrl = environment.config['ADMIN_SERVICE_URL'];

  // TODO: update this end point
  private servicePath = `${this.baseApiUrl}/zonal-details/`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async get(id: string): Promise<{data: subZonalDetail}> {
    const apiPath = `${this.servicePath}sub-zone/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getParentZoneById(payload: { parentZoneId: string[] })
    : Promise<{ data: ParentZonalDetail[] }> {
    const apiPath = `${this.adminBaseApiUrl}/zonal-details/parent-zone/get-info-by-ids`;
    return this.httpPost(apiPath, payload);
  }

  async getAllSubzonesForParent(id: string): Promise<{ data: subZonalDetail[] }> {
    const apiPath = `${this.servicePath}sub-zone/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll(): Promise<{ data: subZonalDetail[] }> {
    const apiPath = `${this.servicePath}eligible/sub-zones`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getParentZone(parentZoneId: string): Promise<{ data: ParentZonalDetail }> {
    const apiPath = `${this.servicePath}parent-zone/${parentZoneId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllParentZone(
    queryParams?: Partial<any>,
  ): Promise<{ data: ParentZonalDetail[] }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      const { removeClosed } = queryParams;
      if (removeClosed !== undefined) {
        searchParams.set('removeClosed', String(removeClosed));
      }
    }
    const apiPath = `${this.servicePath}parent-zones?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllEligibleParentZone(
    queryParams?: Partial<any>,
  ): Promise<{ data: ParentZonalDetail[] }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      const { removeClosed } = queryParams;
      if (removeClosed !== undefined) {
        searchParams.set('removeClosed', String(removeClosed));
      }
    }
    const apiPath = `${this.baseApiUrl}/zonal-details/eligible/parent-zones?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllEligibleSubZoneByParentId(parentZoneId: string): Promise<{ data: subZonalDetail[] }> {
    const searchParams = new URLSearchParams();
    if (parentZoneId) {
      searchParams.set('parentZoneId', parentZoneId);
    }
    const apiPath = `${this.baseApiUrl}/zonal-details/eligible/sub-zones?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllEligibleSubZone(): Promise<{ data: subZonalDetail[] }> {
    const apiPath = `${this.baseApiUrl}/zonal-details/eligible/sub-zones`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getParentZoneByLocation(
    longitude: any,
    latitude: any,
  ): Promise<{ data: ParentZonalDetail }> {
    const searchParams = new URLSearchParams();
    if (longitude) {
      searchParams.set('long', String(longitude));
    }
    if (latitude) {
      searchParams.set('lat', String(latitude));
    }
    const apiPath = `${this.adminBaseApiUrl}/zonal-details/parent-zone?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async create(payload: CreateSubZone) {
    const apiPath = `${this.servicePath}sub-zone`;
    return this.httpPost(apiPath, payload);
  }

  async createParentZone(payload: CreateParentZone) {
    const apiPath = `${this.servicePath}parent-zone`;
    return this.httpPost(apiPath, payload);
  }

  async delete(id: string) {
    const apiPath = `${this.servicePath}sub-zone/${id}`;
    return this.httpDelete(apiPath);
  }

  async deleteParentZone(id: string) {
    const apiPath = `${this.servicePath}parent-zone/${id}`;
    return this.httpDelete(apiPath);
  }

  async updateParentZone(id: string, payload: Partial<CreateSubZone>) {
    const apiPath = `${this.servicePath}parent-zone/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async update(id: string, payload: Partial<CreateSubZone>) {
    const apiPath = `${this.servicePath}sub-zone/${id}`;
    return this.httpPut(apiPath, payload);
  }

  async getTimeSlotList()
    : Promise<{ data: IDeliverySlot[] }> {
    const apiPath = `${this.adminBaseApiUrl}/time-slots/parent-zone/eligible-active-list`;
    return this.httpGet(apiPath);
  }
}
