/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  DELAY_NOTIFICATION_EVENT_TYPE, OrderCategoryEnum, PaymentStatusEnum, PurchaseOrderStatuses,
} from '../../enums/order.enum';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { ILocation, IProductDetails } from './order-api.service';

export interface IRating {
  createdAt: string;
  orderId: string;
  rating: number;
  ratingId: string;
  review: string;
  revieweeId: string;
  revieweeType: 'kitchen' | 'rider' | 'item' | '3pl-rider';
  reviewerId: string;
  reviewerType: 'customer' | 'rider';
  updatedAt: string;
}

export interface IListOfOrdersToCheck {
  purchaseOrderId: string;
  customerId: string;
  isFirstTimeCustomer?: boolean;
}

export interface IListOfProductOrdersToCheck {
  simplifiedOrderId: string;
  customerId: string;
  isFirstTimeCustomer?: boolean;
}

export interface ILocationCoordinates {
  coordinates: number[];
  type: string;
}

export interface IItemDetails {
  itemId: string;
  itemCategory: string;
  itemName: string;
  quantity: number;
  price: number;
}

export interface ICouponDetails {
  _id: string;
  termsAndCondition: string;
  detailedDescription: string;
  couponId: string;
  couponCode: string;
  discountType: string;
  discount: number;
  savingsOnDiscount: number;
  couponName: string,
  description: string;
  isActive: boolean,
  constraints: [
    {
      maxRedemption: number;
    }
  ],
}

export interface IPurchaseOrderItemDetails {
  itemId: string;
  quantity: number;
  price: number;
  itemName: string;
  itemCategory: string;
  foodType: string;
}

export interface ICreatedOrUpdatedBy {
  entityId: string;
  entityName: string;
  entityType: number;
}

export interface IDeliveryTimeSlotDateAndTime {
  date: Date;
  time: Date;
  timeSlotType?: string;
}

export interface IFirstTimeOrderPayload {
  purchaseOrderId: string;
  customerId: string;
}

export interface IFirstTimeProductOrderPayload {
  simplifiedOrderId: string;
  customerId: string;
}

export interface IPurchaseOrder {
  createdAt: string;
  purchaseOrderId: string;
  referenceId: string;
  billDetails?: {
    couponId: string;
  };
  customerId: string;
  customerName: string;
  phoneNumber: string;
  orderStatus: PurchaseOrderStatuses | PaymentStatusEnum;
  deliveryTimeSlot: IDeliveryTimeSlotDateAndTime;
  orderType: string;
  mealTime: string;
  itemDetails: Record<string, Record<string, IPurchaseOrderItemDetails>>;
  paymentId?: string;
  paymentStatus?: string;
  createdBy: ICreatedOrUpdatedBy;
  amount: number;
  mode: string;
  couponId: string;
  isOrderNoteExists: boolean;
  isProductExists: boolean;
  kitchenName: string;
  customerRatings: IRating[];
  productRating: IRating[];
  _id?: string;
  cancellationReason?: string;
  subZoneId: string;
  parentZoneId: string;
  class?: string;
  ratingStarAssetLink?: string;
  orderStatusLabel?: string;
  isFirstTimeCustomer?: boolean;
  preOrder?: boolean;
  isSupportRequired?: boolean;
  simplifiedOrderId?: string;
  relatedOrders: IPurchaseOrder[];
  productType?: string;
  relatedOrderCount: number;
  orderCategory?: OrderCategoryEnum;
  satelliteStoreId?: string;
  isFoodReady?: boolean;
  isCouponInitiated?: boolean;
  delayNotificationLogs: Array<INotificationLog>;
  isChatSupportEnabled?: boolean;
  updatedAt: string;
  productDetails?: Array<IProductDetails>;
  address?: IAddress;
}

export interface IPurchaseOrderDetails {
  data: {
    orders: IPurchaseOrder[],
    totalOrders: number,
  };
}

interface IRelatedOrders {
  data: IPurchaseOrder[];
}

export interface IEntity {
  entityName: string;
  entityType: string;
  entityId: string;
}

export interface INotificationLog {
  delayNotificationLogId: string;
  callMadeToCustomer: boolean;
  customerResponse: string;
  eventType: DELAY_NOTIFICATION_EVENT_TYPE;
  notificationMessageByTemplate?: string;
  notificationMessage?: string;
  couponName?: string;
  referenceId: string;
  remarks: string;
  createdAt: string;
  createdBy: IEntity;
  updatedAt: string;
}

export interface IAddress {
  addressType: string;
  houseNo: string;
  landmark: string;
  locality: string;
  location: ILocation;
  mapAddress: string;
  postalCode: string;
  street: string;
}

export interface IGroupedOrders {
  _id: string;
  simplifiedOrderId: string;
  customerId: string;
  orderedDateTime: string;
  deliveryTimeSlotDate: Array<string>;
  referenceId: Array<string>;
  purchaseOrderId: Array<string>;
  satelliteStoreId: Array<string>;
  orderStatus: PurchaseOrderStatuses;
  productType: Array<string>;
  paymentId: string;
  parentZoneId: string;
  subZoneId: string;
  orderCategory: OrderCategoryEnum;
  createdAt: string;
  updatedAt: string;
  __v: number;
  phoneNumber: string;
  customerName: string;
  paymentStatus: string;
  status: string;
  mode: string;
  billDetails: {
    couponId: string;
  };
  amount: number;
  isRatingExists: boolean;
  orderStatusLabel?: string;
  isFirstTimeCustomer: boolean;
}

export interface IGroupedOrderDetails {
  data: {
    orders: Array<IGroupedOrders>;
    ordersCount: number;
  };
}

export interface IRelatedOrderDetails {
  data: {
    orders: Array<IPurchaseOrder>;
  };
}

@Injectable()
export class PurchaseOrderApiService extends RestApiService {
  // TODO: update this end point
  protected override baseApiUrl = environment.config['CUSTOMER_SERVICE_URL'];

  private servicePath = `${this.baseApiUrl}/orders/purchase-details`;

  private groupedOrderServicePath = `${this.baseApiUrl}/grouped-orders`;

  private relatedOrderServicePath = `${this.baseApiUrl}/orders/simplified`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getPurchaseOrders(queryParams?: Record<any, (string | number)>): Promise<IPurchaseOrderDetails> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['adminOrders'] !== undefined) {
        searchParams.set('adminOrders', String(queryParams['adminOrders']));
      }
      if (queryParams['orderCategory'] !== undefined) {
        if (Array.isArray(queryParams['orderCategory'])) {
          queryParams['orderCategory'].forEach((item) => {
            searchParams.append('orderCategory', item);
          });
        } else {
          searchParams.set('orderCategory', String(queryParams['orderCategory']));
        }
      }
      if (queryParams['cancellationReasonId']) {
        searchParams.set('cancellationReasonId', String(queryParams['cancellationReasonId']));
      }
      if (queryParams['orderChannel']) {
        searchParams.set('orderChannel', String(queryParams['orderChannel']));
      }
      if (queryParams['orderTabType']) {
        searchParams.set('orderTabType', String(queryParams['orderTabType']));
      }
    }

    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllGroupedOrders(queryParams?: Record<any, (string | number)>): Promise<IGroupedOrderDetails> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['orderCategory'] !== undefined) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (queryParams['productType'] !== undefined) {
        searchParams.set('productType', String(queryParams['productType']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['adminOrders'] !== undefined) {
        searchParams.set('adminOrders', String(queryParams['adminOrders']));
      }
      if (queryParams['cancellationReasonId']) {
        searchParams.set('cancellationReasonId', String(queryParams['cancellationReasonId']));
      }
      if (queryParams['orderChannel']) {
        searchParams.set('orderChannel', String(queryParams['orderChannel']));
      }
      // if (queryParams['orderTabType']) {
      //   searchParams.set('orderTabType', String(queryParams['orderTabType']));
      // }
    }

    const apiPath = `${this.groupedOrderServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getRelatedOrders(simplifiedOrderId: string): Promise<IRelatedOrderDetails> {
    const apiPath = `${this.relatedOrderServicePath}/${simplifiedOrderId}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async customerFirstOrder(payload: { listOfOrdersToCheck: IListOfOrdersToCheck[]; }): Promise<{ data: IListOfOrdersToCheck[]; }> {
    const apiPath = `${this.baseApiUrl}/common/orders/customers-first-order`;
    return this.httpPost(apiPath, payload);
  }

  async customerFirstProductOrder(payload: { listOfGroupedOrders: IListOfProductOrdersToCheck[]; }): Promise<{ data: IListOfProductOrdersToCheck[]; }> {
    const apiPath = `${this.baseApiUrl}/common/orders/customers-first-order`;
    return this.httpPost(apiPath, payload);
  }

  async updatePurchaseOrderCancelStatus(referenceId: string, cancellationReason: string, cancellationReasonId?: string) {
    const payload = {
      cancellationReason,
      cancellationReasonId,
    };
    const apiPath = `${this.baseApiUrl}/common/orders/${referenceId}/cancel`;
    return this.httpPatch(apiPath, payload);
  }

  async updateProductOrderCancelStatus(simplifiedOrderId: string, cancellationReason: string, cancellationReasonId?: string) {
    const payload = {
      cancellationReason,
      cancellationReasonId,
    };
    const apiPath = `${this.baseApiUrl}/common/orders/simplified/${simplifiedOrderId}/cancel`;
    return this.httpPatch(apiPath, payload);
  }

  // async getRelatedOrders(simplifiedOrderId: string, referenceId: string): Promise<IRelatedOrders | null> {
  //   if (simplifiedOrderId && referenceId) {
  //     const apiPath = `${this.baseApiUrl}/common/orders/simplified/${simplifiedOrderId}/reference/${referenceId}`;
  //     return this.httpGet(apiPath, {
  //       preferLatestCall: true,
  //     });
  //   }
  //   console.log('Unable to fetch the related orders');
  //   return null;
  // }
}
