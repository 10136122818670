import { from } from 'rxjs';
import {
  FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule,
} from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';
import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuid } from 'uuid';
import { FileHandle } from '../../../../directives/drop-zone/drop-zone.directive';
import { S3Service } from '../../../../services/upload.service';
import { generateFileHandles } from '../../../../directives/drop-zone/drop-zone-helpers';
import { SequenceConfirmationModalService } from '../sequence-conformation-modal/sequence-conformation-modal.service';
import { ParentZonalDetail, subZonalDetail, ZoneApiService } from '../../../../services/api/zone-api.service';
import { CuisineApiService, ICuisine } from '../../../../services/api/cuisine-api.service';
import {
  CheckboxGroupControl, CheckboxRequiredValidator, generateCheckboxGroupControl, IChoice,
} from '../../../../helpers/form.helpers';
import { ModalRef } from '../../../../lib/modal/modal-ref';
import { ModalConfig } from '../../../../lib/modal/modal-config';
import {
  ISectionCard, ConfigApiService, ISectionCardQuery, ISectionCardPayload,
} from '../../../../services/api/config.service';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { ModalService } from '../../../../lib/modal/modal.service';
import { InitialService } from '../../../../services/initial.service';
import { MenuCategoriesType, MENU_CATEGORIES } from '../../../../constants/menu.constants';
import { ZoneTypeEnum } from '../../../customer-admin/banner-management/scrollable-banner/scrollable-banner.component';
import { CategoryService, ICategoryDetails } from '../../../../services/api/category.service';
import { IBrands, ISubCategoryDetailsResponse, SubCategoryService } from '../../../../services/api/sub-category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ITagMasters } from '../../../../constants/tag-master.constants';

enum ManipulationType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const queryChoice: IChoice[] = [
  {
    name: 'Nearby Kitchens',
    value: 'nearby',
  },
  {
    name: 'New Kitchens',
    value: 'new',
  }, {
    name: 'Kitchens Cuisine type',
    value: 'cuisine',
  }, {
    name: 'Get It Immediately',
    value: 'isGetItImmediatelySection',
  },
];

export const sequenceChoice: IChoice[] = [
  {
    name: 'First',
    value: 'first',
  },
  {
    name: 'Last',
    value: 'last',
  },
];

const itemChoiceLabelMap = {
  nearby: 'Nearby Items',
  new: 'New Items',
  cuisine: 'Items Cuisine type',
  isGetItImmediatelySection: 'Get It Immmediately',
  // ['seller-product-category']: 'Product Category',
  // ['seller-product-sub-category']: 'Product Subcategory',
  // ['seller-brand']: 'Brand',
};

const dropdownSetting = {
  singleSelection: false,
  text: 'Select Cuisine *',
  labelKey: 'cuisineName',
  enableCheckAll: false,
  enableSearchFilter: true,
  tagToBody: false,
  primaryKey: '_id',
};

const mealTimeDropdownSetting = {
  singleSelection: false,
  text: 'Select Preferred MealTime',
  labelKey: 'value',
  enableCheckAll: false,
  enableSearchFilter: false,
  tagToBody: false,
  escapeToClose: true,
  primaryKey: 'id',
};

const UPLOAD_TYPE = ['image/png', 'image/jpg', 'image/jpeg'];

const defaultPaginationConfig = {
  skip: 0,
  limit: 10,
};

@Component({
  selector: 'app-add-section-card-modal',
  templateUrl: './add-section-card-modal.component.html',
  styleUrls: ['./add-section-card-modal.component.scss'],
})

export class AddSectionCardModalComponent {
  form!: FormGroup;

  manipulationType = ManipulationType.ADD;

  manipulationTypes = ManipulationType;

  public queryGroup!: CheckboxGroupControl;

  public parentZonalDetails: ParentZonalDetail[] = [];

  public selectedParentZoneId: string | null = null;

  public selectedSubZoneId: string | null = null;

  public subZoneDetails: subZonalDetail[] | [] = [];

  public itemChoiceLabelMap = itemChoiceLabelMap;

  formSubmitted = false;

  zoneType = ZoneTypeEnum;

  template!: ISectionCard;

  public dropDownSettings = dropdownSetting;

  public cuisineList: ICuisine[] = [];

  public cardBgImage: FileHandle | null = null;

  public mealTimeList = MENU_CATEGORIES;

  public mealTimeDropdownSetting = mealTimeDropdownSetting;

  public cardBgImageLg: FileHandle | null = null;

  public skipCategoryList: number = 0;

  public allCategories: Array<ICategoryDetails> = [];

  public skipSubCategoryList: number = 0;

  public allSubCategories: Array<ISubCategoryDetailsResponse> = [];

  public skipTagsList: number = 0;

  public allTags: Array<ITagMasters> = [];

  public allBrands: Array<IBrands> = [];

  public templateType = '';

  constructor(
    public modalConfig: ModalConfig,
    private modalService: ModalService,
    private toast: HotToastService,
    private zoneApiService: ZoneApiService,
    private cuisineService: CuisineApiService,
    private configService: ConfigApiService,
    public modalRef: ModalRef,
    public sequenceConformationModalService: SequenceConfirmationModalService,
    private readonly sanitizer: DomSanitizer,
    private readonly s3Service: S3Service,
    private readonly initialService: InitialService,
    private readonly categoryService: CategoryService,
    private readonly subCategoryService: SubCategoryService,
    private ngModalService: NgbModal,
  ) {
    this.createForm();
  }

  removeUploadedFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.cardBgImage = null;
  }

  removeLgUploadedFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.cardBgImageLg = null;
  }

  updateImage(file: File) {
    const fileList = generateFileHandles([file] as any, this.sanitizer);
    this.onImageDrop(fileList);
  }

  updateLgImage(file: File) {
    const fileList = generateFileHandles([file] as any, this.sanitizer);
    this.onLgImageDrop(fileList);
  }

  onImageUpload(event: Event) {
    event.stopPropagation();
    const { files } = event.target as any;
    const fileList = generateFileHandles(files, this.sanitizer);
    this.onImageDrop(fileList);
  }

  onLgImageUpload(event: Event) {
    event.stopPropagation();
    const { files } = event.target as any;
    const fileList = generateFileHandles(files, this.sanitizer);
    this.onLgImageDrop(fileList);
  }

  validateFile(file: File) {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if ((width / height).toFixed(2) !== (18 / 11).toFixed(2)) {
          this.toast.error('Please upload an image with ratio 18:11');
          resolve(false);
          return;
        }
        resolve(true);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        return reject;
      };
    });
    img.src = objectUrl;
    return promise;
  }

  validateLgFile(file: File) {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if ((width / height).toFixed(2) !== (18 / 23).toFixed(2)) {
          this.toast.error('Please upload an image with ratio 18:23');
          resolve(false);
          return;
        }
        resolve(true);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        return reject;
      };
    });
    img.src = objectUrl;
    return promise;
  }

  async onImageDrop(fileHandles: FileHandle[]) {
    if (fileHandles.length) {
      this.cardBgImage = null;
      let fileExtension = '';
      fileExtension = fileHandles[0].file.name.split('.').pop() || '';
      if (await this.validateFile(fileHandles[0].file)) {
        if ((fileHandles[0].file.size / 1024) > 256) {
          this.toast.error('Upload less than or equal to 256kb!');
          this.cardBgImage = null;
        } else {
          this.cardBgImage = { ...fileHandles[0] };
        }
      }
    }
  }

  async onLgImageDrop(fileHandles: FileHandle[]) {
    if (fileHandles.length) {
      this.cardBgImageLg = null;
      let fileExtension = '';
      fileExtension = fileHandles[0].file.name.split('.').pop() || '';
      if (await this.validateLgFile(fileHandles[0].file)) {
        if ((fileHandles[0].file.size / 1024) > 256) {
          this.toast.error('Upload less than or equal to 256kb!');
          this.cardBgImageLg = null;
        } else {
          this.cardBgImageLg = { ...fileHandles[0] };
        }
      }
    }
  }

  async ngOnInit() {
    this.templateType = this.modalConfig.data.templateType;
    const eligibleParentZoneIds = await this.initialService.getElgibleParentZones();
    if (Array.isArray(eligibleParentZoneIds)) {
      const responses = await this.zoneApiService.getAllEligibleParentZone();
      this.parentZonalDetails = responses.data.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    } else {
      const responses: Array<ParentZonalDetail> = [];
      responses.push(...(await this.zoneApiService.getAllParentZone()).data);
      this.parentZonalDetails = responses.sort(
        (a, b) => a.parentZoneName.localeCompare(b.parentZoneName),
      );
    }
    this.cuisineList = (await this.cuisineService.getAll()).data;
    if (this.modalConfig.data.manipulationType) {
      this.manipulationType = this.modalConfig.data.manipulationType;
      if (this.templateType === 'food') {

      } else {
        this.form.patchValue({
          type: this.templateType,
        })
      }
    }
    if (this.manipulationType === ManipulationType.EDIT && this.templateType === 'food') {
      if (this.modalConfig.data.template) {
        const { template }: { template: ISectionCard } = this.modalConfig.data;
        if (template.parentZoneId !== this.zoneType.ALL_PARENT_ZONE) {
          const response = await this.zoneApiService
            .getAllSubzonesForParent(template.parentZoneId);
          this.subZoneDetails = response.data.sort(
            (a, b) => a.subZoneName.localeCompare(b.subZoneName),
          );
        }
        const selectedCuisines = this.cuisineList
          .map((cuisine) => cuisine.cuisineName)
          .filter((cuisineValue) => template.query.cuisine?.includes(cuisineValue));
        const selectedMealTime = this.mealTimeList
          .filter((mealTime) => template.preferredMealTime?.includes(mealTime.value));
        this.form.patchValue({
          name: template.name || '',
          title: template.title || '',
          description: template.description || '',
          type: template.type || '',
          parentZoneId: template.parentZoneId,
          subZoneId: template.subZoneId,
          isActive: template.isActive,
          cuisine: selectedCuisines || null,
          nearBy: template.query.nearBy || 5,
          requiredLimit: template.requiredLimit || 4,
          new: template.query.new || null,
          restrictForPureVeg: template.isPureVegCompatible !== undefined
            ? !template.isPureVegCompatible : false,
          preferredMealTime: selectedMealTime,
          isGetItImmediatelySection: template.query.isGetItImmediatelySection,
        });
        if (template.cardBgImageURL) {
          fetch(template.cardBgImageURL)
            .then(async (response1) => {
              const data = await response1.blob();
              const file = new File([data], template?.cardBgImage!, {
                type: data.type,
              });
              this.updateImage(file);
            });
        } else {
          this.cardBgImage = null;
        }
        if (template.cardBgImageLgURL) {
          fetch(template.cardBgImageLgURL)
            .then(async (response2) => {
              const data = await response2.blob();
              const file = new File([data], template?.cardBgImageLg!, {
                type: data.type,
              });
              this.updateLgImage(file);
            });
        } else {
          this.cardBgImageLg = null;
        }
        let selectedQuery;
        let cuisine: ICuisine[] = [];
        this.form.controls['parentZoneId'].disable();
        this.form.controls['subZoneId'].disable();
        if (template.query.nearBy) {
          selectedQuery = 'nearby';
          this.form.value.nearBy = template.query.nearBy;
        } else if (template.query.new) {
          selectedQuery = 'new';
          this.form.value.new = template.query.new;
        } else if (template.query.cuisine?.length) {
          selectedQuery = 'cuisine';
          if (!this.form.value.cuisine[0].cuisineName) {
            this.form.value.cuisine.forEach((i: string) => {
              const match = this.cuisineList.find((j: ICuisine) => j.cuisineName === i);
              cuisine.push(match!);
            });
          } else {
            cuisine = this.form.value.cuisine;
          }
        } else if (template.query.isGetItImmediatelySection) {
          selectedQuery = 'isGetItImmediatelySection';
          this.form.value.isGetItImmediatelySection = template.query.isGetItImmediatelySection;
        }
        this.form.patchValue({
          selectQuery: selectedQuery,
          cuisine,
        });
        if (this.modalConfig.data.template.query.length) {
          const constraint: ISectionCardQuery = this.modalConfig.data.template.query[0];
          this.form.patchValue({
            nearBy: constraint.nearBy,
            new: constraint.new,
            cuisine: constraint.cuisine,
            isGetItImmediatelySection: constraint.isGetItImmediatelySection,
          });
        }
      }
    }
    if (this.manipulationType === ManipulationType.EDIT && this.templateType === 'sellerProducts') {
      if (this.modalConfig.data.template) {
        const { template } = this.modalConfig.data;
        if (template.parentZoneId !== this.zoneType.ALL_PARENT_ZONE) {
          const response = await this.zoneApiService
            .getAllSubzonesForParent(template.parentZoneId);
          this.subZoneDetails = response.data.sort(
            (a, b) => a.subZoneName.localeCompare(b.subZoneName),
          );
        }
        this.form.patchValue({
          name: template.name || '',
          title: template.title || '',
          description: template.description || '',
          type: template.type || '',
          parentZoneId: template.parentZoneId,
          subZoneId: template.subZoneId,
          isActive: template.isActive,
          cuisine: undefined,
          nearBy: template.query.nearBy || 5,
          requiredLimit: template.requiredLimit || 4,
          new: template.query.new || null,
          restrictForPureVeg: undefined,
          preferredMealTime: undefined,
          isGetItImmediatelySection: template.query.isGetItImmediatelySection,
        });
        // let selectedQuery;
        if (template.query.sellerProductCategory) {
          this.form.controls['selectQuery'].setValue('seller-product-category');
          if (template.entityDetails && Array.isArray(template.entityDetails)) {
            this.allCategories = template.entityDetails;
            this.skipCategoryList = 0;
            this.fetchCategories();
          }
          this.form.controls['sellerProductCategory'].setValue(template.query.sellerProductCategory);
          this.form.controls['sellerProductCategory'].setValidators([Validators.required]);
          this.form.controls['sellerProductCategory'].updateValueAndValidity();
        }
        if (template.query.sellerProductSubCategory) {
          this.form.controls['selectQuery'].setValue('seller-product-sub-category');
          if (template.entityDetails && Array.isArray(template.entityDetails)) {
            this.allSubCategories = template.entityDetails;
            this.skipSubCategoryList = 0;
            this.fetchSubCategories();
          }
          this.form.controls['sellerProductSubCategory'].setValue(template.query.sellerProductSubCategory);
          this.form.controls['sellerProductSubCategory'].setValidators([Validators.required]);
          this.form.controls['sellerProductSubCategory'].updateValueAndValidity();
        }
        if (template.query.sellerProductBrand) {
          this.form.controls['selectQuery'].setValue('seller-brand');
          await this.fetchBrands();
          this.form.controls['sellerProductBrand'].setValue(template.query.sellerProductBrand);
          this.form.controls['sellerProductBrand'].setValidators([Validators.required]);
          this.form.controls['sellerProductBrand'].updateValueAndValidity();
        }
        if (template.query.productTags && Array.isArray(template.query.productTags)) {
          this.form.controls['selectQuery'].setValue('product-tags');
          if (template.tagsWithNames && Array.isArray(template.tagsWithNames)) {
            this.allTags = template.tagsWithNames;
            this.skipTagsList = 0;
            this.fetchTags();
          }
          this.form.controls['productTags'].setValue(template.query.productTags[0]);
          this.form.controls['productTags'].setValidators([Validators.required]);
          this.form.controls['productTags'].updateValueAndValidity();
        }
        if (template.cardBgImageURL) {
          fetch(template.cardBgImageURL)
            .then(async (response1) => {
              const data = await response1.blob();
              const file = new File([data], template?.cardBgImage!, {
                type: data.type,
              });
              this.updateImage(file);
            });
        } else {
          this.cardBgImage = null;
        }
        if (template.cardBgImageLgURL) {
          fetch(template.cardBgImageLgURL)
            .then(async (response2) => {
              const data = await response2.blob();
              const file = new File([data], template?.cardBgImageLg!, {
                type: data.type,
              });
              this.updateLgImage(file);
            });
        } else {
          this.cardBgImageLg = null;
        }
      }
    }
  }

  createForm() {
    const queryGroup = generateCheckboxGroupControl(
      'queryGroup',
      queryChoice,
      [new CheckboxRequiredValidator().validate],
    );
    this.queryGroup = cloneDeep(queryGroup);
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      parentZoneId: new FormControl('', [Validators.required]),
      subZoneId: new FormControl('', [Validators.required]),
      isActive: new FormControl('', [Validators.required]),
      selectQuery: new FormControl('', [Validators.required]),
      new: new FormControl(undefined),
      cuisine: new FormControl(undefined),
      nearBy: new FormControl(5, undefined),
      requiredLimit: new FormControl(4, undefined),
      restrictForPureVeg: new FormControl(false),
      image: new FormControl(null),
      preferredMealTime: new FormControl([]),
      isGetItImmediatelySection: new FormControl(undefined),
      sellerProductCategory: new FormControl(undefined),
      sellerProductSubCategory: new FormControl(undefined),
      sellerProductBrand: new FormControl(undefined),
      productTags: new FormControl(undefined),
    });
  }

  onFileSelect(event: any) {
    if (event.target.files) {
      const file = generateFileHandles(event.target.files, this.sanitizer);
      // eslint-disable-next-line prefer-destructuring
      this.cardBgImage = file[0];
      if ((this.cardBgImage.file.size / 1024) > 256) {
        this.toast.error('Upload a image less than or equal to 256kb!');
        this.cardBgImage = null;
      }
    }
  }

  previewUploadedFile(event: Event, data: any) {
    event.stopPropagation();
    if (UPLOAD_TYPE.includes(data.file.type)) {
      this.modalConfig.data = data.url;
      // this.modalService.open(PreviewModalComponent);
      const modalRef = this.ngModalService.open(ImagePreviewComponent, {
        centered: true,
        modalDialogClass: 'border-0 shadow-none',
        size: 'sm',
        fullscreen: 'sm',
        scrollable: true,
      });
      modalRef.componentInstance.imageUrl = data.url;
      modalRef.componentInstance.close.subscribe(() => {
        modalRef.close();
      });
    } else {
      window.open(data.url.changingThisBreaksApplicationSecurity, '_blank');
    }
  }

  onLgFileSelect(event: any) {
    if (event.target.files) {
      const file = generateFileHandles(event.target.files, this.sanitizer);
      // eslint-disable-next-line prefer-destructuring
      this.cardBgImageLg = file[0];
      if ((this.cardBgImageLg.file.size / 1024) > 256) {
        this.toast.error('Upload a image less than or equal to 256kb!');
        this.cardBgImageLg = null;
      }
    }
  }

  previewLgUploadedFile(event: Event, data: any) {
    event.stopPropagation();
    if (UPLOAD_TYPE.includes(data.file.type)) {
      this.modalConfig.data = data.url;
      // this.modalService.open(PreviewModalComponent);
      const modalRef = this.ngModalService.open(ImagePreviewComponent, {
        centered: true,
        modalDialogClass: 'border-0 shadow-none',
        size: 'sm',
        fullscreen: 'sm',
        scrollable: true,
      });
      modalRef.componentInstance.imageUrl = data.url;
      modalRef.componentInstance.close.subscribe(() => {
        modalRef.close();
      });
    } else {
      window.open(data.url.changingThisBreaksApplicationSecurity, '_blank');
    }
  }

  onParentzoneInputSelect(event: Event) {
    this.selectedParentZoneId = (event.target as HTMLInputElement).value;
    if (this.selectedParentZoneId !== this.zoneType.ALL_PARENT_ZONE) {
      this.getAllSubzonesForParent(this.selectedParentZoneId);
    } else {
      this.subZoneDetails = [];
    }
    this.form.patchValue({
      subZoneId: this.zoneType.ALL_SUB_ZONE,
    });
  }

  async getAllSubzonesForParent(id: string) {
    const subZoneResponse = (await this.zoneApiService.getAllSubzonesForParent(id)).data;
    this.subZoneDetails = subZoneResponse.sort(
      (a, b) => a.subZoneName.localeCompare(b.subZoneName),
    );
  }

  async save() {
    if (this.form.invalid) {
      this.formSubmitted = true;
      this.toast.error('Please Complete all Mandatory Fields');
      return;
    }
    const formValues = this.form.getRawValue();
    let fileName = '';
    let fileExtension = '';
    if (this.cardBgImage) {
      if (this.cardBgImage?.file) {
        fileExtension = this.cardBgImage?.file.name.split('.').pop() || '';
        fileName = `section-card-${uuid()}.${fileExtension}`;
        await this.s3Service.upload(this.cardBgImage.file, {
          fileName,
          contentType: this.cardBgImage.file.type,
        });
      }
    }
    let lgFileName = '';
    let lgFileExtension = '';
    if (this.cardBgImageLg) {
      if (this.cardBgImageLg?.file) {
        lgFileExtension = this.cardBgImageLg?.file.name.split('.').pop() || '';
        lgFileName = `section-card-${uuid()}.${lgFileExtension}`;
        await this.s3Service.upload(this.cardBgImageLg.file, {
          fileName: lgFileName,
          contentType: this.cardBgImageLg.file.type,
        });
      }
    }
    if (this.templateType === 'food') {
      const payload: ISectionCardPayload = {
        query: {},
        name: formValues.name,
        title: formValues.title,
        description: formValues.description,
        parentZoneId: formValues.parentZoneId,
        subZoneId: formValues.subZoneId,
        type: formValues.type,
        requiredLimit: formValues.requiredLimit || 4,
        isActive: formValues.isActive,
        isPureVegCompatible: !formValues.restrictForPureVeg,
        cardBgImage: fileName,
        cardBgImageLg: lgFileName,
        preferredMealTime: Array.isArray(formValues.preferredMealTime)
          && formValues.preferredMealTime.length > 0
          ? formValues.preferredMealTime
            .map((mealTime: MenuCategoriesType) => mealTime.value) : [],
      };
      const query: ISectionCardQuery = {};
      if (![undefined, null].includes(formValues.nearBy) && formValues.selectQuery === 'nearby') {
        query.nearBy = formValues.nearBy;
      } else if (formValues.selectQuery === 'new') {
        query.new = true;
      } else if (![undefined, null].includes(formValues.cuisine) && formValues.selectQuery === 'cuisine') {
        const cuisine = formValues.cuisine.map((i: any) => i.cuisineName);
        if (cuisine.length) {
          query.cuisine = cuisine;
        } else if (formValues.cuisine?.length) {
          query.cuisine = formValues.cuisine;
        } else {
          this.formSubmitted = true;
          this.toast.error(`Please fill the ${formValues.selectQuery}`);
          return;
        }
      } else if (formValues.selectQuery === 'isGetItImmediatelySection') {
        query.isGetItImmediatelySection = true;
      }
      if (Object.keys(query).length) {
        payload.query = query;
      }
      if (!Object.keys(query).length) {
        this.formSubmitted = true;
        this.toast.error(`Please fill the ${formValues.selectQuery}`);
        return;
      }
      if (formValues.selectQuery === 'isGetItImmediatelySection' && (!this.cardBgImageLg || !this.cardBgImage)) {
        this.formSubmitted = true;
        this.toast.error('Please upload the images!');
        return;
      }
      let template;
      if (this.manipulationType === ManipulationType.ADD) {
        const confirmation = await this.sequenceConformationModalService
          .showConfirmationPopup(sequenceChoice);
        if (!confirmation) {
          this.toast.error('Please Select Section Sequence');
          return;
        }
        const promise = this.configService.createSectionCardConfig(payload, confirmation);
        from(promise).pipe(
          this.toast.observe({
            success: 'Template created successfully',
            loading: 'Creating Template',
            error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
          }),
        ).subscribe();
        template = await promise;
        this.modalRef.dataEmit(template.data);
      } else {
        const promise = this.configService
          .updateSectionCardConfig(this.modalConfig.data.template.templateId, payload);
        from(promise).pipe(
          this.toast.observe({
            success: 'Template updated successfully',
            loading: 'Updated Template',
            error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
          }),
        ).subscribe();
        template = await promise;
        this.modalRef.dataEmit({
          ...this.modalConfig.data.template,
          ...template.data,
          cardBgImageURL: template.data.cardBgImageURL || '',
          cardBgImageLgURL: template.data.cardBgImageLgURL || '',
        });
      }
      this.modalRef.close();
    }

    if (this.templateType === 'sellerProducts') {
      const payload = {
        query: {
          sellerProductCategory: formValues.sellerProductCategory && formValues.sellerProductCategory?.length ? formValues.sellerProductCategory : undefined,
          sellerProductSubCategory: formValues.sellerProductSubCategory && formValues.sellerProductSubCategory?.length ? formValues.sellerProductSubCategory : undefined,
          sellerProductBrand: formValues.sellerProductBrand && formValues.sellerProductBrand?.length ? formValues.sellerProductBrand : undefined,
          productTags: formValues.productTags ? [formValues.productTags] : undefined,
        },
        name: formValues.name,
        title: formValues.title,
        description: formValues.description,
        parentZoneId: formValues.parentZoneId,
        subZoneId: formValues.subZoneId,
        type: formValues.type,
        requiredLimit: formValues.requiredLimit || 4,
        isActive: formValues.isActive,
        cardBgImage: fileName,
        cardBgImageLg: lgFileName,
      }
      let template;
      if (this.manipulationType === ManipulationType.ADD) {
        const confirmation = await this.sequenceConformationModalService
          .showConfirmationPopup(sequenceChoice);
        if (!confirmation) {
          this.toast.error('Please Select Section Sequence');
          return;
        }
        const promise = this.configService.createSectionCardConfig(payload, confirmation);
        from(promise).pipe(
          this.toast.observe({
            success: 'Template created successfully',
            loading: 'Creating Template',
            error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
          }),
        ).subscribe();
        template = await promise;
        this.modalRef.dataEmit(template.data);
      } else {
        const promise = this.configService
          .updateSectionCardConfig(this.modalConfig.data.template.templateId, payload);
        from(promise).pipe(
          this.toast.observe({
            success: 'Template updated successfully',
            loading: 'Updated Template',
            error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
          }),
        ).subscribe();
        template = await promise;
        this.modalRef.dataEmit({
          ...this.modalConfig.data.template,
          ...template.data,
          cardBgImageURL: template.data.cardBgImageURL || '',
          cardBgImageLgURL: template.data.cardBgImageLgURL || '',
        });
      }
      this.modalRef.close();
    }
  }

  async handleChangeCategory(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.skipCategoryList = 0;
      this.allCategories = [];
      this.skipSubCategoryList = 0;
      this.allSubCategories = [];
      this.form.controls['sellerProductCategory'].setValue([]);
      this.form.controls['sellerProductSubCategory'].setValue([]);
      this.form.controls['sellerProductBrand'].setValue([])
      this.form.controls['productTags'].setValue('')
      await this.fetchCategories();
      this.form.controls['sellerProductCategory'].setValidators([Validators.required]);
      this.form.controls['sellerProductCategory'].updateValueAndValidity();
      this.form.controls['sellerProductSubCategory'].clearValidators();
      this.form.controls['sellerProductSubCategory'].updateValueAndValidity();
      this.form.controls['sellerProductBrand'].clearValidators();
      this.form.controls['sellerProductBrand'].updateValueAndValidity();
      this.form.controls['productTags'].clearValidators();
      this.form.controls['productTags'].updateValueAndValidity();
    }
  }
  async handleChangeSubCategory(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.skipCategoryList = 0;
      this.allCategories = [];
      this.skipSubCategoryList = 0;
      this.allSubCategories = [];
      this.allBrands = [];
      this.skipTagsList = 0;
      this.allTags = [];
      this.form.controls['sellerProductCategory'].setValue([])
      this.form.controls['sellerProductSubCategory'].setValue([])
      this.form.controls['sellerProductBrand'].setValue([])
      this.form.controls['productTags'].setValue('')
      await this.fetchSubCategories();
      this.form.controls['sellerProductSubCategory'].setValidators([Validators.required]);
      this.form.controls['sellerProductSubCategory'].updateValueAndValidity();
      this.form.controls['sellerProductCategory'].clearValidators();
      this.form.controls['sellerProductCategory'].updateValueAndValidity();
      this.form.controls['sellerProductBrand'].clearValidators();
      this.form.controls['sellerProductBrand'].updateValueAndValidity();
      this.form.controls['productTags'].clearValidators();
      this.form.controls['productTags'].updateValueAndValidity();
    }
  }

  async fetchCategoriesSearch(event: any) {
    this.skipCategoryList = 0;
    this.allCategories = [];
    await this.fetchCategories(event.term)
  }

  async fetchCategories(searchText?: string) { // need to revisit
    try {
      // if (!searchText && this.searchText) {
      //   searchText = this.searchText;
      // }

      const query: any = {
        skip: this.skipCategoryList,
        limit: defaultPaginationConfig.limit,
      };

      if (searchText) {
        query.search = searchText;
      }

      const newCategories = (await this.categoryService.getAllCategories(query))
        .data.categories;

      if (newCategories.length) {
        this.skipCategoryList += defaultPaginationConfig.limit;

        if (searchText) {
          this.allCategories = newCategories;
        } else {
          this.allCategories.push(...newCategories);
          this.allCategories = this.allCategories
            .reduce<ICategoryDetails[]>((unique, category) => {
              const existingCategory = unique.find(
                (c) => c.categoryId === category.categoryId,
              );
              if (!existingCategory) {
                unique.push(category);
              }
              return unique;
            }, []);
        }
      }
    } catch (error) {
      this.allCategories = [];
    }
  }

  async handleChangeProductTags(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.skipCategoryList = 0;
      this.allCategories = [];
      this.skipSubCategoryList = 0;
      this.allSubCategories = [];
      this.skipTagsList = 0;
      this.allTags = [];
      this.allBrands = [];
      this.form.controls['sellerProductCategory'].setValue([])
      this.form.controls['sellerProductSubCategory'].setValue([])
      this.form.controls['sellerProductBrand'].setValue([])
      this.form.controls['productTags'].setValue('')
      await this.fetchTags();
      this.form.controls['productTags'].setValidators([Validators.required]);
      this.form.controls['productTags'].updateValueAndValidity();
      this.form.controls['sellerProductCategory'].clearValidators();
      this.form.controls['sellerProductCategory'].updateValueAndValidity();
      this.form.controls['sellerProductBrand'].clearValidators();
      this.form.controls['sellerProductBrand'].updateValueAndValidity();
      this.form.controls['sellerProductSubCategory'].clearValidators();
      this.form.controls['sellerProductSubCategory'].updateValueAndValidity();
    }
  }

  async fetchTagsSearch(event: any) {
    this.skipTagsList = 0;
    this.allTags = [];
    await this.fetchTags(event.term)
  }
  onCloseTags() {
    // this.searchText = '';
    this.skipTagsList = 0;
    this.allTags = [];
    this.fetchTags();
  }
  async fetchTags(searchText?: string) { // need to revisit
    try {
      // if (!searchText && this.searchText) {
      //   searchText = this.searchText;
      // }

      const query: any = {
        skip: this.skipTagsList,
        limit: defaultPaginationConfig.limit,
      };

      if (searchText) {
        query.search = searchText;
      }

      const tags = (await this.subCategoryService.getAllTags(query))
        .data.tags;

      if (tags.length) {
        this.skipTagsList += defaultPaginationConfig.limit;

        if (searchText) {
          this.allTags = tags;
        } else {
          this.allTags.push(...tags);
          this.allTags = this.allTags
            .reduce<ITagMasters[]>((unique, category) => {
              const existingTag = unique.find(
                (c) => c.tagId === category.tagId,
              );
              if (!existingTag) {
                unique.push(category);
              }
              return unique;
            }, []);
        }
      }
    } catch (error) {
      this.allTags = [];
    }
  }

  onCloseCategories() {
    // this.searchText = '';
    this.skipCategoryList = 0;
    this.allCategories = [];
    this.fetchCategories();
  }
  async fetchSubCategoriesSearch(event: any) {
    this.skipSubCategoryList = 0;
    this.allSubCategories = [];
    await this.fetchSubCategories(event.term)
  }
  async fetchSubCategories(searchText?: string) { // need to revisit
    try {
      // if (!searchText && this.searchText) {
      //   searchText = this.searchText;
      // }

      const query: any = {
        skip: this.skipSubCategoryList,
        limit: defaultPaginationConfig.limit,
      };

      if (searchText) {
        query.search = searchText;
      }

      const newCategories = (await this.subCategoryService.getAllSubCategories(query))
        .data.subCategories;

      if (newCategories.length) {
        this.skipSubCategoryList += defaultPaginationConfig.limit;

        if (searchText) {
          this.allSubCategories = newCategories;
        } else {
          this.allSubCategories.push(...newCategories);
          this.allSubCategories = this.allSubCategories
            .reduce<ISubCategoryDetailsResponse[]>((unique, category) => {
              const existingCategory = unique.find(
                (c) => c.subCategoryId === category.subCategoryId,
              );
              if (!existingCategory) {
                unique.push(category);
              }
              return unique;
            }, []);
        }
      }
    } catch (error) {
      this.allSubCategories = [];
    }
  }

  onCloseSubCategories() {
    // this.searchText = '';
    this.skipSubCategoryList = 0;
    this.allSubCategories = [];
    this.fetchSubCategories();
  }

  async handleChangeBrand(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.skipCategoryList = 0;
      this.allCategories = [];
      this.skipSubCategoryList = 0;
      this.allSubCategories = [];
      this.allBrands = [];
      this.skipTagsList = 0;
      this.allTags = [];
      this.form.controls['sellerProductCategory'].setValue([])
      this.form.controls['sellerProductSubCategory'].setValue([])
      this.form.controls['sellerProductBrand'].setValue([])
      this.form.controls['productTags'].setValue('')
      await this.fetchBrands();
      this.form.controls['sellerProductBrand'].setValidators([Validators.required]);
      this.form.controls['sellerProductBrand'].updateValueAndValidity();
      this.form.controls['sellerProductCategory'].clearValidators();
      this.form.controls['sellerProductCategory'].updateValueAndValidity();
      this.form.controls['sellerProductSubCategory'].clearValidators();
      this.form.controls['sellerProductSubCategory'].updateValueAndValidity();
      this.form.controls['productTags'].clearValidators();
      this.form.controls['productTags'].updateValueAndValidity();
    }
  }

  async fetchBrands() { // need to revisit
    const brands = this.subCategoryService
      .getAllBrands();
    from(brands).pipe(
      this.toast.observe({
        error: (e) => (`${e?.error?.errorCode || e?.error?.statusCode}  -  ${e?.error?.message}`) || 'Something went wrong',
      }),
    ).subscribe();
    const res = await brands.catch(() => {
      this.allBrands = [];
    });
    if (res) {
      this.allBrands = res.data
    } else {
      this.allBrands = []
    }
  }
  closeEvent() {
    this.modalRef.close();
  }
}
