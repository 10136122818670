import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from './drop-zone.directive';

export const generateFileHandles = (files: FileList, sanitizer: DomSanitizer): FileHandle[] => {
  const fileHandels = [];
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const url = sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file),
    );
    fileHandels.push({ file, url });
  }
  return fileHandels;
};
