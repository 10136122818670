<div class="container-fluid mt-4 mb-3">
  <div class="header-label">
    <div class="text-capitalize" *ngIf="manipulationType === manipulationTypes.ADD">Add
    </div>
    <div class="text-capitalize" *ngIf="manipulationType === manipulationTypes.EDIT">Edit
    </div>
    <img src="assets/icon-order-status-close.svg" alt="icon-close" (click)="modalRef.close();">
  </div>
  <form [formGroup]="form" [ngClass]="{'submitted':formSubmitted}">
    <div class="parentzone-active row">
    <div class="mt-3 mb-4 col-6">
      <label class="required mb-2">Select Parent Zone</label>
      <select tabindex="0" type="text" id="type" class="form-control" (change)="parentZoneChange($event)" formControlName="parentZoneId">
        <option value="">Select Parent Zone</option>
        <option value="default">Common</option>
        <option *ngFor="let zone of parentZonalDetails" value={{zone.parentZoneId}}>{{zone.parentZoneName}}
        </option>
      </select>
    </div>
      <div class="toggle-label pt-3 p-3 mb-3 m-0 col-6">
        <span>Status</span>
        <div class="toggle mt-3 px-6">
          <input type="checkbox" id="switch-status" formControlName="isActive" />
          <label for="switch-status">Toggle</label>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="required mb-2 d-block">Select Delivery Preference</label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="order-now" formControlName="selectedOption" value="Order Now">
        <label class="form-check-label" for="order-now">Order Now</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="order-later" formControlName="selectedOption"
          value="Order Later">
        <label class="form-check-label" for="order-later">Order Later</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="both" formControlName="selectedOption" value="Both">
        <label class="form-check-label" for="both">Both</label>
      </div>
    </div>
    
      <div class="delivery-text mb-2">
        <label >Delivery Message</label>
      </div>
      <div class="delivery-message">
      <textarea id="deliveryMessage" row="30" formControlName="deliveryMessage" >
          
      </textarea>
    </div>
    <div class="submit-btn d-flex justify-content-end align-item-center p-0">
      <button type="button" type="submit" class="footer-btn border-0" id="save-btn" [disabled]="formSubmitted"
        (click)="saveChanges()">
        Save
      </button>
    </div>
  </form>
</div>