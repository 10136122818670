<main class="layout-wrapper">
  <header>
    <a href="/"><img class="brand-logo" src="/assets/logo/cookr-admin-panel-logo.svg" alt="logo" /></a>
    <div class="options-section">
      <!-- <div class="notification">
        <img src="/assets/notification-filled.svg" alt="notification-filled">
      </div> -->
      <!-- <div class="dropdown user-info-section">
        <span>Welcome &nbsp;<span>{{ userName }}</span></span>
        <button class="logout-cta" (click)="logout()">
          <div class="logout-icon-wrapper">
            <img src="/assets/logout.svg" alt="logout" />
          </div>
          <span>Logout</span>
        </button>
      </div> -->
      <div class="dropdown user-info-section">

        <button class="btn dropdown-toggle" type="button" id="headerDropdownMenuButton" data-bs-toggle="dropdown"
          aria-expanded="false">
          <span>{{ userName }}</span>
          <img src="/assets/icon-chevron-down.svg" alt="icon-chevron-down" class="icon-chevron-down">
        </button>
        <ul class="dropdown-menu" aria-labelledby="headerDropdownMenuButton">
          <li><a class="dropdown-item" routerLink="../user-management">User Management</a></li>
          <hr class="m-0">
          <li><a class="dropdown-item" target="_blank" [href]="envConfig.CONTROL_TOWER_URI">Control Tower</a></li>
          <li><a class="dropdown-item" target="_blank" [href]="envConfig.REPORT_DASHBOARD_URI">Reports Dashboard</a>
          </li>
          <li><a class="dropdown-item" target="_blank" [href]="envConfig.SELLER_PIPELINE_URI">Seller Pipeline</a></li>
          <hr class="m-0">
          <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </header>
  <div class="content-layout">
    <ul class="content-layout-tabs nav nav-tabs" id="contentLayoutTabs" role="tablist">
      <li *ngIf="appAbility.can('view', appAbilityKey.COOK_ADMIN)" class="nav-item" role="presentation">
        <a routerLink="cook-admin" routerLinkActive="active" class="nav-link" id="cookAdminNav" data-bs-toggle="tab"
          type="button" role="tab" aria-controls="cook-admin" aria-selected="true"
          (click)="changeTab(tabTypes.COOK_ADMIN)">Cook Admin</a>
      </li>
      <li *ngIf="appAbility.can('view', appAbilityKey.DE_ADMIN)" class="nav-item" role="presentation">
        <a routerLink="driver-admin" routerLinkActive="active" class="nav-link" id="driverAdminNav" data-bs-toggle="tab"
          type="button" role="tab" aria-controls="driver-admin" aria-selected="false"
          (click)="changeTab(tabTypes.DRIVER_ADMIN)">DE Admin</a>
      </li>
      <li *ngIf="appAbility.can('view', appAbilityKey.CUSTOMER_ADMIN)" class="nav-item" role="presentation">
        <a routerLink="customer-admin" routerLinkActive="active" class="nav-link" id="customerAdminNav"
          data-bs-toggle="tab" type="button" role="tab" aria-controls="customer-admin" aria-selected="false"
          (click)="changeTab(tabTypes.CUSTOMER_ADMIN)">Customer
          Admin</a>
      </li>
    </ul>
    <div class="tab-content" id="contentLayoutTabContents">
      <div class="tab-content-flex-wrapper">
        <app-sidebar id="sideBarCollapse" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample" [currentAdminView]="currentTab"></app-sidebar>
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sideBarCollapse"
          aria-expanded="true" aria-controls="sideBarCollapse  ">

        </button>

        <router-outlet>

        </router-outlet>
      </div>
    </div>
  </div>
</main>