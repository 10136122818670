import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { IMenuItem } from './menu-item-api.service';

export interface IUpdateMenuSttaus {
  approvalStatus: string;
  remarks?: string;
}

export interface IUpdatedBy {
  entityId: string;
  entityName: string;
  entityType: string;
}

export interface ILogs {
  itemAppovalLogsId: string,
  approvalStatus: string,
  itemId: string,
  itemName: string,
  action: string,
  updatedBy: IUpdatedBy,
  createdAt: string,
  updatedAt: string,
}

@Injectable({
  providedIn: 'root',
})
export class MenuApprovalApiService extends RestApiService {
  protected serviceBaseApiUrl = environment.config['KITCHEN_SERVICE_URL'];

  private servicePath = `${this.serviceBaseApiUrl}/item-approvals`;

  private changeSubject = new BehaviorSubject<string>('');

  changeObservable$ = this.changeSubject.asObservable();

  async triggerChange(itemId: string) {
    await this.changeSubject.next(itemId);
  }

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async fetchMenusById(
    queryParams: Partial<any>,
  ): Promise<{ data: { items: IMenuItem[], totalItems: number, totalPages: number } }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['parentZoneId']) {
        searchParams.set('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['approvalStatus'] && queryParams['approvalStatus'] !== 'ALL') {
        searchParams.set('approvalStatus', String(queryParams['approvalStatus']));
      } else {
        searchParams.delete('approvalStatus');
      }
      if ('isExistingMenuTab' in queryParams) {
        searchParams.set('isExistingMenuTab', String(queryParams['isExistingMenuTab']));
      }
      if ('search' in queryParams) {
        searchParams.set('search', String(queryParams['search']));
      } else {
        searchParams.delete('search');
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateMenuStatus(itemId: string, queryParams: Partial<IUpdateMenuSttaus>) {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['remarks']) {
        searchParams.set('remarks', String(queryParams['remarks']));
      }
      if (queryParams['approvalStatus']) searchParams.set('approvalStatus', String(queryParams['approvalStatus']));
    }
    const apiPath = `${this.servicePath}/${itemId}?${searchParams.toString()}`;
    return this.httpPatch(apiPath);
  }

  async getCompareMenuById(itemId: string) {
    const apiPath = `${this.servicePath}/approval-request-comparison/${itemId}`;
    return this.httpGet(apiPath);
  }

  async menuApprovalCount() {
    const apiPath = `${this.servicePath}/approval-request/count`;
    return this.httpGet(apiPath);
  }

  async menuApprovalLogs(id: string) {
    const apiPath = `${this.servicePath}/logs/${id}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async updateMenu(itemId: string, payload: IMenuItem) {
    const apiPath = `${this.servicePath}/admin/edit/${itemId}`;
    return this.httpPatch(apiPath, payload);
  }
}
